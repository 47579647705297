import React, { useState } from 'react';
import Swal from "sweetalert2";
import useAPI from "../../../hooks/useAPI";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaWpforms } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { TiExport } from "react-icons/ti";

const ListButtonsCallToAction = ({
    b,
    btn,
    message,
    contactList,
    orderShopping,
    ProductInContext,
    listProducts,
    urlAdvertising,
    linkLocation,
    setListContacts,
    setModalListContacts,
    setModalViewProductContext,
    setProductContextSelected,
    setModalViewOrder,
    setQuantityOrder,
    setTotalPriceOrder,
    setOrderSelected,
    setLoading,
    formGenerateLink,
}) => {

    const { urlAPI, urlAPI_1, urlAPI_2, optGET, optPOST, optPUT, optDELETE, urlSocket } = useAPI();

     //#--> Visualizar producto en contexto
    const onViewProductContext = async (data) => {
        console.log('Recibiendo data el producto en constexto a ver:', data)
        setProductContextSelected(data)
        setModalViewProductContext(true)
    }

    //#--> Ver detalles de un flujo
    const onViewFlow = async (id) => {
        console.log('----Recibiendo el ID :::::', id)
        try {
            setLoading(true);
            const url = urlAPI_2 + "flow/" + id;
            const response = await fetch(url, optGET());
            const result = await response.json();
            console.log("Detalles del Flujoooooo ==========>>", result.data);

            // Verificar si hay una URL en result.data antes de abrir una nueva pestaña
            if (result.data && result.data.preview_url) {
                const newTab = window.open(result.data.preview_url, '_blank');
                if (newTab) {
                    //#--> La pestaña se abrió correctamente
                    console.log('Se abrio correctamenteee.....');
                    newTab.focus();
                } else {
                    //#--> Bloqueador de ventanas emergentes podría haber impedido la apertura
                    console.error('No se pudo abrir una nueva pestaña. Asegúrate de permitir ventanas emergentes.');
                    Swal.fire({
                        title: "Advertencia",
                        text: "Asegúrate de permitir ventanas emergentes en tu navegador",
                        icon: "warning",
                    });
                }
            } else {
                console.error('La propiedad "preview_url" no está presente en result.data.');
                Swal.fire({
                    title: "Advertencia",
                    text: "No es posible visualizar este flujo.",
                    icon: "warning",
                });
            }

            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    //#--> Visualizar orden selecionada
    const onViewOrder = async (orderShopping) => {
        setModalViewOrder(true)
        let productsOrder = []
        let totalQuantity = 0
        let totalOrder = 0
        for (let pro of orderShopping) {
        const orderProducts = listProducts.find(prod => prod.retailer_id === pro.product_retailer_id)
        console.log('Data product:', orderProducts);

        let productOrder = {}
        productOrder.name = orderProducts.name
        productOrder.description = orderProducts.description
        productOrder.price = orderProducts.sale_price ? orderProducts.sale_price : orderProducts.price
        productOrder.image_url = orderProducts.image_url
        productOrder.quantity = pro.quantity
        productsOrder.push(productOrder)

        totalQuantity = totalQuantity + pro.quantity
        const priceTotalProduct = pro.quantity * pro.item_price
        totalOrder = totalOrder + priceTotalProduct
        }
        setQuantityOrder(totalQuantity)
        setTotalPriceOrder(totalOrder)
        setOrderSelected(productsOrder)
        totalOrder = String(totalOrder).replace(/[^0-9]/g, "");
        formGenerateLink.reset({totalOrder: `$${totalOrder}`})
    }

  return (
    <>
        <div 
            key={b} 
            className={
                (
                    btn.type === "contacts" || btn.type === "location" || 
                    (btn.type === "order" && message.originMessage === "user") || 
                    ((btn.type === "productContext" || btn.type === "advertising") && message.originMessage === "user")
                ) ? "content-botton contacts pointer" : "content-botton p"
            }
        >
            <div>
                {btn.type == "PHONE_NUMBER" ? 
                    <BsFillTelephoneFill className="mr-1" style={{ fontSize: "1.3rem"}}/>
                : btn.type == "FLOW" ? 
                    <FaWpforms className="mr-1" style={{ fontSize: "1.3rem"}}/>
                : btn.type == "contacts" || btn.type === "flow" || btn.type === "catalog" || btn.type === "order" || btn.type === "productContext" || btn.type === "advertising" ? 
                    ""
                : btn.type == "location" ? 
                    <a
                        href={message.bodytext}
                        target="_blank"
                        rel="noreferrer"
                        className="view-location"
                        style={{color: "#00a5f4", fontSize: "1rem"}}
                    >
                        <IoLocationOutline className="mr-1" style={{ fontSize: "1.3rem"}} />
                    </a>
                : btn.type == "send_location" ? 
                    <IoLocationOutline className="mr-1" style={{fontSize: "1.3rem"}} />
                : 
                    <TiExport className="mr-1" style={{ fontSize: "1.3rem"}}/>
                }
                <span className={btn.type == "contacts" || btn.type == "flow" || btn.type == "order" || btn.type == "productContext" || btn.type == "advertising" && "pointer"}
                    onClick={() =>{
                        if (btn.type == "contacts") {
                            setListContacts(contactList)
                            setModalListContacts(true)
                        } else if (btn.type === "flow") {
                            onViewFlow(message.idTemplate)
                        } else if (btn.type === "order") {
                            onViewOrder(orderShopping)
                        } else if (btn.type === "productContext") {
                            onViewProductContext(ProductInContext)
                        }
                    }}
                >
                    {btn.type === "location" || btn.type === "advertising" ?
                        <a href={btn.type === "advertising" ? urlAdvertising : btn.type === "location" ? linkLocation : message.bodytext} target="_blank" rel="noreferrer" className="view-location pointer" style={{color: "#00a5f4"}}>
                        {btn.text}
                        </a>
                    :
                        btn.text
                    }
                </span>
            </div>
        </div>
    </>
  );
};

export default ListButtonsCallToAction;