import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Avvvatars from "avvvatars-react";
import Modal from "../layout/Modal";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import useAPI from "../../hooks/useAPI";
import { FaAngleLeft, FaSortDown, FaSortUp, FaHeadphones } from "react-icons/fa";
import { FaTags } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { BsSearch, BsPencilFill } from "react-icons/bs";
import { CgMenuRight } from "react-icons/cg";
import { BiSolidLabel } from "react-icons/bi";
import { PiUserSwitchFill } from "react-icons/pi";

const MessagesContentHeader = ({
  showContactInfo,
  idChatSelected,
  nameInitials,
  contactName,
  isAssignedAgent,
  statusChat,
  isSavedChat,
  listStatus,
  listAreas,
  listTags,
  tableListTags,
  searchArea,
  validationModalTags,
  phoneContact,
  token,
  setLoading,
  setClassChat,
  setShowContactInfo,
  setClassInfo,
  setNotChatSelected,
  setIsChatSelected,
  setModalTransferAgent,
  setListAreas,
  setListTags,
  setValidationModalTags,
  onChatSelected,
  onChatStatus,
  getIsDark,
  getTagContact,
  updateConversations,
}) => {

  const [showTransfer, setShowTransfer] = useState(false);
  const [modalTransferArea, setModalTransferArea] = useState(false);
  const [searchModalArea, setSearchModalArea] = useState("");
  const [showStatus, setShowStatus] = useState(false);
  const [modalTags, setModalTags] = useState(false);
  const [showOptionsChat, setShowOptionsChat] = useState(false);
  const [searchTags, setSearchTags] = useState("");

  const { urlAPI, urlAPI_1, urlAPI_2, optGET, optPOST, optPUT, urlSocket } = useAPI();

  const navigate = useNavigate();

  const {watch,register,handleSubmit,formState: { errors },reset} = useForm();

  ///#--> Funcion que envia el area que se asigno al chat
  const onAsingArea = async (id) => {
    var objFinal = {
      areaId: id,
      chatId: localStorage.getItem("CONVERSATION_SELECTED")
    };
    console.log('-------objSend----->', objFinal)
    try {
      setLoading(true);
      const url = urlAPI_1 + "assignChat/" + phoneContact;
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(objFinal),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      updateConversations();
      setLoading(false);
      setModalTransferArea(false);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      console.log(error);
    }
  };
  
  ///#--> Buscador para las áreas
  const onSearchArea = (value) => {
    var searchResults = searchArea.filter((element) => {
      if (element.description.toString().toLowerCase().includes(value.toLowerCase())) {
        return element;
      }
    });
    setListAreas(searchResults);
  };

  //#--> Etiquetar contacto
  const putTagContact = async () => {
    const {tags} = watch()
    const body = tags.toString();
    console.log('Etiquetas seleccionadas==>', body);
    const idContact = idChatSelected.slice(2)
    const chatSelected = localStorage.getItem("CHAT_SELECTED");
    try {
      setLoading(true);
      const values = {
        tags: body
      };
      const url = urlAPI_1 + `tagsContact/${idContact}`;
      const response = await fetch(url, optPUT(values));
      const result = await response.json();
      console.log("Data Send", result);
      setLoading(false);
      setModalTags(false);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });
      getTagContact(chatSelected);
      console.log("Calling getConversations from putTagContact");
      updateConversations();
    } catch (error) {
      console.log(error);
    }
  }
    
  //#--> Buscador en la lista de tags
  const searchTagsChecked = (searchTerm) => {
    var searchResults = tableListTags.filter((element) => {
      if (element.title.toString().toLowerCase().includes(searchTerm.toLowerCase())) {
        return element;
      }
    });
    setListTags(searchResults);
    if (searchResults.length === 0) {
      setValidationModalTags(true);
    } else {
      setValidationModalTags(false);
    }
  };

  return (
    <>
      <header className="message d-flex" style={{height: "8%"}}>
        <div className="message d-flex col-6" style={{ alignItems: "center" }}>
          <div className="back-list">
            <FaAngleLeft className="pointer" style={{ fontSize: "25px" }} onClick={() => setClassChat(false)}/>
          </div>
          <div className="cursor-pointer d-flex align-items-center"
            onClick={() => {
              !showContactInfo ? setShowContactInfo(true) : setShowContactInfo(false);
              //onChatSelected(idChatSelected);
              setClassInfo(true);
              getIsDark()
            }}
          >
            <div className="logo-wsp ">
              <IconList>
                <Avvvatars value={nameInitials} />
              </IconList>
            </div>
            <div id="customer-name" className="ml-2 d-flex flex-column">
              <div className="d-flex align-items-center">
                <h1 className='mr-2'>
                  {contactName}
                </h1>
                <BsPencilFill style={{ fontSize: "16px" }}/>
              </div>
              <div className="d-flex align-items-center">
                <FaHeadphones className="mr-1" style={{fontSize: "16px"}} />
                <p className="mb-0" style={localStorage.getItem("AGENT_ASIGN") ? { color: "rgb(91, 192, 222)" } : { color: "rgb(251 0 0)" }}>
                  {localStorage.getItem("AGENT_ASIGN") ? localStorage.getItem("AGENT_ASIGN") : "Sin asignar"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="dropdown options-header-chat-responsive mr-2">
          <p className="m-0" onClick={() => setShowOptionsChat(prevShow => !prevShow)} >
            {showOptionsChat ?
              <IoClose className="icon-close-menu pointer mr-1" style={{ fontSize: "1.5rem" }}/> :
              <CgMenuRight className="icon-menu pointer mr-1" style={{ fontSize: "1.5rem" }}/>
            }
          </p>
          {showOptionsChat ? (
              <div className="category-list position-absolute status-list" style={{ width: "10rem", zIndex: "9999", right: "0px", padding: "0.5rem 0" }}>
                <div className={`dropdown mx-2 pointer status-chat ${isAssignedAgent && "active"}`} style={{ alignItems: "center" }}>
                  <p
                    onClick={() => {
                      if (!isAssignedAgent) {
                        if (showStatus) {
                          setShowStatus(false)
                        } else {
                          setShowStatus(true)
                          setShowOptionsChat(false)
                        }
                      }
                    }}
                  >
                    <a 
                      href="javascript:;"
                      className={
                        statusChat === "1" || statusChat === "Abierto" ? "green d-flex" 
                        : statusChat === "3" || statusChat === "En espera" ? "gray d-flex" 
                        : "red d-flex"
                      }
                    >
                      {showStatus ? 
                        <FaSortUp className="pointer mr-1" style={{ fontSize: "18px", marginTop: "5px" }}/> :
                        <FaSortDown className="pointer mr-1" style={{ fontSize: "18px", marginBottom: "5px" }}/>
                      }

                      {statusChat == "1" || statusChat == "Abierto" ? "Abierto"
                        : statusChat == "2" || statusChat == "Cerrado" ? "Cerrado"
                        : "En espera"
                      }
                    </a>
                  </p>

                  <div className="message-status">
                    <label className="f-w-900" style={{fontStyle: "italic"}}>
                      Por favor primero asigna la conversación para poder cambiar su estado
                    </label>
                  </div>
                </div>

                <div className={`ml-0 mt-2 content-icon-tags ${!isSavedChat && "active"}`}>
                  <div className="dropdown mx-2 pointer status-chat d-flex align-items-center"
                    onClick={() => {
                      if (isSavedChat) {
                        setModalTags(true)
                        setShowOptionsChat(false);
                      }
                    }}
                  >
                    <FaTags
                      style={{ fontSize: "25px" }}
                      title={isSavedChat ? "Etiquetar contacto" : ""}
                      className={`pointer tags ${isSavedChat && "active"}`}
                    />
                    <label className="m-0 ml-2">Etiquetar</label>
                  </div>

                  <div className="message-tags">
                    <p className="f-w-900" style={{fontStyle: "italic"}}>
                      Por favor guarde el contacto para usar las etiquetas
                    </p>
                  </div>

                </div>

                <div className="transfer-chat mt-2 ml-2 d-flex align-items-center"
                  onClick={() => {
                    setShowTransfer((state) => !state)
                    setShowOptionsChat(false);
                  }}
                >
                  <PiUserSwitchFill
                    className="icon-transfer pointer"
                    style={{ fontSize: "29px", position: "relative" }}
                    title="Transferir conversación"
                  />
                  <label className="m-0 ml-2">Transferir</label>
                </div>
              </div>
          ) : null}

          {showStatus ? (
            <div className="category-list position-absolute status-list" style={{ width: "135px", zIndex: "999999", right: "0", top: "0" }}>
              <span
                className="position-absolute close-transfer pointer"
                style={{ right: "0.2rem", top: "0", fontSize:"1rem", fontWeight:"bold" }}
                onClick={() => {
                  setShowStatus(false);
                }}
              >x</span>
              {listStatus && listStatus.map((stts, k) => (
                <div key={k} className="item-list">
                  <input
                    type="radio"
                    name={stts.id}
                    id={stts.id}
                    className="form-check-input mt-3"
                    value={stts.id}
                    onChange={(e) => {
                      onChatStatus(e.target.value);
                      setShowStatus(false);
                      if (statusChat) {
                        Swal.fire({
                          title: "¡Bien!",
                          text: e.target.value == 2 ? "Conversación cerrada con exito" : "Estado cambiado con exito",
                          icon: "success",
                        });
                        if (e.target.value == 2) {
                          setShowContactInfo(false)
                          setNotChatSelected(true)
                          setIsChatSelected(false);
                          setClassChat(false);
                          localStorage.setItem("CHAT_SELECTED", "");
                        }
                      }
                    }}
                  />
                  <label htmlFor={stts.id} className="form-check d-flex justify-content-between cursor-pointer mb-0" key={stts.id} style={{ padding: "5px 2px 5px 1.25rem" }}>
                    <div>
                      <label htmlFor={stts.id} className="form-check-label pl-0 f-w-400">
                        {stts.Description}
                      </label>
                    </div>
                    <div
                      htmlFor={stts.id}
                      className={`circle-status ${
                        stts.Description == "1" || stts.Description == "Abierto" ? "green"
                        : stts.Description == "2" || stts.Description == "Cerrado" ? "red"
                        : "gray"
                      }`}
                    ></div>
                  </label>
                </div>
              ))}
            </div>
          ) : null}

          {showTransfer ? (
            <div className="category-list position-absolute status-list p-2" style={{ width: "11.5rem", zIndex: "9911", right: "0", top: "0" }}>
              <span
                className="position-absolute close-transfer pointer"
                style={{ right: "0.2rem", top: "0", fontSize:"1rem", fontWeight:"bold" }}
                onClick={() => {
                  setShowTransfer(false)
                }}
              >x</span>
              <ul className="transfer-options">
                <li className="p-1">
                  <label
                    className="pointer"
                    onClick={() => {
                      setModalTransferArea(true);
                      setShowTransfer((state) => !state)
                    }}
                  >
                    Transferir a un área
                  </label>
                </li>
                <li className="p-1">
                  <label
                    className="pointer"
                    onClick={() => {
                      setModalTransferAgent(true);
                      setShowTransfer((state) => !state)
                    }}
                  >
                    Transferir a un agente
                  </label>
                </li>
              </ul>
            </div>
          ) : null}

        </div>

        <div className="options-header-chat col-6 d-flex justify-content-end align-items-center">
          <div className={`dropdown mx-2 pointer status-chat ${isAssignedAgent && "active"}`} style={{ alignItems: "center" }}>
            <p
              onClick={() => {
                if (!isAssignedAgent) {
                  if (showStatus) {
                    setShowStatus(false)
                  } else {
                    setShowStatus(true)
                  }
                }
              }}
            >
              <a href="javascript:;"
                className={
                  statusChat === "1" || statusChat === "Abierto" ? "green d-flex"
                    : statusChat === "3" || statusChat === "En espera" ? "gray d-flex"
                    : "red d-flex"
                }
              >
                {showStatus ?
                  <FaSortUp className="pointer mr-1" style={{ fontSize: "18px", marginTop: "5px" }}/> :
                  <FaSortDown className="pointer mr-1" style={{ fontSize: "18px", marginBottom: "5px" }}/>
                }

                {statusChat == "1" || statusChat == "Abierto"
                  ? "Abierto"
                  : statusChat == "2" || statusChat == "Cerrado"
                  ? "Cerrado"
                  : "En espera"}
              </a>
            </p>
            {showStatus ? (
              <div className="category-list position-absolute status-list span-color" style={{ width: "135px", zIndex: "9" }}>
                {listStatus && listStatus.map((stts, k) => (
                  <div key={k} className="item-list">
                    <input
                      type="radio"
                      name={stts.id}
                      id={stts.id}
                      className="form-check-input mt-3"
                      value={stts.id}
                      onChange={(e) => {
                        onChatStatus(e.target.value);
                        if (statusChat) {
                          console.log("estado cambiado desde response");
                          Swal.fire({
                            title: "¡Bien!",
                            text: e.target.value == 2 ? "Conversación cerrada con exito" : "Estado cambiado con exito",
                            icon: "success",
                          });
                          if (e.target.value == 2) {
                            setNotChatSelected(true)
                            setIsChatSelected(false);
                            setClassChat(false);
                          }
                          setShowStatus(false);
                        }
                      }}
                    />
                    <label htmlFor={stts.id} className="form-check d-flex justify-content-between cursor-pointer mb-0" key={stts.id} style={{ padding: "5px 2px 5px 1.25rem" }}>
                      <div>
                        <label htmlFor={stts.id} className="form-check-label pl-0 f-w-400">
                          {stts.Description}
                        </label>
                      </div>
                      <div
                        htmlFor={stts.id}
                        className={`circle-status ${
                          stts.Description == "1" || stts.Description == "Abierto" ? "green"
                          : stts.Description == "2" || stts.Description == "Cerrado" ? "red"
                          : "gray"
                        }`}
                      ></div>
                    </label>
                  </div>
                ))}
              </div>
            ) : null}

            <div className="message-status">
              <label className="f-w-900" style={{fontStyle: "italic"}}>
                Por favor primero asigna la conversación para poder cambiar su estado
              </label>
            </div>
          </div>

          <div className={`ml-0 mt-2 content-icon-tags ${!isSavedChat && "active"}`}>
            <div className="dropdown mx-2 pointer status-chat" style={{ alignItems: "center"}}>
              <FaTags
                style={{ fontSize: "25px" }}
                title={isSavedChat ? "Etiquetar contacto" : ""}
                className={`pointer tags ${isSavedChat && "active"}`}
                onClick={() => {
                  if (isSavedChat) {
                    setModalTags(true)
                  }
                }}
              />
            </div>

            <div className="message-tags">
              <p className="f-w-900" style={{fontStyle: "italic"}}>
                Por favor guarde el contacto para usar las etiquetas
              </p>
            </div>

          </div>

          <div className="transfer-chat ml-2 d-flex" style={{ alignItems: "center" }}>
            <PiUserSwitchFill
              className="pointer icon-transfer"
              style={{ fontSize: "29px", position: "relative" }}
              title="Transferir conversación"
              onClick={() => {
                setShowTransfer((state) => !state)
              }}
            />

            {showTransfer ? (
              <div className="category-list position-absolute status-list p-2" style={{ width: "11.5rem", zIndex: "9", right: "1rem", top: "2.3rem" }}>
                <span
                  className="position-absolute close-transfer pointer"
                  style={{ right: "0.5rem", top: "0", fontSize:"1rem", fontWeight:"bold" }}
                  onClick={() => {
                    setShowTransfer(false)
                  }}
                >x</span>
                <ul className="transfer-options">
                  <li className="p-1">
                    <label
                      className="pointer"
                      onClick={() => {
                        setModalTransferArea(true);
                        setShowTransfer((state) => !state)
                      }}
                    >
                      Transferir a un área
                    </label>
                  </li>
                  <li className="p-1">
                    <label
                      className="pointer"
                      onClick={() => {
                        setModalTransferAgent(true);
                        setShowTransfer((state) => !state)
                      }}
                    >
                      Transferir a un agente
                    </label>
                  </li>
                </ul>
              </div>
            ) : null}

          </div>
        </div>
      </header>

      {/* Modal para transferir a un área */}
      <Modal
        status={modalTransferArea}
        changeStatus={setModalTransferArea}
        title="Transferir a un área"
        width={"450px"}
      >
        <Content>
          <div className="w-100">
            <form className="form-inline my-3 justify-content-center">
              <div className="su-control mt-1">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Búsqueda"
                  value={searchModalArea}
                  onChange={(e) => {
                    setSearchModalArea(e.target.value);
                    onSearchArea(e.target.value);
                  }}
                />
              </div>
            </form>
            <div className="list-advisers" style={{overflow: "auto",height: "366px"}}>
              <ul>
                {listAreas.length > 0 ? listAreas.map((area, k) => {
                    const initialWords = area.description.split(" ");
                    const firstWord = initialWords[0];
                    const secondWord = initialWords[1];
                    let icon = "";
                    if (secondWord === undefined) {
                      icon = firstWord[0];
                    } else {
                      icon = firstWord[0] + secondWord;
                    }
                    return (
                      <li key={k} style={{ alignItems: "center" }} className="pointer d-flex align-items-center" onClick={() => {onAsingArea(area._id)}}>
                        <IconList>
                          <Avvvatars value={icon} />
                        </IconList>
                        <div className="d-block">
                          <p className="ml-3 span-color">{area.description}</p>
                        </div>
                      </li>
                    );
                  })
                : <div className="text-center">
                    <h4 className="text-center">No existen areas</h4>
                  </div>
                }
              </ul>

            </div>
          </div>
        </Content>
      </Modal>

      {/* Modal para etiquetar contacto */}
      <Modal
        status={modalTags}
        changeStatus={setModalTags}
        title="Etiquetar contacto"
        width={"480px"}
      >
        <form className="form-inline justify-content-center mb-2 modal-addList">
          <div className="su-control mt-1 d-flex">
            <input
              type="text"
              className="form-control"
              placeholder="Búsqueda"
              value={searchTags}
              onChange={(e) => {
                setSearchTags(e.target.value);
                searchTagsChecked(e.target.value);
              }}
            />
            <button
              className="btn btn-info"
              type="button"
              id="buttonSearchProductBack"
              style={{ height: "34px" }}
            >
              <BsSearch />
            </button>
          </div>
        </form>
        <form className="form-content-modal" onSubmit={handleSubmit(putTagContact)} >
          <ModalContent className="modal-add-users show-list-tags">
            <ul className="o-auto d-flex flex-column justify-content-center align-items-center" style={{ padding: "0 0 5px" }}>
              {listTags.map((tag, t) => {
                return (
                  <li key={t} className="bg-light b-primary m-t-5 p-3 row w-90">
                    <div className="col-8 d-flex align-items-center icon-avatar">
                      <BiSolidLabel
                        style={{
                          color: tag.color,
                          fontSize: "2.5em",
                        }}
                      />
                      <span className="ml-3">{tag.title}</span>
                    </div>
                    <input
                      type="checkbox"
                      value={tag._id}
                      className="cmn-toggle cmn-toggle-round-flat"
                      name={tag._id }
                      id={tag._id}
                      defaultChecked={tag.tagActive}
                      // checked={tag.tagActive}
                      {...register("tags")}
                    />
                    <label className="ml-4" htmlFor={tag._id}></label>
                  </li>
                );
              })}
              {validationModalTags && (
                <div className="text-center mt-3">
                  <div className="empty">
                    <FaTags style={{ fontSize: "55px" }} />
                    <h1 style={{ fontSize: "30px" }}>No tienes etiquetas</h1>
                    <input
                      type="button"
                      className="btn btn-default"
                      value="Ir a crear"
                      onClick={() => {
                        navigate("/home/tags");
                      }}
                    />
                  </div>
                </div>
              )}
            </ul>
          </ModalContent>
          <div className="row justify-content-end mt-3 pt-3">
            <div className="col-sm-6 right">
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => setModalTags((prevMessage) => { return false})}
              >
                Volver
              </a>
              <input
                className="btn btn-lg btn-info"
                value="Aceptar"
                type="submit"
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default MessagesContentHeader;

const IconList = styled.div`
  margin-bottom: 0px;

  div {
    height: 37px;
    width: 37px;
    border-radius: 50%;
    // box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.7);
    margin: 4px auto;
  }

  p {
    font-size: 18px;
    font-weight: 600;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  li {
    padding: 10px 5px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.05);
  }
  li:hover {
    background: rgb(0, 0, 0, 0.05);
  }
  p {
    font-size: 18px;
    margin-bottom: 0px;
  }
  input {
    padding: 10px;
    border-radius: 0px;
    font-size: 17px;
  }
  img.img-answer {
    cursor: pointer;
    width: auto;
    height: 85px;
    object-fit: scale-down;
  }
  .content-img {
    position: relative;
  }
  .content-img img:hover {
    opacity: 0.6;
  }
`;
const ModalContent = styled.div`
  overflow: auto;
  height: 335px;

  .checkbox {
    padding: 25px;
    width: 25px;
    height: 25px;
  }
`;