import React from "react";
import { Handle, Position } from 'reactflow';

import { MdOutlineSupportAgent } from "react-icons/md";
import { FaEllipsisVertical } from "react-icons/fa6";

const TransferAgent = ({ data, isConnectable, id }) => {
    return (
        <div className="rounded" style={{width: "180px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", cursor: 'pointer'}}>
            <Handle type="target" position={Position.Top} isConnectable={isConnectable}  style={{backgroundColor: '#F56F89'}}/>
            <div>
                <div 
                    className="d-flex align-items-center rounded-top justify-content-between" 
                    style={{height: "10px", backgroundColor: "#F56F89", padding: "12px"}}
                >
                    <div className="d-flex align-items-center">
                        <MdOutlineSupportAgent className="mr-1" style={{fontSize: "12px", color: "#fff"}}/>
                        <span style={{fontSize: "10px", color: "#fff"}} >
                            {data.title ?? "Transferir Conversación"}
                        </span>
                    </div>
                    {!data.first && (
                        <div className="dropdown">
                            <button
                                className="bg-transparent border-0 p-0"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <FaEllipsisVertical className="text-white pointer mt-1" />
                            </button>
                            <div
                                className="dropdown-menu"
                                style={{minWidth: "60px"}}
                                aria-labelledby="dropdownMenuButton"
                            > 
                                <button 
                                    className="text-danger border-0 bg-transparent pointer" 
                                    style={{height: "20px"}} 
                                    onClick={() => {data.onRemove(id)}}
                                >
                                    <span className="text-dark ml-1 f-10">
                                        Eliminar
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="bg-white rounded-bottom" style={{padding: '8px'}}>
                    {data.user_response && (
                        <div 
                            className="p-1 mb-2" 
                            style={{
                                backgroundColor: "rgba(245, 111, 137, 0.3)",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Respuesta del Cliente:</span>
                            <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                {data.user_response}
                            </p>
                        </div>
                    )}
                    {data.bot_response && (
                        <div 
                            className="p-1 mb-2" 
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Respuesta del Bot:</span>
                            <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                {data.bot_response}
                            </p>
                        </div>
                    )}
                    {data.agent_area && (
                        <div 
                            className="p-1" 
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >    
                            <div>
                                <MdOutlineSupportAgent className="mr-1" style={{fontSize: "12px", color: "#000"}}/>
                                <span style={{fontWeight: "bold", fontSize: "9px"}}>
                                    Agente / Area:
                                </span>
                            </div>
                            <p style={{fontWeight: 'bold', fontSize: "11px"}} className="m-0">
                                {data.agent_area.label}
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <Handle 
                type="source" 
                position={Position.Bottom} 
                id="b" 
                isConnectable={isConnectable}  
                style={{backgroundColor: '#F56F89'}}
            />
        </div>
    )
}

export default TransferAgent;