import Avvvatars from "avvvatars-react";
import React, { useEffect, useState } from "react";
import useAPI from "../../hooks/useAPI";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { BsPersonVcard } from "react-icons/bs";
import { BiSolidHelpCircle } from "react-icons/bi";
import { BsFillBuildingFill, BsSun, BsMoon, BsWhatsapp } from "react-icons/bs";
import { FaRegMoneyBillAlt, FaSortDown } from "react-icons/fa";
import { FaMoneyBills } from "react-icons/fa6";
import { HiUserGroup } from "react-icons/hi";
import { MdCancel } from "react-icons/md";
import { PiUserCircleLight } from "react-icons/pi";
import { useQueryClient } from '@tanstack/react-query';

const Header = (props) => {
  const [menu, setMenu] = useState(false);
  const [getUser, setGetUser] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [widthMenu, setWidthMenu] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const { urlAPI, optPOST, optGET, optPUT, optDELETE, urlAPI_1, urlAPI_2 } = useAPI();
  const userId = localStorage.getItem('USER_ID');

  const queryClient = useQueryClient();

  useEffect(() => {
    getDataUser();
    const vDark = localStorage.getItem("IS_DARK");
    const isDarkMode = vDark === "true";
    queryClient.setQueryData(['isDarkTheme'], isDarkMode);
    setIsChecked(isDarkMode);
    var body = document.getElementById('body-dark')
    if(isDarkMode == true){
      body.classList.add("dark")
      console.log('_____verdadero_____')
    }else{
      body.classList.remove("dark")
      console.log('_____falso_____')
    }
    const loadFacebookSDK = () => {
      window.fbAsyncInit = function() {
        FB.init({
          appId: '934333144429201',
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v19.0'
        });
      };

      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        js.async = true;
        js.defer = true;
        js.crossOrigin = "anonymous";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    };

    loadFacebookSDK();

  }, []);

  const launchWhatsAppSignup = () => {
    // Facebook Login with JavaScript SDK
    FB.login(async function(response) {
      console.log("response de login de empresas: ", response);
      if (response.authResponse) {
        console.log("response.authResponse de login de empresas: ", response.authResponse);
  
        const code = response.authResponse.code;
  
        try {
          const url = urlAPI_2 + "getAccessToken";
          const lambdaResponse = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ code })
          });
  
          const data = await lambdaResponse.json();
          console.log('Access Token:', data.accessToken);
        } catch (error) {
          console.error('Error al obtener el Access Token:', error);
        }
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, {
      config_id: '3732563590359357', 
      response_type: 'code',   
      override_default_response_type: true,
      scope: 'public_profile,email,business_management,whatsapp_business_management',
      return_scopes: true 
    });
  };
  

  const getDataUser = () => {
    const user = localStorage.getItem("USER_NAME");
    const roleDescription = localStorage.getItem("USER_ROLEDESCRIPTION");
    // console.log("==user==", user, "==rol==", roleDescription);
    setGetUser(user);
    if (roleDescription === "SuperAdmin") {
      setIsSuperAdmin(true);
    } else {
      setIsSuperAdmin(false);
    }

    if (roleDescription === "Administrador") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  };

  const navigate = useNavigate();

  const logOut = () => {
    console.log("Removing USER_TOKEN because logout action");
    localStorage.removeItem("USER_TOKEN");
    localStorage.removeItem("USER_NAME");
    localStorage.removeItem("USER_ROLEDESCRIPTION");
    localStorage.removeItem("USER_ROLEID");
    // navigate('/log-in');
    navigate("/");
  };

  let icon = "";
  const initialWords = getUser.split(" ");
  const firstWord = initialWords[0];
  const secondWord = initialWords[1];
  if (secondWord === undefined) {
    icon = firstWord[0];
  } else {
    icon = firstWord[0] + secondWord;
  }

  const showMenu = () => {
    var widthh = document.getElementById("header-menu");
    widthh = widthh.clientWidth;
    setWidthMenu(widthh);
    if (menu) {
      setMenu(false);
    } else {
      setMenu(true);
    }
  };

  const onChangeTheme = async () => {
    setIsChecked(!isChecked);
    var body = document.getElementById('body-dark')
    queryClient.setQueryData(['isDarkTheme'], !isChecked);
    if(!isChecked == true){body.classList.add("dark")}
    else{body.classList.remove("dark")}
    const values = {
      isDark: !isChecked
    }
    try {
      const url = urlAPI_2 + "users/" + userId;
      const response = await fetch(url, optPUT(values));
      const result = await response.json();
      // console.log("Response of the API", result);
      console.log("........______ DARK ______.......", result.data);
      localStorage.setItem("IS_DARK", result.data.isDark);
      localStorage.setItem("THEME", result.data.isDark);
      queryClient.setQueryData(['isDarkTheme'], result.data.isDark);
      setIsChecked(result.data.isDark)
    } catch (error) {
      console.log(error);
    }
  }

  const location = useLocation();
  const currentPath = location.pathname;
  

  return (
    <>
      {currentPath == "/home/chat" ? (
          <li id="menu-avatar" className="d-flex justify-content-center option-templates" style={{lineHeight: "60px", height: "35px"}}>
            <button
              className="bg-transparent border-0 p-0"
              type="button"
              id="dropdownAvatar"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {/* <PiUserCircleLight className="icon-show-menu pointer" style={{color:"#6d7d88"}}/> */}
              <div id="avatar-nav">
                <Avvvatars value={icon} className="icon-show-menu pointer" />
              </div>
            </button>
            <div id="content-dropdown-avatar" className="dropdown-menu p-2 position-fixed" aria-labelledby="dropdownAvatar">
              <ul className="show-notification profile-notification" style={{ width: widthMenu, position:"initial"}}>
                <li id="user-logged-in" className="d-flex align-items-center py-3 mb-1" style={{height: "2rem", lineHeight: "1"}}>
                  <Avvvatars value={icon} />
                  <span className="ml-2" style={{fontSize:"1.2rem", fontWeight:"700"}}>{getUser}</span>
                </li>
                {isAdmin ? (
                  <>
                    <li className="waves-effect waves-light d-block">
                      <Link to="/home/myData" onClick={() => setMenu(false)}>
                        <BsPersonVcard className="mr-2 ml-0 my-0" style={{ fontSize: "20px" }} />
                        Mis datos
                      </Link>
                    </li>
                    <li className="waves-effect waves-light d-block">
                      <Link to="/home/agents" onClick={() => setMenu(false)}>
                        <HiUserGroup className="mr-2 ml-0 my-0" style={{ fontSize: "20px" }} />
                        Agentes
                      </Link>
                    </li>
                    <li className="waves-effect waves-light d-block">
                      <Link to="#" onClick={() => {launchWhatsAppSignup(); setMenu(false)}}>
                        <BsWhatsapp className="mr-2 ml-0 my-0" style={{ fontSize: "20px" }} />
                        Agregar línea de whatsapp 
                      </Link>
                    </li>
                    <li className="waves-effect waves-light d-block">
                      <Link to="/home/consumptionPayments" onClick={() => setMenu(false)}>
                        <FaRegMoneyBillAlt className="mr-2 ml-0 my-0" style={{ fontSize: "20px" }} />
                        Consumos y pagos
                      </Link>
                    </li>
                  </>
                ) : null}
                {!isSuperAdmin && (
                  <li className="waves-effect waves-light d-block">
                    <Link to="/home/support" onClick={() => setMenu(false)}>
                      <BiSolidHelpCircle className="mr-2 ml-0 my-0" style={{ fontSize: "20px" }} />
                      Soporte
                    </Link>
                  </li>
                )}
                {currentPath == "/home/chat" && 
                  <li className="waves-effect waves-light d-block" id="li-change-theme-h">
                    <div className="switch d-flex">
                      <input
                        id="theme"
                        className="cmn-toggle cmn-toggle-round-flat"
                        type="checkbox"
                        name="theme"
                        checked={isChecked}
                        onChange={onChangeTheme}
                      />
                    </div>
                    {!isChecked == true ?
                      <label className="form-check-label ml-0 pointer d-flex align-items-center" htmlFor="theme">
                          <BsMoon className="ml-0" style={{fontSize: "1.3rem"}}/>
                          <span className="span-change-theme">Modo oscuro</span>
                      </label>
                    :
                      <label className="form-check-label ml-0 pointer d-flex align-items-center" htmlFor="theme">
                        <BsSun className="ml-0" style={{fontSize: "1.3rem"}}/>
                        <span className="span-change-theme">Modo claro</span>
                      </label>
                    }
                  </li>
                }
                <li className="waves-effect waves-light d-block">
                  <a href="" onClick={() => logOut()}>
                    <MdCancel className="mr-2 ml-0 my-0" style={{ fontSize: "20px" }} />
                    Cerrar sesión
                  </a>
                </li>
              </ul>
            </div>
          </li>
      ) : (
        <div className="content-header">
          <ul className="nav-right d-flex">
          {/* <ul className={`nav-right ${currentPath != "/home/chat" && 'd-flex'}`}> */}
            {/* <li className="d-flex align-items-center">
              <div className="su-control su-switch check-dark mt-0 d-flex" style={{height: "1.6rem"}}>
                <div className="switch d-flex">
                  <input
                    id="theme"
                    className="cmn-toggle cmn-toggle-round-flat"
                    type="checkbox"
                    name="theme"
                    checked={isChecked}
                    onChange={onChangeTheme}
                  />
                </div>
                {!isChecked == true ?
                  <label className="form-check-label ml-0 pointer" htmlFor="theme">
                      <BsMoon className="ml-1" style={{fontSize: "1.5rem"}}/>
                  </label>
                :
                  <label className="form-check-label ml-0 pointer" htmlFor="theme">
                      <BsSun className="ml-1" style={{fontSize: "1.5rem"}}/>
                  </label>
                }
              </div>
            </li> */}

            <li className="user-profile header-notification">
              <a
                id="header-menu"
                className={`waves-effect waves-light ${menu ? "active" : null} pt-2`}
                href="javascript:;"
                onClick={() => showMenu()}
              >
                <div className="d-flex align-items-center">
                  <Avvvatars value={icon} />
                  <span className="ml-2 mr-2">{getUser}</span>
                  <FaSortDown style={{ fontSize: "24px", marginBottom: "10px" }}/>
                </div>
              </a>

              {menu ? (
                <ul className="show-notification profile-notification" style={{ width: widthMenu }}>
                  {isAdmin ? (
                    <>
                      <li className="waves-effect waves-light">
                        <Link to="/home/myData" onClick={() => setMenu(false)}>
                          <BsPersonVcard className="mr-2" style={{ fontSize: "20px" }} />
                          Mis datos
                        </Link>
                      </li>
                      <li className="waves-effect waves-light">
                        <Link to="/home/agents" onClick={() => setMenu(false)}>
                          <HiUserGroup className="mr-2" style={{ fontSize: "20px" }} />
                          Agentes
                        </Link>
                      </li>
                      <li className="waves-effect waves-light d-block">
                        <Link to="#" onClick={() => {launchWhatsAppSignup(); setMenu(false)}}>
                          <BsWhatsapp className="mr-2 ml-0 my-0" style={{ fontSize: "20px" }} />
                          Agregar línea de whatsapp 
                        </Link>
                      </li>
                      <li className="waves-effect waves-light">
                        <Link to="/home/consumptionPayments" onClick={() => setMenu(false)}>
                          <FaRegMoneyBillAlt className="mr-2" style={{ fontSize: "20px" }} />
                          Consumos y pagos
                        </Link>
                      </li>
                    </>
                  ) : null}
                  <li className="waves-effect waves-light d-block" id="li-change-theme-h">
                    <div className="switch d-flex">
                      <input
                        id="theme"
                        className="cmn-toggle cmn-toggle-round-flat"
                        type="checkbox"
                        name="theme"
                        checked={isChecked}
                        onChange={onChangeTheme}
                      />
                    </div>
                    {!isChecked == true ?
                      <label className="form-check-label ml-0 pointer d-flex align-items-center" htmlFor="theme" style={{height:"auto"}}>
                          <BsMoon className="ml-0" style={{fontSize: "1.3rem"}}/>
                          <span className="span-change-theme" style={{fontFamily:"Arial, sans-serif"}}>Modo oscuro</span>
                      </label>
                    :
                      <label className="form-check-label ml-0 pointer d-flex align-items-center" htmlFor="theme" style={{height:"auto"}}>
                        <BsSun className="ml-0" style={{fontSize: "1.3rem"}}/>
                        <span className="span-change-theme" style={{fontFamily:"Arial, sans-serif"}}>Modo claro</span>
                      </label>
                    }
                  </li>
                  <li className="p-0">
                    <hr className="my-2" />
                  </li>
                  {!isSuperAdmin && 
                    <li className="waves-effect waves-light">
                      <Link to="/home/support" onClick={() => setMenu(false)}>
                        <BiSolidHelpCircle className="mr-2" style={{ fontSize: "20px" }} />
                        Soporte
                      </Link>
                    </li>
                  }
                    {/* </>
                  ) : null} */}

                  {isSuperAdmin && (
                    <>
                      <li className="waves-effect waves-light">
                        <Link to="/admin" onClick={() => setMenu(false)}>
                          <BsFillBuildingFill className="mr-2" style={{ fontSize: "20px" }} />
                          Compañias
                        </Link>
                      </li>
                      <li className="waves-effect waves-light">
                        <Link to="/consumptionByCompany" onClick={() => setMenu(false)}>
                          <FaMoneyBills className="mr-2" style={{ fontSize: "20px" }} />
                          Consumos por compañia
                        </Link>
                      </li>
                      <li className="waves-effect waves-light">
                        <Link to="/supportAdmin" onClick={() => setMenu(false)}>
                          <BiSolidHelpCircle className="mr-2" style={{ fontSize: "20px" }} />
                          Soporte
                        </Link>
                      </li>
                    </>
                  )}

                  <li className="waves-effect waves-light">
                    <a href="" onClick={() => logOut()}>
                      <MdCancel className="mr-2" style={{ fontSize: "20px" }} />
                      Cerrar sesión
                    </a>
                  </li>
                </ul>
              ) : null}
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default Header;
