import React, { useState, useEffect } from "react";
import { Handle, Position } from 'reactflow';
import useAPI from "../../../../hooks/useAPI";

import { FaBrain } from "react-icons/fa";
import { FaEllipsisVertical } from "react-icons/fa6";

const AssitantAI = ({ data, isConnectable, id }) => {
    const { optGET, urlAPI_2 } = useAPI(); 
    const [listAssistants, setListAssistants] = useState([]);

    const getAssistants = async () => {
        try {
          const url = urlAPI_2 + "assistants";
          const response = await fetch(url, optGET());
          const result = await response.json();
          if (result.type === "error") {
            Swal.fire({
              title: "¡Atención!",
              text: result.message,
              icon: result.type,
            });
          } else if (!result.data) {
            setListAssistants([])
          } else {
            setListAssistants(result.data)
          }
        } catch (error) {
          console.log('error---->', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
            await Promise.all([
                getAssistants(),
            ]);
            } catch (error) {
            console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    let assistant = ""
    if(listAssistants.length) {
        const find = listAssistants.find(v => v._id == data.assistant)
        if(find) {
            assistant = find.name
        } 
    }
    
    return (
        <div className="rounded" style={{width: "180px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", cursor: 'pointer'}}>
            <Handle type="target" position={Position.Top} isConnectable={isConnectable}  style={{backgroundColor: '#A7ADA7'}}/>
            <div>
                <div 
                    className="d-flex align-items-center rounded-top justify-content-between" 
                    style={{height: "10px", backgroundColor: "#A7ADA7", padding: "12px"}}
                >
                    <div className="d-flex align-items-center">
                        <FaBrain className="mr-1" style={{fontSize: "12px", color: "#fff"}}/>
                        <span style={{fontSize: "10px", color: "#fff"}} >
                            {data.title ?? "Asistente IA"}
                        </span>
                    </div>
                    {!data.first && (
                        <div className="dropdown">
                            <button
                                className="bg-transparent border-0 p-0"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <FaEllipsisVertical className="text-white pointer mt-1" />
                            </button>
                            <div
                                className="dropdown-menu"
                                style={{minWidth: "60px"}}
                                aria-labelledby="dropdownMenuButton"
                            > 
                                <button 
                                    className="text-danger border-0 bg-transparent pointer" 
                                    style={{height: "20px"}} 
                                    onClick={() => {data.onRemove(id)}}
                                >
                                    <span className="text-dark ml-1 f-10">
                                        Eliminar
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="bg-white rounded-bottom" style={{padding: '8px'}}>
                    {data.assistant && (
                        <div 
                            className="p-1 mb-2" 
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >    
                            <div>
                                <span style={{fontWeight: "bold", fontSize: "9px"}}>
                                    Asistente:
                                </span>
                            </div>
                            <div className="d-flex align-items-center">
                                <p 
                                    style={{fontWeight: "bold", fontSize: "9px", backgroundColor: "#E6E6E6", borderRadius: "4px"}} 
                                    className="m-0 p-1 mb-1"
                                >
                                    {assistant}
                                </p>
                            </div>
                        </div>
                    )}
                    {data.user_response && (
                        <div 
                            className="p-1 mb-2" 
                            style={{
                                backgroundColor: "rgba(167, 173, 167, 0.3)",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Respuesta del Cliente:</span>
                            <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                {data.user_response}
                            </p>
                        </div>
                    )}
                    {data.bot_response && (
                        <div 
                            className="p-1 mb-2" 
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Respuesta del Bot:</span>
                            <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                {data.bot_response}
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <Handle 
                type="source" 
                position={Position.Bottom} 
                id="b" 
                isConnectable={isConnectable}  
                style={{backgroundColor: '#A7ADA7'}}
            />
        </div>
    )
}

export default AssitantAI;