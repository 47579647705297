import React, { useState, useEffect } from "react";
import useAPI from "../../hooks/useAPI";
import Header from "../layout/Header";
import Spinner from "../plugins/Spinner";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { BsPencilFill, BsTrash3, BsPlusSquare } from "react-icons/bs";
import { LuCalendarClock } from "react-icons/lu";
import Modal from "../layout/Modal";
import styled from "styled-components";



const BusinessHours = () => {
  const [companie, setCompanie] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(false);
  const [modalSchedule, setModalSchedule] = useState(false);

  const [weekdays, setWeekdays] = useState([]);
  const [schedule, setSchedule] = useState("");
  const [daySelected, setDaySelected] = useState("");
  const [timeFields, setTimeFields] = useState([{ desde: '', hasta: '' }]);
  const [selectedDays, setSelectedDays] = useState([]);

  const { urlAPI, optPOST, optGET, optPUT, optDELETE, urlAPI_2 } = useAPI();

  const {watch,register,handleSubmit,formState: { errors },reset,} = useForm({defaultValues: {}});

  useEffect(() => {
    getCompanieHours();
  }, []);

  const getCompanieHours = async () => {
    const resultIdCompany = localStorage.getItem("ID_COMPANY");
    console.log("id compañia: ", resultIdCompany);
    console.log("id", resultIdCompany);
    try {
      setLoading(true);
      const url = urlAPI_2 + "companies/" + resultIdCompany;
      const response = await fetch(url, optGET());
      const result = await response.json();
      console.log("Result...............", result);
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (!result.data) {
        setValidation(true);
        setCompanie([]);
      } else {
        setCompanie(result.data[0]);
        setValidation(false);
        const {
          typeSchedule,
          schedules
        } = result.data[0];
        
        var object = []
        for(var day of schedules){
          if (day.status) {object.push(String(day.id))}
        }
        setSelectedDays(object)
        setWeekdays(schedules)
        setSchedule(typeSchedule)
        reset({
            typeSchedule,
          },
          { keepDefaultValues: true }
        );
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const putCompanyHours = async (inputs) => {
    const { typeSchedule } = inputs;

    console.log('selectedDays=====>', selectedDays)
    console.log('typeSchedule=====>', typeSchedule)

    const updateStatus = weekdays.map((day) => {
      if (selectedDays) {
        if (selectedDays.includes(String(day.id))) {
          var obj1 = {}
          if (schedule == 1) {
            obj1 = { ...day, status: true}
          }else {
            obj1 = { ...day, status: true, schedule: [{desde: "01:00 am", hasta: "11:59 pm"}]}
          }
          return obj1;
        }else{
          var obj2 = {}
          if (schedule == 1) {
            obj2 = { ...day, status: false}
          }else {
            obj2 = { ...day, status: false, schedule: [{desde: "01:00 am", hasta: "11:59 pm"}]}
          }
          return obj2;
        }
      }
    });
    setWeekdays(updateStatus);


    const valuess = {
      typeSchedule,
      schedules: updateStatus,
    };

    console.log("inputs::", inputs);
    console.log("valuess::", valuess);

    const id = companie._id;
    const values = inputs;
    try {
      setLoading(true);
      const url = urlAPI_2 + "companies/" + id;
      const response = await fetch(url, optPUT(valuess));
      const result = await response.json();
      console.log("Result of the API: Put Commpany", result);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  //#--> Agregar un nuevo campo
  const addSchedule = () => {
    console.log('Agregando campos.....')
    setTimeFields([...timeFields, { desde: '', hasta: '' }]);
  };

  //#--> Eliminar un campo
  const deleteSchedule = (index) => {
    var newFields = [...timeFields];
    newFields.splice(index, 1);
    setTimeFields(newFields);
  };

  //#--> Manejar cambios en los campos
  const handleChange = (index, field, value) => {
    var newTimeFields = [...timeFields];
    newTimeFields[index][field] = value;
    setTimeFields(newTimeFields);
  };

  //#--> Convertir la hora a am o pm
  const convertToAmPm = (time) => {
    const [hours, minutes] = time.split(':');
    let formattedTime = '';
    if (hours < 12) {formattedTime = `${hours}:${minutes} am`;} 
    else if (hours === '12') {formattedTime = `${hours}:${minutes} pm`;}
    else {
      const formattedHours = (hours - 12) > 9 ? hours - 12 : `0${hours - 12}`;
      formattedTime = `${formattedHours}:${minutes} pm`;
    }
    return formattedTime;
  };

  //#--> Guardar las horas seleccionadas
  const onSubmit = async (data) => {
    const { desde1, hasta1, desde2, hasta2 } = watch()
    console.log('leyendo campos:::: =', desde1, hasta1, desde2, hasta2);

    const formattedD1 = convertToAmPm(desde1);
    const formattedH1 = convertToAmPm(hasta1);
    let objTimes = [];
    if (desde2 || hasta2) {
      const formattedD2 = convertToAmPm(desde2);
      const formattedH2 = convertToAmPm(hasta2);
      objTimes.push({desde: formattedD1, hasta: formattedH1})
      objTimes.push({desde: formattedD2, hasta: formattedH2})
    } else {
      objTimes.push({desde: formattedD1, hasta: formattedH1})
    }

    const updatedWeekdays = weekdays.map((day) => {
      if (day.name === daySelected) {
        return { ...day, schedule: objTimes };
      }
      return day;
    });

    setWeekdays(updatedWeekdays);
    setModalSchedule(false);
    reset({desde1: "", hasta1: "", desde2: "", hasta2: ""});
    setTimeFields([{ desde: '', hasta: '' }]);
    setDaySelected("");

  };

  const onScheduleSelected = async (idSelected) => {
    const filtDay = weekdays.find(day => day.id === idSelected);
    console.log('Id seleccionado:', filtDay);
  
    // Crear una copia profunda del objeto utilizando JSON.parse() y JSON.stringify()
    const copiaFiltDay = JSON.parse(JSON.stringify(filtDay));
  
    console.log('Objeto schedule:', copiaFiltDay.schedule);
  
    var hora11 = "";
    var hora22 = "";
    var objFinal = {};
    var arrayFinal = [];
    for (var i of copiaFiltDay.schedule) {
      hora11 = convertTime(i.desde);
      hora22 = convertTime(i.hasta);
      i.desde = hora11;
      i.hasta = hora22;
      objFinal = i;
      console.log('objeto a guardar:', objFinal);
      arrayFinal.push(objFinal);
    }
    if (arrayFinal.length > 1) {
      reset({
        desde1: arrayFinal[0].desde,
        hasta1: arrayFinal[0].hasta,
        desde2: arrayFinal[1].desde,
        hasta2: arrayFinal[1].hasta
      });
    } else {
      reset({
        desde1: arrayFinal[0].desde,
        hasta1: arrayFinal[0].hasta
      });
    }
    setTimeFields(arrayFinal);
    console.log('Array a guardar:', arrayFinal);
  }

  //#--> Convertir hora a militar
  const convertTime = (time) => {    
    const [hour, amPm] = time.split(' ');
    console.log('=========>', hour,'======>', amPm)
    let formattedTime = '';
    if (amPm.toLowerCase() == "am") { 
      formattedTime = hour 
    }else {
      const [h, m] = hour.split(':');
      var fH = ""
      if (parseInt(h) === 12) {fH = 12}
      else {fH = (parseInt(h) + 12)}
      formattedTime = `${fH}:${m}`;
    }
    return formattedTime;
  }

  return (
    <>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}

      <div className="m-auto container-fluid p-0 h-100">
        <div className="col-xs-9 heading">
          <div>
            <h1>Horario comercial</h1>
          </div>

          <Header />
        </div>

        <div className="container-fluid mt-3 px-0" style={{ height: "90%", overflowY: "auto" }}>
          <section className="module-create-user myData" style={{ height: "100%", backgroundColor: "transparent", boxShadow: "none" }}>
            <div className="h-100">
              <form onSubmit={handleSubmit(putCompanyHours)} className="h-100">
                <div className="sections-my-data h-100 mb-0">
                  <h2 className="d-flex align-items-center" style={{ fontSize: "1.4em", padding: "20px 20px 0px" }}>
                    <LuCalendarClock style={{ fontSize: "25px", marginRight: "10px" }} />
                    Horario comercial
                  </h2>
                  <div className="module-section border-top-0" id="product-basic" style={{height: "78%", overflow: "auto"}}>
                    <div className="row h-100">
                      <div className="col-md-12 h-100">
                        <div 
                          className="row h-100" 
                          style={{
                            // flexDirection: "column",
                            display: "block"
                          }}
                        >
                          <div className="col-md-4 my-0 mx-auto d-flex align-items-end">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-0">
                              <label htmlFor="select">Horario: </label>
                              <select
                                name="typeSchedule"
                                id="typeSchedule"
                                className="form-control"
                                {...register("typeSchedule")}
                                onChange={(e) => setSchedule(e.target.value)}
                              >
                                <option value="" key="0" disabled selected>
                                  Seleccione...
                                </option>
                                <option value="1" key="1" >
                                  Abierto durante un horario específico.
                                </option>
                                <option value="2" key="2" >
                                  Siempre abierto
                                </option>
                              </select>
                            </div>

                            {schedule &&
                              <div className="ml-2 d-flex align-items-end">
                                <BsTrash3 className="text-danger pointer" style={{fontSize: "18px"}} onClick={() => {setSchedule(""); reset({typeSchedule: ""})}}/>
                              </div>
                            }
                          </div>
                          
                          {schedule && (
                            <div className="col-md-6 mt-3 mx-auto">
                              {weekdays.map((day, d) => (
                                <div 
                                  key={day.id} 
                                  className="list-days pointer su-control su-with-prefix su-with-suffix su-segmentation-disable mt-0 pt-3 py-2 px-2 d-flex justify-content-between align-items-center"
                                  style={{borderBottom: "1px solid #ccc"}}
                                >
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <label>{day.name}</label>
                                      {day.status === true ?
                                        schedule == 1 ? 
                                          <div>
                                            {day.schedule.map((time, t) => (
                                              <div key={t}>
                                                <span className="mr-2">{time.desde} - {time.hasta}</span>
                                                <br/>
                                              </div>
                                            ))}
                                          </div> 
                                        :
                                          <div>Abierto las 24 horas</div>
                                      :
                                      <div>Cerrado</div>
                                      }
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    {schedule == 1 && day.status ?
                                      <BsPencilFill 
                                        className="BsPencilFill text-primary" 
                                        style={{fontSize: "20px"}}
                                        onClick={() => {
                                          setModalSchedule(true);
                                          setDaySelected(day.name);
                                          onScheduleSelected(day.id);
                                        }} 
                                      />
                                    : null}
                                    <input
                                      name={day.id}
                                      id={day.id}
                                      type="checkbox"
                                      value={day.id}
                                      className="cmn-toggle cmn-toggle-round-flat"
                                      defaultChecked={day.status}
                                      // {...register("dataCheck")}
                                      onChange={(e) => {
                                        const updatedWeekdays = [...weekdays];
                                        updatedWeekdays[d].status = e.target.checked;
                                        setWeekdays(updatedWeekdays);

                                        const dayId = e.target.value;
                                        if (e.target.checked) {
                                          setSelectedDays([...selectedDays, dayId]);
                                        } else {
                                          setSelectedDays(selectedDays.filter(id => id !== dayId));
                                        }
                                      }}
                                    />
                                    <label className="ml-4" htmlFor={day.id}></label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <footer className="m-0" style={{height: "12%"}}>
                    <div className="row">
                      <div className="col-sm-6"></div>
                      <div className="col-sm-6 right">
                        <input
                          className="btn btn-lg btn-info"
                          value="Actualizar"
                          type="submit"
                        />
                      </div>
                    </div>
                  </footer>
                </div>

              </form>
            </div>
          </section>
        </div>
      </div>


      <Modal
        status={modalSchedule}
        changeStatus={setModalSchedule}
        title={daySelected}
        width={"700px"}
      >
        <Content>
          <form style={{ width: "100%" }}>
            {timeFields.map((field, index) => (
              <div key={index} className="d-flex justify-content-evenly align-items-center">
                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                  <label>Desde: </label>
                  <input
                    type="time"
                    name={`desde${index + 1}`}
                    className="form-control pointer"
                    defaultValue={field.desde}
                    {...register(`desde${index + 1}`)}
                    onChange={(e) => handleChange(index, 'desde', e.target.value)}
                  />
                  {errors[`desde${index + 1}`] && <Alert>* {errors[`desde${index + 1}`].message}</Alert>}
                </div>
                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                  <label>Hasta: </label>
                  <input
                    type="time"
                    name={`hasta${index + 1}`}
                    className="form-control pointer"
                    defaultValue={field.hasta}
                    {...register(`hasta${index + 1}`)}
                    onChange={(e) => handleChange(index, 'hasta', e.target.value)}
                  />
                  {errors[`hasta${index + 1}`] && <Alert>* {errors[`hasta${index + 1}`].message}</Alert>}
                </div>
                <div>
                  <BsTrash3 className="text-danger pointer" style={{fontSize: "18px"}} onClick={() => deleteSchedule(index)}/>
                </div>
              </div>
            ))}

            {timeFields.length < 2 &&
              <div className="mt-4 d-flex justify-content-center">
                <button 
                  type="button" 
                  className="border-0 bg-transparent d-block pointer btn-personalize d-flex align-items-center" 
                  onClick={() => addSchedule()}
                  >
                  <BsPlusSquare style={{fontSize: "16px"}} />
                  <span className="ml-2 f-14">
                    <strong>Añadir horario</strong>
                  </span>
                </button>
              </div>
            }

            <div className="row justify-content-end bd col-12 mt-4">
              <a 
                href="javascript:;" 
                className="btn btn-lg btn-default mr-1" 
                onClick={() => {
                  setModalSchedule(false);
                  // setTimeFields([{ desde: '', hasta: '' }]);
                }}
              >
                Cancelar
              </a>
              <input 
                className="btn btn-lg btn-info" 
                value="Aceptar" 
                // type="submit" 
                type="button" 
                onClick={() => {
                  onSubmit();
                }}
              />
            </div>
          </form>

        </Content>
      </Modal>

    </>
  );
};

export default BusinessHours;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  li {
    padding: 10px 5px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.05);
  }
  li:hover {
    background: rgb(0, 0, 0, 0.05);
  }
  p {
    font-size: 18px;
    margin-bottom: 0px;
  }
  input {
    padding: 10px;
    border-radius: 0px;
    font-size: 17px;
  }
  img.img-answer {
    cursor: pointer;
    width: auto;
    height: 180px;
    object-fit: scale-down;
  }
  .content-img {
    position: relative;
  }
  // .content-img .icon-eye {
  //   cursor: pointer;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }
  .content-img img:hover {
    opacity: 0.6;
  }
`;

const Alert = styled.span`
  color: red;
  display: block;
  width: 250px;
`;