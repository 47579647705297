import React, { Fragment, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Swal from "sweetalert2";
import Iphone from "../../imgs/IPhone_X.png";
import Iphone2 from "../../imgs/Iphone_2.png";
import mImage from "../../imgs/medio-img.png";
import mVideo from "../../imgs/medio-video.png";
import mDocument from "../../imgs/medio-doc.png";
import Picker from "emoji-picker-react";
import useAPI from "../../hooks/useAPI";
import Spinner from "../plugins/Spinner";
import Select from "react-select";
import ReactPlayer from "react-player";
//ICONOS
import { IoMegaphoneSharp, IoClose } from "react-icons/io5";
import { BsBellFill, BsPlusSquareDotted, BsFillTelephoneFill } from "react-icons/bs";
import { FaKey } from "react-icons/fa6";
import { FaListUl } from "react-icons/fa";
import { TiArrowBack, TiExport } from "react-icons/ti";
import { HiOutlineLightBulb } from "react-icons/hi";

//Componentes
import Header from "../layout/Header";
import { upload_s3 } from './UploaderS3';

const TemplateWhatsapp = () => {
  const categories = [
    {
      id: 1,
      name: "Marketing",
      description: "Promociones o información sobre tu negocio, productos o servicios, o cualquier mensaje que no sea de utilidad o autenticación.",
    },
    {
      id: 2,
      name: "Utilidad",
      description: "Mensajes sobre una transacción, cuenta, pedido o solicitud de cliente en particular.",
    }
    // {
    //   id: 3,
    //   name: "Autenticación",
    //   description: "Contraseñas de un solo uso que utilizan tus clientes para autenticar una transacción o un inicio de sesión.",
    // },
  ];

  const opt_aut = [
    {
      id: 1,
      name: "Autocompletar",
      description: "Se recomienda esta opción como la más fácil para los clientes. Cuando uno de ellos toca el botón, el código se envía directamente a tu app. Si se activa la función sin toque, el código se enviará automáticamente sin requerir al cliente que toque el botón. Se enviará un mensaje para copiar el código si la opción de autocompletar no está disponible.",
    },
    {
      id: 2,
      name: "Copiar código",
      description: "Autenticación básica con configuración rápida. Los clientes copian y pegan el código en tu app.",
    }
  ];

  const listLenguagues = [
    {
      id:1,
      description: "Español"
    },
    {
      id:2,
      description: "Ingles"
    },
    // {
    //   id:3,
    //   description: "Chino"
    // },
  ]

  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("createwsp");
  const [date, setDate] = useState("");
  const [categorys, setCategorys] = useState([]);
  const [lenguagesList, setLenguagesList] = useState([]);
  const [viewList, setViewList] = useState(false);
  const [lenguageSelected, setLenguageSelected] = useState([]);
  const [validation, setValidation] = useState(false);

  const [selectedLenguages, setSelectedLenguages] = useState("");

  const [categoryTemplate, setCategoryTemplate] = useState("");
  const [viewCategory, setViewCategory] = useState(false);

  const [headerType, setHeaderType] = useState("");
  const [selectedMedia, setSelectedMedia] = useState("");
  const [textHeader, setTextHeader] = useState("");
  const [textFooter, setTextFooter] = useState("");
  const [buttonType, setButtonType] = useState("");
  const [isListButtons, setIsListButtons] = useState(false);
  const [actionType, setActionType] = useState("callThePhoneNumber");
  const [actionType2, setActionType2] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [buttonTextGoToWebsite, setButtonTextGoToWebsite] = useState("");
  const [buttonText2, setButtonText2] = useState("");
  const [buttonTextGoToWebsite2, setButtonTextGoToWebsite2] = useState("");
  const [buttonTextFastAnswer, setButtonTextFastAnswer] = useState("");
  const [buttonTextFastAnswer2, setButtonTextFastAnswer2] = useState("");
  const [addAnotherButton, setAddAnotherButton] = useState(false);
  const [addAnotherButtonFastAnswer, setAddAnotherButtonFastAnswer] = useState(false);
  const [isBotton, setIsBotton] = useState(false);
  const [bottons, setBottons] = useState(false);
  const [header, setHeader] = useState(false);
  const [count, setCount] = useState(1);
  const [countArray, setCountArray] = useState([]);
  const [typeMessages, setTypeMessages] = useState("");
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [inputStr, setInputStr] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [notVariableHeader, setNotVariableHeader] = useState(false);
  const [notVariable, setNotVariable] = useState(false);
  const [warningMessages, setWarningMessages] = useState(false);
  const [data, setData] = useState("");
  const [urlType, setUrlType] = useState("");
  const [urlType2, setUrlType2] = useState("");
  
  const [isIncludeVariable, setIsIncludeVariable] = useState(false);
  const [isIncludeVariableBody, setIsIncludeVariableBody] = useState(false);
  const [isDark, setIsDark] = useState(false);

  const [selectedImage, setSelectedImage] = useState("");
  const [documentt, setDocumentt] = useState("");
  const [selectedDocument, setSelectedDocument] = useState("");
  const [selectedVideo, setSelectedVideo] = useState("");
  const [videoo, setVideoo] = useState("");

  const [uploadedFile, setUploadedFile] = useState({});
  const [sizeMax, setSizeMax] = useState(false);
  const [errorLenguages, setErrorLenguages] = useState(false);

  const [url_1, setUrl_1] = useState("");
  const [url_2, setUrl_2] = useState("");

  //# Token para permisos de los servicios
  const token = localStorage.getItem("USER_TOKEN");

  const { urlAPI, optGET, urlAPI_1, urlAPI_2 } = useAPI();

  const urlFile = useRef("");
  const nameFile = useRef("");
  const sizeFile = useRef("");
  const templateIdRef = useRef("");

  //# React-hook-form para establecer validaciones
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "all",
    defaultValues: {
      category: "",
      name: "",
      languages: [],
      headerType: "",
      headerText: "",
      sampleVariableHeader: "",
      media: "",
      sampleMedia: "",
      body: "",
      sampleVariableBody: "",
      footer: "",
      // buttonType: "",
      // fastAnswerText: "",
      // fastAnswerText2: "",
      // actionType: "",
      // actionType2: "",
      // buttonTextAction: "",
      // buttonTextAction2: "",
      // buttonTextActionGoToWebsite: "",
      // buttonTextActionGoToWebsite2: "",
      // country: "",
      // country2: "",
      // phoneNumber: "",
      // phoneNumber2: "",
      // urlType: "",
      // urlType2: "",
      // websiteUrl: "",
      // websiteUrl2: "",
    },
  });

  useEffect(() => {
    // getLanguages();
    setCategorys(categories);
    // getTypeMessages();
    setLenguagesList(listLenguagues)
    getIsDark()
  }, []);

  const getTypeMessages = async () => {
    try {
      setLoading(true);
      const url = urlAPI + "messagetypes";
      const response = await fetch(url, optGET());
      const result = await response.json();
      const typeId = result.data[1]._id;
      setTypeMessages(typeId);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getLanguages = async () => {
    try {
      setLoading(true);
      const url = urlAPI + "languages";
      const response = await fetch(url, optGET());
      const result = await response.json();
      const listL = result.data;
      console.log("Lenguajes nuevos", listL);
      setLenguagesList(listL);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onEmojiClick = (event, emojiObject) => {
    setInputStr((prevInput) => prevInput + emojiObject.emoji);
    // setShowPicker(false);
  };

  const onValidation = () => {
    const { category, name, languages, errors } = watch();
    console.log(lenguageSelected)

    if (lenguageSelected.length <= 0) {
      setErrorLenguages(true);
    } else {
      setCategoryTemplate(category);
      setViewList(false);
      setErrorLenguages(false);
      setMode("createwsp-2");
      const today = new Date();
      var hours = today.getHours();
      var minutes = today.getMinutes();
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      var time = hours + ":" + minutes;
      setDate(time);
      console.log("Hora actual:", time);
    }
  };

  const onListLenguages = () => {
    const { languages } = watch();
    setLenguageSelected(languages);
    // console.log("list leng==>", languages);
  };

  const onHeaderType = (e) => {
    const {
      category,
      name,
      languages,
      headerType,
      headerText,
      media,
      body,
      footer,
    } = watch();

    reset({
      category,
      name,
      languages,
      headerType: e.target.value,
      headerText: "",
      media: "",
      body,
      footer,
    });
    setSelectedMedia("");
    setTextHeader("");
    setHeaderType(e.target.value);
    setIsIncludeVariable(false)
    setSelectedImage("")
    setSelectedDocument("")
    setSelectedVideo("")
    // setSelectedMedia(1)
    // reset({media: 1})
  };

  const onBoby = (e) => {
    setInputStr(e.target.value);
    // console.log('contenido del Body: ', e.target.value);
  };

  const onAddVariableHeader = () => {
    if (textHeader.includes("{{1}}")) {
      console.log("El texto contiene '{{1}}'.");
      setNotVariableHeader(true);
    } else {
      console.log("No tiene variable aun, se agrega");
      const resContent = textHeader + " {{1}} ";
      setTextHeader(resContent)
      setNotVariableHeader(false)
      setIsIncludeVariable(true)
    }
  };

  const onAddVariable = async () => {
    setCount(count + 1);
    console.log("count--->", count);
    let array = []
    let arrayFinal = []
    if (count <= 10) {
      const variable = `{{${count}}}`;
      const resContent = inputStr + " " + variable + " ";
      setInputStr(resContent);
      setNotVariable(false);
      arrayFinal = [...countArray, `{{${count}}}`]
      if (resContent.includes(`{{${count}}}`)) {
        setIsIncludeVariableBody(true);
      } else {
        setIsIncludeVariableBody(false)
      }
    } else {
      setNotVariable(true);
      arrayFinal = [...countArray]
    }
    setCountArray(arrayFinal)
  };

  const onBottonType = (e) => {
    setButtonType(e.target.value);
    const {
      category,
      name,
      languages,
      headerType,
      headerText,
      media,
      body,
      footer,
    } = watch();

    reset({
      category,
      name,
      languages,
      headerType,
      headerText,
      media,
      body,
      footer,
    });

    setButtonText("");
    setButtonText2("");
    setButtonTextGoToWebsite("");
    setButtonTextGoToWebsite2("");
    setButtonTextFastAnswer("");
    setButtonTextFastAnswer2("");
    setAddAnotherButton(false);
    setAddAnotherButtonFastAnswer(false);
    setIsBotton(true)
  };

  const onActionType = (e) => {
    setActionType(e.target.value);
    const {
      category,
      name,
      languages,
      headerType,
      headerText,
      media,
      body,
      footer,
    } = watch();

    reset({
      category,
      name,
      languages,
      headerType,
      headerText,
      media,
      body,
      footer,
    });
    setButtonText("");
    setButtonText2("");
    setButtonTextGoToWebsite("");
    setButtonTextGoToWebsite2("");
    setUrlType("")
  };

  const onActionType2 = (e) => {
    setActionType2(e.target.value);
    console.log("tipo de acción2", e.target.value);
    setButtonText2("");
    setUrlType2("")
  };

  const onDeleteBotton = () => {
    const {
      category,
      name,
      languages,
      headerType,
      headerText,
      media,
      body,
      footer,
    } = watch();
    console.log("Eliminando....");
    setAddAnotherButton(false);
    reset({
      category,
      name,
      languages,
      headerType,
      headerText,
      media,
      body,
      footer,
    });
    setButtonText2("");
    setButtonTextGoToWebsite2("");
    setIsBotton(true)
    setUrlType2("")
  };

  const onDeleteBottonFastAnswer = () => {
    const {
      category,
      name,
      languages,
      headerType,
      headerText,
      media,
      body,
      footer,
    } = watch();
    console.log("Eliminando....");
    setAddAnotherButtonFastAnswer(false);
    reset({
      category,
      name,
      languages,
      headerType,
      headerText,
      media,
      body,
      footer,
    });
    setButtonTextFastAnswer2("");
    setIsBotton(true)
  };

  const onKeyUp = (e) => {
    const {category,name,languages,headerType,headerText,media,body,footer} = watch();
    const vlr = e.target.value;
    const min = vlr.toLowerCase()
    const result = min.replace(/\s+/g, "_");

    reset({category,name: result,languages,headerType,headerText,media,body,footer});
  };

  const onSaveAll = async () => {
    const {category,name,languages,headerType,headerText,sampleVariableHeader,media,sampleMedia,body,sampleVariableBody,footer} = watch();

    let arrayVariablesBody = []
    for (let i = 0; i < countArray.length; i++) {
      const varName = `sampleVariableBody${i + 1}`;
      arrayVariablesBody.push(watch(varName))
    }
    console.log('----arrayVariablesBody----', arrayVariablesBody);
    

    const removeSpaces = inputStr.trim();
    const initial = removeSpaces.startsWith("{{");
    const final = removeSpaces.endsWith("}}");
    console.log("Posición inicial ", initial);
    console.log("Posición final ", final);
    if (initial || final) {
      console.log("No puedes iniciar y finalizar el contenido con una variable");
      setWarningMessages(true);
    } else {
      console.log("Correcto..........");
      setWarningMessages(false);

      let sortedButtons = [...listOfAddedButtons];

      //#--> Ordenamos la lista de acuerdo a su type
      sortedButtons.sort((a, b) => a.type - b.type);
      
      console.log("Lista de botones..........1.........", listOfAddedButtons);
      console.log("Lista de botones..........2.........", sortedButtons);

      let listButtons = []
      // for (let btn of listOfAddedButtons) {
      for (let btn of sortedButtons) {
        if (btn.type === 1 || btn.type === 2) {
          let objBtnFastAnswer = {}
          objBtnFastAnswer.type = "QUICK_REPLY"
          objBtnFastAnswer.text = btn.fields[0]
          listButtons.push(objBtnFastAnswer)
        }
        if (btn.type === 3) {
          let objBtnGoToWebsite = {}
          objBtnGoToWebsite.type = "URL"
          objBtnGoToWebsite.text = btn.fields[0]
          if (btn.urlType === "dynamic") {
            objBtnGoToWebsite.url = btn.fields[2]+'{{1}}'
            const sampleUrl = btn.exampleUrl.replace(btn.fields[2], "")
            objBtnGoToWebsite.example = [sampleUrl]
          } else {
            objBtnGoToWebsite.url = btn.fields[2]
          }
          listButtons.push(objBtnGoToWebsite)
        }
        if (btn.type === 4) {
          let objBtnCallThePhoneNumber = {}
          objBtnCallThePhoneNumber.type = "PHONE_NUMBER"
          objBtnCallThePhoneNumber.text = btn.fields[0]
          objBtnCallThePhoneNumber.phone_number = btn.fields[1]+btn.fields[2]
          listButtons.push(objBtnCallThePhoneNumber)
        }
      }

      const mainObject = {
        name,
        languages: lenguageSelected.id,
        category,
        header: {
          headerType,
          headerText,
          sampleVariableHeader,
          media,
          sampleMedia: {
            link: urlFile.current,
            size: sizeFile.current,
            name: nameFile.current,
            type: uploadedFile.type
          }
        },
        body,
        sampleVariableBody: arrayVariablesBody,
        footer: isButtonStopPromotions ? '¿No te interesa? Toca "Detener promociones"' : footer,
        buttons: {
          listButtons
        },
        status: true,
      };
      console.log("Objeto a guardar===>", mainObject);
      
      try {
        setLoading(true);
        const url = urlAPI_2 + "template";
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          body: JSON.stringify(mainObject),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const result = await response.json();
        console.log("richarddddd:::", result);
        setLoading(false);
        if (result.code === 0) {
          Swal.fire({
            title: "¡Atención!",
            text: result.message,
            icon: result.type,
            confirmButtonText: "Aceptar",
          });
        } else {
          console.log("Plantilla para whatsapp....", result);
                    
          Swal.fire({
            title: "¡Bien!",
            text: result.message,
            icon: result.type,
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Sincronizar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              setLoading(true);
              const url = urlAPI_2 + "syncTemplates";
              const response = await fetch(url, {
                mode: "cors",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              });
              const result = await response.json();
              console.log("Resultado sincronización ==========>", result);
              setLoading(false);
              if (result.type === "error") {
                Swal.fire({
                  title: "¡Atención!",
                  text: result.message,
                  icon: result.type,
                });
              } else if (result.type === "warning") {
                Swal.fire({
                  title: result.message,
                  text: "¿Desea ir a configurar el campo?",
                  icon: result.type,
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Sí',
                  cancelButtonText: 'Cancelar'
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.href = "/#/home/myData";
                  }
                })
              }else if (result.message === 'Tu petición no tiene cabecera de autorización') {
                Swal.fire({
                  title: "¡Atención!",
                  text: 'Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales',
                  icon: 'info',
                });
                navigate('/');
              } else {
                // getTemplates();
                Swal.fire({
                  title: "¡Bien!",
                  text: result.message,
                  icon: result.type,
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    navigate("/home/templates");
                  }
                })
              }
            } else {
              navigate("/home/templates");
            }
          })
          reset();
        }
      } catch (error) {
        console.log('error-error-error-error:', error);
      }
    }
  };

  const handleChangeLanguages = (lenguage) => {
    console.log('Lenguaje seleccionado=====>>', lenguage);
    // setErrorCountrie(false);
    setLenguageSelected(lenguage);
  };

  const navigate = useNavigate();
  const param = "{{1}}";

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'lightblue' : '#fff',
      color: state.isSelected ? '#333' : '#333',
      '&:hover': {
        backgroundColor: 'lightgray',
        color: '#333',
        cursor: 'pointer',
      },
    }),
  };

  const customStylesDark = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#455a64' : '#202c33',
      color: state.isSelected ? 'white' : 'white',
      '&:hover': {
        backgroundColor: '#374954',
        color: 'white',
        cursor: 'pointer',
      },
    }),
  };

  const getIsDark = () => {
    const dark = localStorage.getItem("THEME");
    setIsDark(dark);
  }

  //Convertit imagen en base64
  const onFile = async (e) => {
    var file = e.target.files[0];
    setUploadedFile({size:file.size, type:file.type, name:file.name})
    console.log('========> ',file);
    var base64 = await convertBase64(file);
    if (file.type.includes("image")) {
      console.log('====IMAGEN====> ');
      setSelectedImage(base64);
    } else if (file.type.includes("video")) {
      console.log('====VIDEO====> ');
      setVideoo(URL.createObjectURL(file));
      console.log("url del video: ", videoo);
      console.log("url 2.....", URL.createObjectURL(file));
      setSelectedVideo(base64);
    } else {
      console.log('====DOCUMENTO====> ');
      setDocumentt(URL.createObjectURL(file));
      setSelectedDocument(base64);
    }
    await uploadToS3(file);
  };

  //Convertit medio en base64
  const convertBase64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };

  const getSignedUrl = async (key_name, size) => {
    try {
      key_name = encodeURIComponent(key_name);
      const url = urlAPI_1 + `getSignedURL/${key_name}/${size}`;
      const response = await fetch(url, {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
      });
      if(response.status == 200){
          let url_signed = await response.json();
          if(url_signed.error){
              return false;
          }
          return url_signed;
      }else{
          console.error('Error getting signed URL');
          return false;
      }
      return response;
    } catch (error) {
      console.error('Error getting signed URL:', error);
      return false;
    }
  };

  const uploadToS3 = async (file) => {
    try {
      let max_size = false;
      if(file.type.includes("audio") && file.size > ((1024 * 1024) * 16)){
        max_size = true;
        Swal.fire({
          title: "¡Atención!",
          text: "El archivo supera el peso máximo permitido para audio: 16MB. Intente enviarlo como documento.",
          icon: "info",
        });
      }
      if(file.type.includes("video") && file.size > ((1024 * 1024) * 16)){
        max_size = true;
        Swal.fire({
          title: "¡Atención!",
          text: "El archivo supera el peso máximo permitido para video: 16MB. Intente enviarlo como documento.",
          icon: "info",
        });
      }
      if(file.type.includes("image") && file.size > ((1024 * 1024) * 5)){
        max_size = true;
        Swal.fire({
          title: "¡Atención!",
          text: "El archivo supera el peso máximo permitido para imagen: 5MB. Intente enviarlo como documento.",
          icon: "info",
        });
      }
      if(file.type.includes("document") && file.size > ((1024 * 1024) * 100)){
        max_size = true;
        Swal.fire({
          title: "¡Atención!",
          text: "El archivo supera el peso máximo permitido para documentos: 100MB. Intente enviarlo como documento.",
          icon: "info",
        });
      }

      if (max_size) {
        setSizeMax(true);
        setSelectedImage((selectedImage) => { return "" });
        setSelectedVideo((selectedVideo) => { return "" });
        setSelectedDocument((selectedDocument) => { return "" });
        return false;
      } else {
        setSizeMax(false);
      }
      setLoading(true);
      const signedUrl = await getSignedUrl(file.name, file.size);
      if(signedUrl){
        urlFile.current = await upload_s3(file, signedUrl);
        nameFile.current = file.name;
        sizeFile.current = file.size;
        console.log('urlFile.current::', urlFile.current);
        console.log('nameFile.current::', nameFile.current);
        console.log('sizeFile.current::', sizeFile.current);
        console.log('file.type::', file.type);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error uploading to s3...', error);
      return false;
    }
  };

  const [listOfAddedButtons, setListOfAddedButtons] = useState([])
  const [isButtonStopPromotions, setIsButtonStopPromotions] = useState(false)
  const [idCounter, setIdCounter] = useState(1);


  //#--> Agregamos botones a la lista
  const addButton = async (value) => {
    console.log('value::', value)
    console.log('listOfAddedButtons::', listOfAddedButtons)
    
    const alreadyAdded = listOfAddedButtons.filter(btn => btn.type === value).length;
    console.log('alreadyAdded::', alreadyAdded)
    
    if (value === 1 && alreadyAdded >= 1) {return;}
    if (value === 3 && alreadyAdded >= 2) {return;}
    if (value === 4 && alreadyAdded >= 1) {return;}

    setIsListButtons(false);
    if (value === 1) {
      setIsButtonStopPromotions(true)
      setTextFooter("")
    }
    let newButton = {
      id: idCounter,
      type: value,
      fields: initializeFields(value),
    };
    if (value === 3) {
      newButton.urlType = "static"
      newButton.exampleUrl = ""
    }
    setIdCounter(idCounter + 1);
    console.log('agregar newButton:', newButton);
    setListOfAddedButtons([...listOfAddedButtons, newButton]);
    console.log('agregar:', listOfAddedButtons);
  }
  
  //#--> Nos permite eliminar un botón agregado
  const deleteButton = (id, type) => {
    console.log('id:',id);
    console.log('type:',type);
    const index = listOfAddedButtons.findIndex(btn => btn.id === id);
    console.log('index:',index);
    if (index !== -1) {
      const newButtons = [...listOfAddedButtons];
      console.log('newButtons 1 :::::', newButtons);
      newButtons.splice(index, 1);
      console.log('newButtons 2 :::::', newButtons);
      setListOfAddedButtons(newButtons);
    }
    // const newButtons = [...listOfAddedButtons];
    // newButtons.splice(index, 1);
    // setListOfAddedButtons(newButtons);


    if (type === 1) {
      setIsButtonStopPromotions(false)
    }
    // if (type === 2) {
    //   reset({[`fastAnswerText${id}`]: ''})
    // }
    // if (type === 3) {
    //   reset({[`buttonTextActionGoToWebsite${id}`]: '', [`urlType${id}`]: '', [`websiteUrl${id}`]: '', [`sampleVariableUrl${id}`]: ''})
    // }
    // if (type === 4) {
    //   reset({[`buttonTextAction${id}`]: '', [`country${id}`]: '', [`phoneNumber${id}`]: ''})
    // }
  };

  //#--> Manejamos los cambios de los campos de cada botón
  const handleChangeField = (indexButton, indexField, value) => {
    const newButtons = [...listOfAddedButtons];
    newButtons[indexButton].fields[indexField] = value;
    setListOfAddedButtons(newButtons);
    console.log('newButtons onchange:', newButtons);
  };

  //#--> Inicializamos los campos de cada tipo de botón
  const initializeFields = (type) => {
    switch (type) {
      case 1:
        return ['Detener promociones','¿No te interesa? Toca "Detener promociones"'];
      case 2:
        return [''];
      case 3:
        return ['', '', ''];
      case 4:
        return ['', '', ''];
      default:
        return [];
    }
  };


  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}
      {mode === "createwsp" ? (
        <>
          <div className="row mx-auto container-fluid p-0 justify-content-between border-top-0 border-right-0 border-left-0 border-secondary mb-1">
            <div style={{ height: "45px", lineHeight: "95px" }}>
              <button
                type="button"
                className="btn btn-left col-1-sm-3 my-auto position-absolute"
                onClick={() => {
                  reset();
                  navigate("/home/templates");
                }}
              >
                <i
                  className="icon-left-open mr-0"
                  style={{ fontSize: "22px" }}
                />
                Plantillas
              </button>
            </div>
            <Header />
          </div>

          <section className="module-create-user created-template-1" style={{height:"90%"}}>
            <header className="message" style={{height:"9%"}}>
              <div id="tittleFormProduct">
                <h1>Crear plantilla para whatsapp</h1>
              </div>
            </header>

            <form className="Form-create-new-template"onSubmit={handleSubmit(onValidation)} style={{height:"91%"}}>
              <div id="section1-create-template" style={{height:"85%", overflow:"auto"}}>
                {/* CATEGORIA DE LA PLANTILLA */}
                <div className="border-bottom mb-1 px-4 pt-3 pb-4">
                  <h4>Categoria</h4>
                  <p>Elige una plantilla de mensaje.</p>

                  {categorys.length > 0 && categorys.map((categ, k) => (
                    <div key={k} className="pointer radio">
                      <input
                        type="radio"
                        name={categ.id}
                        id={categ.id}
                        className="pointer"
                        value={categ.id}
                        {...register("category", {
                          required: {
                            value: true,
                            message: "El tipo de plantilla es requerido.",
                          },
                        })}
                      />
                      <label htmlFor={categ.id} className="d-flex mb-0 pointer radio-label align-items-center" key={categ.id}>
                        {categ.id === 1 ? 
                          <IoMegaphoneSharp style={{ fontSize: "32px" }}/>
                        : categ.id === 2 ? 
                          <BsBellFill style={{ fontSize: "30px" }}/>
                        :
                          <FaKey style={{ fontSize: "30px" }}/>
                        } 

                        <div className="ml-2">
                          <label htmlFor={categ.id} className="form-check-label f-18 f-w-400">
                            {categ.name}
                          </label>
                          <p className="font-weight-normal mb-0">
                            {categ.description}
                          </p>
                        </div>
                      </label>
                    </div>
                  ))}
                  {errors.category && <Alert>* {errors.category.message}</Alert>}
                  {/* {errorCategory ? (<Alert>* El tipo de plantilla es requerido.</Alert>) : null} */}
                </div>

                {/* NOMBRE DE LA PLANTILLA */}
                <div className="border-bottom mb-1 px-4 pt-3 pb-4">
                  <h4>Nombre</h4>
                  <p>Asigna un nombre a la plantilla de mensaje.</p>
                  <div className="mt-0 su-control su-segmentation-disable w-100">
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      id="name"
                      maxLength="512"
                      placeholder="512 caracteres max"
                      {...register("name", {
                        required: {
                          value: true,
                          message: "El nombre es requerido.",
                        },
                      })}
                      // onChange={(e) => {const vlr = e.target.value; console.log(vlr.replace(/\s+/g,"_"))}}
                      onKeyUp={onKeyUp}
                    />
                  </div>
                  {errors.name && <Alert>* {errors.name.message}</Alert>}
                  {/* {data ? <Alert>* El nombre no debe contener espacios, separa las palabras con guión bajo ("Plantilla_nueva").</Alert> : null} */}
                </div>

                {/* IDIOMAS PARA LA PLANTILLA */}
                <div className="mb-1 px-4 pt-3 pb-4 lenguages">
                  <h4>Idiomas</h4>
                  <p>
                    Elige el idioma para la plantilla de mensaje. Puedes editar el idioma más adelante.
                  </p>

                  <div className="mt-4">
                    <Select
                      styles={isDark == 'true' ? customStylesDark : customStyles}
                      className="form-control select-timezone"
                      placeholder="Seleciona un idioma..."
                      value={lenguageSelected}
                      onChange={handleChangeLanguages}
                      options={lenguagesList}
                      menuPlacement="top"
                      getOptionLabel={(option) => (
                        <div className="option d-flex" style={{fontSize: "15px"}}>
                          {option.description} 
                        </div>
                      )}
                      getOptionValue={(option) => option.description}
                    />
                    {errorLenguages && (<Alert>* El idioma es requerido</Alert>)} 
                  </div>
                </div>
              </div>

              <footer style={{height:"15%"}}>
                <div className="row">
                  <div className="col-sm-6"></div>
                  <div className="col-sm-6 right">
                    <a
                      href="javascript:;"
                      className="btn btn-lg btn-default mr-1"
                      onClick={() => {
                        reset();
                        navigate("/home/templates");
                      }}
                    >
                      Cancelar
                    </a>
                    <input
                      className="btn btn-lg btn-info"
                      value="Continuar"
                      type="submit"
                    />
                  </div>
                </div>
              </footer>
            </form>
          </section>
        </>
      ) : null}

      {mode === "createwsp-2" ? (
        <>
          <div className="row mx-auto container-fluid p-0 justify-content-between border-top-0 border-right-0 border-left-0 border-secondary mb-1">
            <div style={{ height: "45px", lineHeight: "95px" }}>
              <button
                id="dos"
                onClick={() => {
                  reset();
                  navigate("/home/templates");
                }}
                type="button"
                className="btn btn-left col-1-sm-3 my-auto position-absolute"
              >
                <i
                  className="icon-left-open mr-0"
                  style={{ fontSize: "22px" }}
                />
                Plantillas
              </button>
            </div>

            <Header />
          </div>

          <section className="module-create-user" style={{ width: "100%", height:"90%" }}>
            <header className="message" style={{height:"9%"}}>
              <div id="tittleFormProduct" className="d-inline-flex">
                <div className="su-with-prefix su-with-suffix su-segmentation-disable d-flex mt-0 w-50">
                  <div className="su-control-group">
                    <input
                      className="form-control font-weight-bold"
                      type="text"
                      name="name"
                      id="name"
                      style={{ background: "transparent" }}
                      {...register("name", {
                        required: {
                          value: true,
                          message: "El nombre es requerido.",
                        },
                      })}
                      onKeyUp={onKeyUp}
                    />
                  </div>
                </div>

                <div className="su-with-prefix su-with-suffix su-segmentation-disable d-flex mt-0 w-50">
                  <label htmlFor="select">Categoría: </label>
                  <div className="position-relative">
                    <input
                      className="form-control pl-2"
                      type="text"
                      name="category"
                      id="category"
                      readOnly
                      value={
                        categoryTemplate === "1" ? "Marketing"
                        : categoryTemplate === "2" ? "Utilidad"
                        : categoryTemplate === "3" ? "Autenticación"
                        : null
                      }
                      onClick={() => {
                        viewCategory
                          ? setViewCategory(false)
                          : setViewCategory(true);
                      }}
                    />
                    {viewCategory ? (
                      <div
                        className="category-list position-absolute"
                        style={{ width: "265px" }}
                      >
                        {categorys.length > 0 && categorys.map((categ, k) => (
                          <div key={k} className="item-list">
                            <input
                              type="radio"
                              name={categ.id}
                              id={categ.id}
                              className="form-check-input mt-3"
                              value={categ.id}
                              {...register("category", {
                                required: {
                                  value: true,
                                  message:
                                    "El tipo de plantilla es requerido.",
                                },
                              })}
                              onChange={(e) =>
                                setCategoryTemplate(e.target.value)
                              }
                            />
                            <label
                              htmlFor={categ.id}
                              className="form-check d-flex mb-0"
                              key={categ.id}
                              style={{ padding: "5px 2px 5px 1.25rem" }}
                            >
                              <div>
                                <label
                                  htmlFor={categ.id}
                                  className="form-check-label pl-3 f-w-400"
                                >
                                  {categ.name}
                                </label>
                              </div>
                            </label>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </header>

            <form className="Form-create-new-template" onSubmit={handleSubmit(onSaveAll)} style={{height:"91%"}}>
              <div className="d-flex" style={{height:"87%"}}>
                {/**************** COLUMNA 1 ****************/}
                <div className="colum-1 mr-0">
                  <div className="form-check p-3">
                    <label htmlFor="select">Idioma: </label>

                    <div className="mt-3"
                      style={{
                        background: "#fff",
                        width: "112%",
                        marginLeft: "-14px",
                        paddingRight: "0",
                        paddingLeft: "0",
                      }}
                    >
                      <Select
                        styles={isDark == 'true' ? customStylesDark : customStyles}
                        className="form-control select-timezone"
                        placeholder="Seleciona un país"
                        value={lenguageSelected}
                        onChange={handleChangeLanguages}
                        options={lenguagesList}
                        // getOptionLabel={(option) => option.name}
                        getOptionLabel={(option) => (
                          <div className="option d-flex" style={{fontSize: "15px"}}>
                            {option.description} 
                          </div>
                        )}
                        getOptionValue={(option) => option.description}
                      />
                      {/* <ul className="o-auto d-flex flex-column justify-content-center align-items-center">
                        {lenguagesList && lenguagesList.map((list, k) => (
                            <li key={k} className="row w-90 align-items-center">
                              <input
                                name={list.description}
                                id={list.description}
                                type="checkbox"
                                value={list.id}
                                className="cmn-toggle cmn-toggle-round-flat"
                                {...register("languages", {
                                  required: {
                                    value: true,
                                    message: "Obligatoria",
                                  },
                                })}
                              />
                              <label
                                onClick={() => onListLenguages()}
                                className="ml-0"
                                htmlFor={list.description}
                              ></label>
                              <span className="col-8">{list.description}</span>
                            </li>
                          ))}
                      </ul> */}
                    </div>
                  </div>
                </div>

                {/**************** COLUMNA 2 ****************/}
                <div className="colum-2 h-100">
                  <form className="Form-create-new-template h-100" style={{overflow:"auto"}}>
                    <div className="border-bottom mb-1 px-4 pt-3 pb-4">
                      <div>
                        <div id="tittleFormProduct">
                          <h3>{`Contenido del mensaje en ${lenguageSelected.description}`}</h3>
                        </div>
                      </div>
                    </div>

                    {categoryTemplate === "3" ? <>
                      {/* <div className="border-bottom mb-1 px-4 pt-3 pb-4">
                        <div className="d-flex">
                          <h4>Entrega del código</h4>
                        </div>
                        <p>Elige cómo enviarán los clientes el código de WhatsApp a tu app. Obtén información sobre cómo enviar <a href="https://developers.facebook.com/docs/whatsapp/business-management-api/authentication-templates/" target="_blank" rel="noreferrer">plantillas de mensajes de autenticación.</a></p>
                        <div className="form-check mx-0 pl-0">
                          <div className="d-flex">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mr-4">
                              {opt_aut.length > 0 && opt_aut.map((categ, k) => (
                                <div key={k}>
                                  <input
                                    type="radio"
                                    name={categ.id}
                                    id={categ.id}
                                    className="form-check-input mt-3"
                                    value={categ.id}
                                    {...register("category", {
                                      required: {
                                        value: true,
                                        message: "El tipo de plantilla es requerido.",
                                      },
                                    })}
                                  />
                                  <label htmlFor={categ.id} className="form-check d-flex align-items-center" key={categ.id}>
                                    <div className="ml-2">
                                      <label htmlFor={categ.id} className="form-check-label f-18 f-w-400">
                                        {categ.name}
                                      </label>
                                      <p className="font-weight-normal">
                                        {categ.description}
                                      </p>
                                    </div>
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="border-bottom mb-1 px-4 pt-3 pb-4">
                        <div className="d-flex">
                          <h4>Contenido del mensaje</h4>
                        </div>
                        <p>El contenido de las plantillas de mensajes de autenticación no se puede editar. Puedes agregar contenido adicional de las siguientes opciones.</p>
                        <div className="mt-0 su-control su-segmentation-disable w-100">
                          <ul>
                            <li className="m-t-5 p-3 row">
                              <input
                                type="checkbox"
                                value="segurity"
                                className="cmn-toggle cmn-toggle-round-flat"
                                name=""
                                id="segurity"
                                defaultChecked="true"
                                // checked={tag.tagActive}
                                // {...register("tags")}
                              />
                              <label className="" htmlFor="segurity"></label>
                              <div className="col-8 d-flex align-items-center icon-avatar">
                                <label className="">Agregar recomendación de seguridad</label>
                              </div>
                            </li>
                            <li className="m-t-5 p-3 row">
                              <input
                                type="checkbox"
                                value="date"
                                className="cmn-toggle cmn-toggle-round-flat"
                                name=""
                                id="date"
                                defaultChecked="false"
                                // checked={tag.tagActive}
                                // {...register("tags")}
                              />
                              <label className="" htmlFor="date"></label>
                              <div className="col-8 d-flex align-items-center icon-avatar">
                                <label className="">Agrega la fecha de caducidad para el código</label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="border-bottom mb-1 px-4 pt-3 pb-4">
                        <div className="d-flex">
                          <h4>Texto del botón</h4>
                        </div>
                        <p>Puedes personalizar el texto del botón para las opciones de autocompletar y de copiar código. Aunque la opción sin toque esté activada, los botones se necesitan para el método de entrega del código de respaldo.</p>
                        <div className="mt-0 su-control su-segmentation-disable w-75">
                          <input
                            className="form-control"
                            type="text"
                            name="footer"
                            maxLength="25"
                            placeholder="25 caracteres max"
                            {...register("txtButton")}
                            // onChange={(e) => setTextFooter(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="mb-1 px-4 pt-3 pb-4">
                        <div className="d-flex">
                          <h4>Configuración avanzada</h4>
                        </div>
                        <h5>Período de validez del mensaje</h5>
                        <p>Recomendamos establecer un período de validez personalizado en el que se debe entregar tu mensaje de autenticación antes de que caduque. Si un mensaje no se entrega dentro de ese plazo, no se te cobrará y tu cliente no verá el mensaje.</p>
                        <div className="mt-0 su-control su-segmentation-disable w-100">
                          <ul>
                            <li className="m-t-5 p-3 row">
                              <input
                                type="checkbox"
                                value="segurity"
                                className="cmn-toggle cmn-toggle-round-flat"
                                name=""
                                id="segurity"
                                defaultChecked="true"
                                // checked={tag.tagActive}
                                // {...register("tags")}
                              />
                              <label className="" htmlFor="segurity"></label>
                              <div className="col-8 d-flex align-items-center icon-avatar">
                                <label className="d-block">Establecer período de validez personalizado para tu mensaje de autenticación</label>
                                <span className="">Si no estableces un período de validez personalizado, se aplicará el período de validez del mensaje de WhatsApp estándar de 30 días.</span>
                              </div>
                            </li>
                          </ul>
                          <div className="mb-1 px-4 pt-3 pb-4">
                            <div className="d-flex">
                            <h5>Período de validez</h5>
                            </div>
                            <div className="form-check mx-0 pl-0">
                              <div className="d-flex">
                                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mr-4">
                                  <select
                                    name="headerType"
                                    id="headerType"
                                    className="form-control"
                                    {...register("headerType")}
                                    onChange={onHeaderType}
                                  >
                                    <option value="1">1 minuto</option>
                                    <option value="2">2 minutos</option>
                                    <option value="3">3 minutos</option>
                                    <option value="5">5 minutos</option>
                                    <option value="10">10 minutos</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                    </> : <>
                      <div className="border-bottom mb-1 px-4 pt-3 pb-4">
                        <div className="d-flex">
                          <h4>Encabezado</h4>
                          <span className="optional ml-3"> Opcional</span>
                        </div>
                        <p>Agregue un título o elija qué tipo de medio usará para este encabezado.</p>
                        <div className="form-check mx-0 pl-0">
                          <div className="d-flex">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mr-4">
                              <select name="headerType" id="headerType" className="form-control"
                                {...register("headerType")}
                                onChange={onHeaderType}
                              >
                                <option value="" key="0" selected>Ninguna</option>
                                <option value="headerText" key="2">Mensaje de texto</option>
                                <option value="media" key="3">Medios</option>
                              </select>
                            </div>
                            {headerType === "headerText" ? (
                              <div style={{ marginTop: "27px", width: "77%" }}>
                                <div className="su-control-group">
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="headerText"
                                    id="headerText"
                                    maxLength="60"
                                    placeholder="60 caracteres max"
                                    value={textHeader}
                                    {...register("headerText", {
                                      required: {
                                        value: true,
                                        message: "El texto es requerido.",
                                      },
                                    })}
                                    onChange={(e) => {
                                      setTextHeader(e.target.value)
                                      setNotVariableHeader(notVariableHeader &&  false);
                                      if (e.target.value.includes("{{1}}")) {
                                        console.log("El texto contiene '{{1}}'.");
                                        setIsIncludeVariable(true);
                                      } else {
                                        setIsIncludeVariable(false)
                                      }
                                    }}
                                  />
                                    <div className="ml-1 mt-1">
                                      <span className="add-variable" style={{ cursor: "pointer" }} onClick={onAddVariableHeader}>
                                        + Agregar variable
                                      </span>
                                    </div>
                                    {errors.headerText && (<Alert>* {errors.headerText.message}</Alert>)}
                                    {notVariableHeader && (<Alert>* Tu título no puede incluir más de una variable</Alert>)}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        {headerType === "none" ? null : headerType === "media" ? (
                          <>
                            <div className="type-medios">
                              <div className="content-type-medios">
                                <div className="t-image">
                                  <input
                                    type="radio"
                                    name="media"
                                    id="1"
                                    className="form-check-input mt-3"
                                    value="1"
                                    {...register("media", {
                                      required: {
                                        value: true,
                                        message: "Debes elegir un medio.",
                                      },
                                    })}
                                    onClick={() => {
                                      setSelectedMedia(1)
                                      const inputFile = document.getElementById('input-file');
                                      // console.log('inputFile', inputFile);
                                      if (inputFile) {
                                        inputFile.value = '';
                                      }
                                    }}
                                  />
                                  <label
                                    htmlFor="1"
                                    className="form-check d-flex"
                                  >
                                    <div className="cont-medio">
                                      <div className="cont-all">
                                        <div></div>
                                        <span>Imagen</span>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                                <div className="t-video">
                                  <input
                                    type="radio"
                                    name="media"
                                    id="2"
                                    className="form-check-input mt-3"
                                    value="2"
                                    {...register("media", {
                                      required: {
                                        value: true,
                                        message: "Debes elegir un medio.",
                                      },
                                    })}
                                    onClick={() => {
                                      setSelectedMedia(2)
                                      const inputFile = document.getElementById('input-file');
                                      // console.log('inputFile', inputFile);
                                      if (inputFile) {
                                        inputFile.value = '';
                                      }
                                    }}
                                  />
                                  <label
                                    htmlFor="2"
                                    className="form-check d-flex"
                                  >
                                    <div className="cont-medio">
                                      <div className="cont-all">
                                        <div></div>
                                        <span>video</span>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                                <div className="t-document">
                                  <input
                                    type="radio"
                                    name="media"
                                    id="3"
                                    className="form-check-input mt-3"
                                    value="3"
                                    {...register("media", {
                                      required: {
                                        value: true,
                                        message: "Debes elegir un medio.",
                                      },
                                    })}
                                    onClick={() => {
                                      setSelectedMedia(3)
                                      const inputFile = document.getElementById('input-file');
                                      // console.log('inputFile', inputFile);
                                      if (inputFile) {
                                        inputFile.value = '';
                                      }
                                    }}
                                  />
                                  <label
                                    htmlFor="3"
                                    className="form-check d-flex"
                                  >
                                    <div className="cont-medio">
                                      <div className="cont-all">
                                        <div></div>
                                        <span>Documento</span>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                                <div className="t-location">
                                  <input
                                    type="radio"
                                    name="media"
                                    id="4"
                                    className="form-check-input mt-3"
                                    value="4"
                                    {...register("media", {
                                      required: {
                                        value: true,
                                        message: "Debes elegir un medio.",
                                      },
                                    })}
                                    onClick={() => {
                                      setSelectedMedia(4)
                                      const inputFile = document.getElementById('input-file');
                                      // console.log('inputFile', inputFile);
                                      if (inputFile) {
                                        inputFile.value = '';
                                      }
                                    }}
                                  />
                                  <label
                                    htmlFor="4"
                                    className="form-check d-flex"
                                  >
                                    <div className="cont-medio">
                                      <div className="cont-all">
                                        <div></div>
                                        <span>Ubicación</span>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                            {errors.media && (<Alert>* {errors.media.message}</Alert>)}
                          </>
                        ) : null}

                        {isIncludeVariable || (headerType === "media" && (selectedMedia === 1 || selectedMedia === 2 || selectedMedia === 3)) ? 
                          <div className="ml-5">
                            <h4 className="mt-3">Ejemplos de contenido del encabezado</h4>
                            <p>Para ayudarnos a revisar tu contenido, proporciona ejemplos de las variables o del contenido multimedia en el encabezado. No incluyas información del cliente. La API de la nube alojada por Meta revisa las plantillas y los parámetros de las variables para proteger la seguridad e integridad de nuestros servicios.</p>
                            <div className="su-control-group d-flex align-items-center">
                              {headerType === "media" ? <>
                                <span>{selectedMedia === 1 ? "Imagen" : selectedMedia === 2 ? "Video" : selectedMedia === 3 ? "Documento" : null }</span>
                                <input
                                  className="form-control ml-1"
                                  id="input-file"
                                  type="file"
                                  name="sampleMedia"
                                  accept={selectedMedia === 1 ? "image/gif,image/jpeg,image/jpg,image/png" : selectedMedia === 2 ? "video/*" : selectedMedia === 3 ? ".pdf" : null }
                                  {...register("sampleMedia", {
                                    required: {
                                      value: true,
                                      message: "Agregar contenido multimedia de muestra.",
                                    },
                                  })}
                                  onChange={(e) => onFile(e)}
                                />
                              </> : <>
                                <span>{'{{1}}'}</span>
                                <input
                                  className="form-control ml-1"
                                  type="text"
                                  name="sampleVariableHeader"
                                  maxLength="60"
                                  {...register("sampleVariableHeader", {
                                    required: {
                                      value: true,
                                      message: "Agregar texto de muestra.",
                                    },
                                  })}
                                  />
                              </>}
                            </div>
                            {errors.sampleVariableHeader && (<Alert>* {errors.sampleVariableHeader.message}</Alert>)}
                            {errors.sampleMedia && (<Alert>* {errors.sampleMedia.message}</Alert>)}

                          </div>
                        : null}
                      </div>

                      <div className="border-bottom mb-1 px-4 pt-3 pb-4">
                        <div className="d-flex">
                          <h4>Texto</h4>
                        </div>
                        <p>Introduzca el texto de su mensaje en el idioma que haya seleccionado.</p>
                        <div className="mt-0 su-control su-segmentation-disable w-100">
                          <textarea
                            name="body"
                            id="body"
                            className="form-control"
                            maxLength="1024"
                            placeholder="1024 caracteres max"
                            value={inputStr}
                            {...register("body", {
                              required: {
                                value: true,
                                message: "El texto es requerido.",
                              },
                            })}
                            onChange={(e) => {
                              setInputStr(e.target.value);
                              if (e.target.value.includes("{{1}}")) {
                                // console.log("El texto contiene '{{1}}'.");
                                setIsIncludeVariableBody(true);
                                
                                const newText = e.target.value;
                                
                                const regex = /\{\{(\d+)\}\}/g;
                                const matches = [...newText.matchAll(regex)];
                                // const extractedVariables = matches.map((match) => match[0]);
                                const extractedVariables = matches.map((match, index) => `{{${index + 1}}}`); 
                                // console.log("extractedVariables::", extractedVariables);
                                setCountArray(extractedVariables)

                              } else {
                                setIsIncludeVariableBody(false)
                                setCount(1)
                                setCountArray([])
                              }
                            }}
                            style={{ height: "135px" }}
                          ></textarea>
                        </div>
                        {warningMessages && (
                          <div style={{ backgroundColor: "rgba(255,241,204,1)" }}>
                            <ul>
                              <li style={{ padding: "10px" }}>
                                <i
                                  className="icon-attention"
                                  style={{
                                    fontSize: "19px",
                                    color: "rgba(255,186,0,1)",
                                  }}
                                />{" "}
                                El texto no puede contener parámetros variables al
                                principio o al final.
                              </li>
                            </ul>
                          </div>
                        )}
                        {errors.body && <Alert>* {errors.body.message}</Alert>}
                        {notVariable && (<Alert>* No puedes agregar mas variables</Alert>)}

                        <div className="d-inline-block">
                          <div className="picker-container">
                            <i
                              className={
                                showPicker
                                  ? "icon-smile emoji-icon active"
                                  : "icon-smile emoji-icon"
                              }
                              style={{ fontSize: "22px" }}
                              onClick={() => setShowPicker((val) => !val)}
                            />

                            {showPicker && (
                              <Picker
                                pickerStyle={{
                                  width: "100%",
                                  left: "35px",
                                  position: "absolute",
                                  zIndex: "999",
                                }}
                                onEmojiClick={onEmojiClick}
                                groupNames={{
                                  smileys_people: "Emoticones y personas",
                                  animals_nature: "Animales y naturaleza",
                                  food_drink: "Alimentos y bebidas",
                                  travel_places: "Viajes y lugares",
                                  activities: "Actividades",
                                  objects: "Objetos",
                                  symbols: "Símbolos",
                                  flags: "Banderas",
                                  recently_used: "Recientes",
                                }}
                              />
                            )}
                          </div>

                          <div className="ml-5 mt-1">
                            <div>
                              <span
                                className="add-variable"
                                style={{ cursor: "pointer" }}
                                onClick={onAddVariable}
                              >
                                + Agregar variable
                              </span>
                            </div>
                          </div>
                        </div>

                        {isIncludeVariableBody && 
                          <div className="ml-5">
                            <h4 className="mt-3">Ejemplos de contenido del cuerpo</h4>
                            <p>Agrega un ejemplo en cada variable del texto del cuerpo para que podamos revisar tu plantilla de mensaje. No uses información verdadera de clientes. La API de la nube, alojada por Meta, revisa las plantillas y los parámetros de las variables para garantizar la seguridad e integridad de nuestros servicios</p>
                            {countArray.map((item, n) => (
                              <div key={n} className="su-control-group d-flex align-items-center mb-2">
                                <span>{item}</span>
                                <input
                                  className="form-control ml-1"
                                  type="text"
                                  name={`sampleVariableBody${n+1}`}
                                  maxLength="60"
                                  {...register(`sampleVariableBody${n+1}`, {
                                    required: {
                                      value: true,
                                      message: "Agregar texto de muestra.",
                                    },
                                  })}
                                />
                              </div>
                            ))}
                            {errors.sampleVariableBody && (<Alert>* {errors.sampleVariableBody.message}</Alert>)}

                          </div>
                        }

                      </div>

                      <div className="mb-1 px-4 pt-3 pb-4">
                        <div className="d-flex">
                          <h4>Pie de pagina</h4>
                          <span className="optional ml-3"> Opcional</span>
                        </div>
                        <p>Agrega una breve línea de texto en la parte inferior de la plantilla de mensaje. Si agregas el botón para no recibir mensajes de marketing, el pie del mensaje asociado se mostrará aquí de forma predeterminada.</p>
                        <div className="mt-0 su-control su-segmentation-disable w-75">
                          <input
                            className="form-control"
                            type="text"
                            name="footer"
                            maxLength="60"
                            placeholder="60 caracteres max"
                            value={isButtonStopPromotions ? '¿No te interesa? Toca "Detener promociones"' : textFooter}
                            disabled={isButtonStopPromotions ?? false}
                            {...register("footer")}
                            onChange={(e) => setTextFooter(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="mb-1 px-4 pt-3 pb-4">
                        <div className="d-flex">
                          <h4>Botones</h4>
                          <span className="optional ml-3"> Opcional</span>
                        </div>
                        <p>Cree botones que permitan a los clientes responder a su mensaje o realizar alguna acción.</p>
                        {categoryTemplate === "1"  && 
                          <div className="content-recomnendation">
                            <HiOutlineLightBulb className="mr-1" style={{fontSize:"16px", color:"#5bc0de"}}/>
                            <label>{'Te recomendamos agregar el botón "Desactivar marketing"'}</label>
                            <p>Permite que los clientes soliciten desactivar todos los mensajes de marketing. Esto puede ayudar a reducir los bloqueos de los clientes y aumentar tu calificación de calidad. <a className="hover-a" href="https://web.facebook.com/business/help/448422200528701?_rdc=1&_rdr" target="_blank" rel="noreferrer">Más información.</a></p>
                          </div>
                        }
                        <div>
                          <div className="form-check mx-0 pl-0 d-flex">
                            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                              <input 
                                // className="pointer btn btn-lg btn-default mr-1" 
                                className="btn btn-lg btn-info" 
                                style={{lineHeight:"0.9", fontSize:"1.1em"}}
                                type="button" 
                                value="+ Agregar un botón" 
                                disabled={listOfAddedButtons.length < 10 ? false : true}
                                onClick={() => {setIsListButtons(true)}}
                              />
                              {listOfAddedButtons.length === 10 && <label className="alert warning d-block mt-1" style={{fontSize:"0.9rem"}}>Se alcanzó el límite de botones, para agregar un tipo de botón distinto, primero elimina un botón.</label>}
                              {isListButtons &&
                                <div className="content-answers p-0" style={{ position: "absolute", bottom: "2.5rem", backgroundColor: "#fff", zIndex: "9999", width:"21rem" }}>
                                  <IoClose className="icon-cancel pointer position-absolute" style={{fontSize: "1.4rem", right:"0"}} onClick={() => setIsListButtons(false)}/>
                                  <div className="px-3 list-quickly-answers">
                                    <ul>
                                      <li className="py-2 px-1" style={{fontSize: "0.9em"}}>
                                        <div style={{display: "grid"}}>
                                          <strong className="mb-2" style={{fontSize:"1rem"}}>Botones de respuesta rápidas</strong>
                                          <ul className="list-opt-buttons ml-2">
                                            <li 
                                              className="pointer mb-3" 
                                              onClick={()=>{addButton(1)}} 
                                              disabled={listOfAddedButtons.some(btn => btn.type == 1)}
                                            >
                                              Desactivar Marketing
                                              <span style={{fontSize:"0.8rem"}} className="d-block">recomendado</span>
                                            </li>
                                            <li 
                                              className="pointer"  
                                              onClick={()=>{addButton(2)}}
                                            >
                                              Personalizado
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li className="py-2 px-1" style={{fontSize: "0.9em"}}>
                                        <div style={{display: "grid"}}>
                                          <strong className="mb-2" style={{fontSize:"1rem"}}>Botones de llamada a la acción</strong>
                                          <ul className="list-opt-buttons ml-2">
                                            <li 
                                              className="pointer mb-3" 
                                              onClick={()=>{addButton(3)}} 
                                              disabled={listOfAddedButtons.filter(btn => btn.type == 3).length >= 2}
                                            >
                                              Ir al sitio web
                                              <span style={{fontSize:"0.8rem"}} className="d-block">2 botones como máximo</span>
                                            </li>
                                            <li 
                                              className="pointer" 
                                              onClick={()=>{addButton(4)}}
                                              disabled={listOfAddedButtons.some(btn => btn.type == 4)}
                                            >
                                              Llamar al número de teléfono
                                              <span style={{fontSize:"0.8rem"}} className="d-block">1 botón como máximo</span>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                          <div className="mt-3">
                            <div>
                                {listOfAddedButtons.map((btn, i) => (
                                // {sortedButtons.map((btn, i) => (
                                  <div key={`${btn.id}-${i}`}>
                                    <div className="d-flex align-items-end">
                                      {btn.type === 1 && (<>
                                        <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mr-2 w-75">
                                            <label>Texto del botón:{" "}</label>
                                            <div className="su-control-group">
                                              <input
                                                className="form-control mt-2"
                                                type="text"
                                                name="stopPromotionsText"
                                                defaultValue="Detener promociones"
                                                disabled
                                                {...register("stopPromotionsText")}
                                                onChange={(e) => handleChangeField(i, 0, "Detener promociones")}
                                              />
                                            </div>
                                        </div>
                                        <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mr-2 w-75">
                                          <label>Texto de pie de página:{" "}</label>
                                          <div className="su-control-group">
                                            <input
                                              className="form-control mt-2"
                                              type="text"
                                              name="stopPromotionsText"
                                              defaultValue="¿No te interesa? Toca &quot;Detener promociones&quot;"
                                              disabled
                                              {...register("stopPromotionsText")}
                                              onChange={(e) => handleChangeField(i, 1, "¿No te interesa? Toca &quot;Detener promociones&quot;")}
                                            />
                                          </div>
                                        </div>
                                      </>)}

                                      {btn.type === 2 && (<>
                                        <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mr-2 w-75">
                                          <label>Texto del botón:{" "}</label>
                                          <div className="su-control-group">
                                            <input
                                              className="form-control mt-2"
                                              type="text"
                                              name={`fastAnswerText${btn.id}`}
                                              maxLength="25"
                                              placeholder="25 caracteres max"
                                              {...register(`fastAnswerText${btn.id}`, {
                                                required: {
                                                  value: true,
                                                  message:
                                                    "Texto del botón requerido.",
                                                },
                                              })}
                                              onChange={(e) => handleChangeField(i, 0, e.target.value)}
                                            />
                                            {errors[`fastAnswerText${btn.id}`] && (<Alert>* {errors[`fastAnswerText${btn.id}`].message}</Alert>)}
                                          </div>
                                        </div>

                                      </>)}

                                      {btn.type === 3 && (<>
                                        <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mr-2 w-50">
                                          <label>Texto del botón:{" "}</label>
                                          <div className="su-control-group">
                                            <input
                                              className="form-control mt-2"
                                              type="text"
                                              name={`buttonTextActionGoToWebsite${btn.id}`}
                                              maxLength="25"
                                              placeholder="25 caracteres max"
                                              {...register(`buttonTextActionGoToWebsite${btn.id}`,
                                                {
                                                  required: {
                                                    value: true,
                                                    message: "Texto del botón requerido.",
                                                  },
                                                }
                                              )}
                                              onChange={(e) => handleChangeField(i, 0, e.target.value)}
                                            />
                                            {errors[`buttonTextActionGoToWebsite${btn.id}`] && (<Alert>* {errors[`buttonTextActionGoToWebsite${btn.id}`].message}</Alert>)}
                                          </div>
                                        </div>

                                        <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mr-2 url-type">
                                          <label>Tipo de URL:{" "}</label>
                                          <select
                                            name={`urlType${btn.id}`}
                                            className="form-control"
                                            {...register(`urlType${btn.id}`, {
                                              required: {
                                                value: true,
                                                message: "Tipo requerido.",
                                              },
                                            })}
                                            onChange={(e) => {
                                              handleChangeField(i, 1, e.target.value)
                                              setListOfAddedButtons(prevBtns => prevBtns.map((prevBtn, b) =>
                                                b === i ? { ...prevBtn, urlType: e.target.value } : prevBtn
                                              ));
                                            }}
                                          >
                                            <option value="" key="0" disabled selected>Selecciona...</option>
                                            <option value="static" key="1">Statica</option>
                                            <option value="dynamic" key="2">Dinamica</option>
                                          </select>
                                          {errors[`urlType${btn.id}`] && (<Alert>* {errors[`urlType${btn.id}`].message}</Alert>)}
                                        </div>

                                        <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mr-1">
                                          <label>URL del sitio web:{" "}</label>
                                          <div className={urlType === "dynamic" ? "su-control-group dynamic" : "su-control-group"}>
                                            <div className="d-flex">
                                              <input
                                                className="form-control mt-2"
                                                type="text"
                                                name={`websiteUrl${btn.id}`}
                                                maxLength="2000"
                                                placeholder="2000 caracteres max"
                                                {...register(`websiteUrl${btn.id}`, {
                                                  required: {
                                                    value: true,
                                                    message: "URL del sitio web requerida.",
                                                  },
                                                  pattern: {
                                                    value: /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,6}\.?)(\/[\w.-]*)*\/?$/,
                                                    message: 'Ingresa una URL válida.',
                                                  }
                                                })}
                                                onChange={(e) => handleChangeField(i, 2, e.target.value)}
                                              />
                                            </div>
                                            {errors[`websiteUrl${btn.id}`] && (<Alert>* {errors[`websiteUrl${btn.id}`].message}</Alert>)}
                                          </div>
                                        </div>
                                        {btn.urlType === "dynamic" && (<p className="d-flex align-items-end">{param}</p>)}

                                      </>)}

                                      {btn.type === 4 && (<>
                                        <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mr-2 w-50">
                                          <label>Texto del botón:{" "}</label>
                                          <div className="su-control-group">
                                            <input
                                              className="form-control mt-2"
                                              type="text"
                                              name={`buttonTextAction${btn.id}`}
                                              maxLength="25"
                                              placeholder="25 caracteres max"
                                              {...register(`buttonTextAction${btn.id}`, {
                                                required: {
                                                  value: true,
                                                  message: "Texto del botón requerido.",
                                                },
                                              })}
                                              onChange={(e) => handleChangeField(i, 0, e.target.value)}
                                            />
                                            {errors[`buttonTextAction${btn.id}`] && (<Alert>* {errors[`buttonTextAction${btn.id}`].message}</Alert>)}
                                          </div>
                                        </div>

                                        <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mr-2 phone-country">
                                          <label>país: </label>
                                          <select
                                            name={`country${btn.id}`}
                                            className="form-control"
                                            {...register(`country${btn.id}`, {
                                              required: {
                                                value: true,
                                                message: "País requerido.",
                                              },
                                            })}
                                            onChange={(e) => handleChangeField(i, 1, e.target.value)}
                                          >
                                            <option value="" key="0" disabled selected>Selecciona...</option>
                                            <option value="57" key="1">CO +57</option>
                                            <option value="59" key="2">EC +59</option>
                                            <option value="52" key="2">MX +52</option>
                                          </select>
                                          {errors[`country${btn.id}`] && (<Alert>* {errors[`country${btn.id}`].message}</Alert>)}
                                        </div>

                                        <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mr-2">
                                          <label>Número de teléfono:{" "}</label>
                                          <div className="su-control-group">
                                            <input
                                              className="form-control mt-2"
                                              type="text"
                                              name={`phoneNumber${btn.id}`}
                                              maxLength="20"
                                              placeholder="20 caracteres max"
                                              {...register(`phoneNumber${btn.id}`, {
                                                required: {
                                                  value: true,
                                                  message:
                                                    "Número de teléfono requerido.",
                                                },
                                              })}
                                              onChange={(e) => handleChangeField(i, 2, e.target.value)}
                                            />
                                            {errors[`phoneNumber${btn.id}`] && (<Alert>* {errors[`phoneNumber${btn.id}`].message}</Alert>)}
                                          </div>
                                        </div>
                                      </>)}

                                      {/* Opción para eliminar un botón */}
                                      <div style={{alignItems: "center", display: "flex"}}>
                                        <Botton type="button" onClick={() => deleteButton(btn.id, btn.type)}>
                                          <IoClose className="icon-cancel" style={{ fontSize: "35px" }}/>
                                        </Botton>
                                      </div>

                                    </div>

                                    {btn.urlType === "dynamic" &&
                                      <div className="ml-5">
                                        <h4 className="mt-3">Agregar URL de muestra</h4>
                                        <p>Para ayudarnos a revisar tu plantilla, agrega un ejemplo de la URL del sitio web. No uses información real del cliente.</p>
                                        <div className="su-control-group d-flex align-items-center">
                                          <span>{'{{1}}'}</span>
                                          <input
                                            className="form-control ml-1"
                                            type="text"
                                            name={`sampleVariableUrl${btn.id}`}
                                            maxLength="60"
                                            placeholder={`Ingresa la URL completa de ${btn.fields[2]}{{1}}`}
                                            {...register(`sampleVariableUrl${btn.id}`, {
                                              required: {
                                                value: true,
                                                message: "Ingresa una URL del sitio web.",
                                              },
                                              pattern: {
                                                value: /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,6}\.?)(\/[\w.-]*)*\/?$/,
                                                message: 'Ingresa una URL válida.',
                                              }
                                            })}
                                            onChange={(e) => {
                                              setListOfAddedButtons(prevBtns => prevBtns.map((prevBtn, b) =>
                                                b === i ? { ...prevBtn, exampleUrl: e.target.value } : prevBtn
                                              ));
                                            }}
                                          />
                                        </div>
                                        {errors[`sampleVariableUrl${btn.id}`] && (<Alert>* {errors[`sampleVariableUrl${btn.id}`].message}</Alert>)}

                                      </div>
                                    }

                                    {btn.type === 1 && <>
                                      <div className="d-flex align-items-center mt-2">
                                        <div className="d-flex align-items-center" style={{position:"relative"}}>
                                          <input
                                            name="checkStopMarketing"
                                            id="checkStopMarketing"
                                            type="checkbox"
                                            className="cmn-toggle cmn-toggle-round-flat"
                                            {...register("checkStopMarketing", {
                                              required: {
                                                value: true,
                                                message: "Es necesario marcar la casilla para agregar el botón.",
                                              },
                                            })}
                                          />
                                          <label className="ml-4 mb-0" htmlFor="checkStopMarketing"></label>
                                        </div>
                                        <p className="mb-0 ml-2">Entiendo que es responsabilidad mia dejar de enviar mensajes de marketing a los clientes que desactivan esta opción.</p>  
                                      </div>
                                      {errors.checkStopMarketing && (<Alert>* {errors.checkStopMarketing.message}</Alert>)}
                                    </>}
                                  </div>
                                )) }
                            </div>
                          </div>
                        </div>
                      </div> 
                    </>}

                  </form>
                </div>

                {/**************** COLUMNA 3 ****************/}
                <div className="colum-3 ml-0 pb-1 h-100" style={{overflow:"auto"}}>
                  <section className="section-preview p-3 h-100" >
                    <h3>
                      <span>Vista previa</span>
                    </h3>
                    <div className="section-div1 ml-1 mr-3">
                      <div className="section-div2 pb-2">
                        <div className="section-div3">
                          <div className="section-div4">
                            <div className={headerType === "headerText" ? "header-preview headerText" : "header-preview"}>
                              {headerType === "headerText" && textHeader}

                              {headerType === "media" && selectedMedia === 1 ? (<>
                                {selectedImage ? 
                                  <img src={selectedImage} alt="Imagen de encabezado" className="w-100"/> 
                                : 
                                  <div className="medio-image custom-media"></div>
                                }
                              </>) : selectedMedia === 2 ? (<>
                                {selectedVideo ? (
                                  <ReactPlayer
                                    url={videoo}
                                    loop
                                    controls
                                    width="100%"
                                    height="100%"
                                  />
                                ) : (
                                  <div className="medio-video custom-media"></div>
                                )}
                              </>) : selectedMedia === 3 ? (<>
                                {documentt ?
                                  <iframe src={documentt}></iframe>
                                :
                                  <div className="medio-document custom-media"></div>
                                }
                              </>
                              ) : selectedMedia === 4 ? (
                                <div className="medio-location custom-media"></div>) 
                              : null} 
                            </div>

                            {selectedMedia === 4 && 
                              <div class="data-location">
                                <div class="location-name">{"{{Location name}}"}</div>
                                <div class="location-adress">{"{{Adress}}"}</div>
                              </div>
                            }

                            <div className="section-div5">
                              <span className="span">
                                <span>{inputStr && inputStr}</span>
                              </span>
                            </div>

                            <div className="content-footer">{textFooter ? textFooter : isButtonStopPromotions ? '¿No te interesa? Toca "Detener promociones"' : ""}</div>

                            <time aria-hidden="true" className="_6xe5">{date}</time>
                          </div>
                        </div>

                        {listOfAddedButtons && listOfAddedButtons.slice(0, listOfAddedButtons.length > 3 ? 2 : listOfAddedButtons.length).map((btn, b) => (
                          <div className="content-fastAnswer" key={b}>
                            <div>
                              {btn.type === 1 && <TiArrowBack className="mr-1" style={{fontSize: "16px"}} />}
                              {btn.type === 2 && <TiArrowBack className="mr-1" style={{fontSize: "16px"}} />}
                              {btn.type === 3 && <TiExport className="mr-1" style={{fontSize: "18px"}} />}
                              {btn.type === 4 && <BsFillTelephoneFill className="mr-1" style={{fontSize: "15px"}} />}
                              <span>{btn.type === 1 ? "Detener promociones" : btn.fields[0]}</span>
                            </div>
                          </div>
                        ))}
                        {listOfAddedButtons && listOfAddedButtons.length > 3 && (
                          <div className="content-fastAnswer" key="extraButton">
                            <div>
                              <FaListUl className="mr-1" style={{fontSize: "15px"}} />
                              <span>Ver todas las opciones</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>

              <footer style={{height:"13%"}}>
                <div className="row">
                  <div className="col-sm-6"></div>
                  <div className="col-sm-6 right">
                    <a
                      href="javascript:;"
                      className="btn btn-lg btn-default mr-1"
                      onClick={() => {
                        setMode("createwsp");
                      }}
                    >
                      Volver
                    </a>
                    <input
                      className="btn btn-lg btn-info"
                      value="Guardar"
                      type="submit"
                    />
                  </div>
                </div>
              </footer>
            </form>
          </section>
        </>
      ) : null}
    </Fragment>
  );
};

export default TemplateWhatsapp;

const Alert = styled.span`
  color: red;
  display: block;
  width: 100%;
  text-align: left;
`;

const Botton = styled.button`
  color: #000000;
  background: transparent;
  border: none;
  cursor: pointer;
  opacity: 0.3;

  &:hover {
    color: #000000;
    opacity: 0.7;
  }
`;
