import React, { Fragment, useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import Modal from "../layout/Modal";
import Pagination from "../plugins/Pagination";
import Spinner from "../plugins/Spinner";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import * as XLSX from "xlsx";
import usePage from "../../hooks/usePage";
import useAPI from "../../hooks/useAPI";
import Avvvatars from "avvvatars-react";
import { Link } from "react-router-dom";
import Header from "../layout/Header";
import fileExcel from "../../documents/plantilla.xlsx";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Select from "react-select";
import Countries from "../plugins/Countries.json";
import { BsSearch, BsPencilFill, BsTrash3, BsChatDots, BsWhatsapp, BsFillCheckCircleFill, BsFileEarmarkExcelFill } from "react-icons/bs";
import { BiListPlus } from "react-icons/bi";
import { FaEye, FaListUl } from "react-icons/fa";
import { FaEllipsisVertical } from "react-icons/fa6";
import { AiOutlineUser } from "react-icons/ai";
import debounce from 'lodash/debounce';
import { HiAdjustmentsHorizontal } from "react-icons/hi2";

const Persons = () => {
  //# Estado de los archivos cargados
  const [excelFile, setExcelFile] = useState(null);

  //# Estados del Modulo (Modales, Spinner)
  const [modal, setModal] = useState(false);
  const [importPersons, setImportPersons] = useState(false);
  const [editPerson, setEditPerson] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingLists, setLoadingLists] = useState(false);
  const [validation, setValidation] = useState(false);
  const [listsModal, setListsModal] = useState(false);
  const [validationModal, setValidationModal] = useState(false);

  //# Estado de la data - API (Personas)
  const [persons, setPersons] = useState([]);
  //# Estado de la data - API (Persona)
  const [personData, setPerson] = useState({});
  //# Listas que traen true or false si el usuario esta en la lista
  const [allLists, setAllLists] = useState([]);
  const [tableLists, setTableLists] = useState([]);

  //# Barra de busqueda
  const [tablePersons, setTablePersons] = useState([]);
  const [search, setSearch] = useState("");
  const [searchModal, setSearchModal] = useState("");

  //# Estado para listar campos adicionales
  const [fieldsList, setFieldsList] = useState([]);

  //# Estado para listar nombres de los campos nuevos como encabezado en la tabla
  const [FieldsNames, setFieldsNames] = useState([]);
  //# Estado para almacenar objeto con datos del usuario a editar
  const [dataValues, setDataValues] = useState([]);

  //# Estado para almacenar todos los paises
  const [selectedCountrie, setSelectedCountrie] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedClass2, setSelectedClass2] = useState("");
  const [errorCountrie, setErrorCountrie] = useState(false);

  //# Estados para las etiquetas
  const [listTags, setListTags] = useState([]);
  const [validationTags, setValidationTags] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagsContact, setTagsContact] = useState([]);

  //# Estados para los agentes y areas
  const [listAdvisers, setListAdvisers] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("");

  const [registersPopup, setRegistersPopup] = useState(false);
  const [listRegister, setListRegister] = useState([]);
  const [isDark, setIsDark] = useState(false);
  const [formatLists, setFormatLists] = useState([]);

  const [isAdmin, setIsAdmin] = useState(false);

  // const [selectedSort, setSelectedSort] = useState({id: "1", title: "Fecha de Creación"});
  const [selectedSort, setSelectedSort] = useState(1);
  const [modalFilter, setModalFilter] = useState(false);
  const [segments, setSegments] = useState([]);
  const [segmentsValidate, setSegmentsValidate] = useState(false);
  const [correspondence, setCorrespondence] = useState([]);
  const [modalExport, setModalExport] = useState(false);

  const prevCount = useRef(999999999999999);

  // const [tableHeaders, setTableHeaders] = useState([]);

  //# Custoom Hooks
  const { page, byPage, resetPage, setPage } = usePage();
  const { urlAPI, optGET, optPOST, urlAPI_1, urlAPI_2 } = useAPI();
  
  const token = localStorage.getItem("USER_TOKEN");
  const roleId = localStorage.getItem("USER_ROLEID");
  const userId = localStorage.getItem("USER_ID");

  const [pageSize, setPageSize] = useState(1000);
  const page_api = useRef(0);
  const listContactsRef = useRef([]);
  const totalPages = useRef(1);
  const searchTerm = useRef("");

  const {watch,register,handleSubmit,formState: { errors },reset,} = useForm({
    defaultValues: {
      name: "",
      lastName: "",
      email: "",
      phone: "",
      userId: "",
      dataSegmentCheck: [],
      responsible: "",
    },
  });

  const listOfSortOptions = [
    {id: "1", title: "Fecha de Creación"},
    {id: "2", title: "Orden alfabetico"}
  ]

  useEffect(() => {
    setFormatLists((prev) => { return [...allLists].reverse() });
    let list_checked = allLists.some(list => parseBoolean(list.inList) === true);
    if (list_checked) {
      setValidation((prev) => {return false});
    } else {
      setValidation((prev) => {return true});
    }
  }, [allLists]);

  useEffect(() => {
    const roleDescription = localStorage.getItem("USER_ROLEDESCRIPTION");
    if (
      roleDescription === "Administrador" ||
      roleDescription === "SuperAdmin"
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
    fetchPersonsPaged();
    setPerson({});
    getFields();
    getTags();
    getAgents();
    getCorresp()
  }, []);

  //# Funcion para almacenar el codigo del país en el estado
  const handleChange = (selectedCountrie) => {
    console.log('Pais seleccionado=====>>', selectedCountrie);
    setErrorCountrie(false);
    setSelectedCountrie(selectedCountrie);
    setSelectedClass(selectedCountrie.callingCodes[0]);
    setSelectedClass2(selectedCountrie.callingCodes[0]);
  };

  const fetchPersonsPaged = async (reset = false, filter = false, all = false) => {
    console.log(':::::Dentro de fetchPersonsPaged::::::')
    try {
      setLoading(true);
      let url;

      if(roleId != "6284fdd697029c6d9743ccb3" && roleId != "64886b1b250f9d31bf2ed68d"){
        // Si no es admin deshabilitamos wpp en caso de corresponder. 
        if (reset) {
          url = urlAPI_1 + `fetchPersonsPaged/?assigns=${userId}&page=0&pageSize=${pageSize}&search=`;
        } else if (filter) {
          console.log('entro acá porque es filtroooooooooo............IF.............🤫')
          url = urlAPI_1 + `fetchPersonsPaged/?assigns=${userId}&page=0&pageSize=${pageSize}&filter=` + filter;
        } else if (all) {
          console.log('entro a traer todoooooo............IFF.............🦮')
          url = urlAPI_1 + `fetchPersonsPaged/?assigns=${userId}&page=0&pageSize=${pageSize}&all=${all}`;
        } else{
          url = urlAPI_1 + `fetchPersonsPaged/?assigns=${userId}&page=${page_api.current}&pageSize=${pageSize}&search=${searchTerm.current}`;
        }
      }
      else{
        if(reset){
          url = urlAPI_1 + `fetchPersonsPaged/?page=0&pageSize=${pageSize}&search=`;
        } else if (filter) {
          console.log('entro acá porque es filtroooooooooo............ELSE.............🤫')
          url = urlAPI_1 + `fetchPersonsPaged/?assigns=${userId}&page=0&pageSize=${pageSize}&filter=` + filter;
        } else if (all) {
          console.log('entro a traer todoooooo............ELSE.............🦮')
          url = urlAPI_1 + `fetchPersonsPaged/?assigns=${userId}&page=0&pageSize=${pageSize}&all=${all}`;
        } else{
          url = urlAPI_1 + `fetchPersonsPaged/?page=${page_api.current}&pageSize=${pageSize}&search=${searchTerm.current}`;
        }
      }
      const response = await fetch(url, {
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      setLoading(false);

      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (result.message === "Tu petición no tiene cabecera de autorización") {
        Swal.fire({
          title: "¡Atención!",
          text: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales",
          icon: "info",
        });
        navigate("/");
      } else if (!result.data) {
        setValidation(true);
        setPersons([]);
      } else {
        let listPersons = [];
        if (filter) { 
          listPersons = result.data;
        } else if (all) { 
          return result.data
        } else if(searchTerm.current || page_api.current == 0){
          listPersons = result.data;
        }else{
          listPersons = [...listContactsRef.current, ...result.data];  
        }

        listPersons = [...listPersons].sort((a, b) => new Date(b.created) - new Date(a.created));

        listContactsRef.current = listPersons;
        console.log('Resultado final::::::---->', listPersons)
        
        setPersons(listPersons);
        setTablePersons(listPersons);
        setValidation(false);
        setFieldsNames(result.listFields);
        setLoading(false);
        page_api.current = result.currentPage + 1
        totalPages.current = result.totalPages;
      }
    } catch (error) {
      console.log('error on actualizar', error);
      return null;
    }
  };

  const searchQuery = (reset = false) => {
    console.log(':::::Dentro de searchQuery::::::', page_api.current)
    console.log(':::::Dentro de searchQuery:::totalPages:::', totalPages.current)
    if (page_api.current > totalPages.current) {
      searchQueryLocal();
    } else {
      // busqueda en db
      fetchPersonsPaged(reset);
    }
  };

  const debouncedSearchQuery = useCallback(
    debounce((value) => {
      if (value === "") {
        searchQuery(true);
      } else {
        searchQuery();
      }
    }, 2000),
    []
  );

  const getCorresp = async () => {
    try {
      const url = urlAPI_2 + "getDataEmail";
      const response = await fetch(url, {
        mode: "cors",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      console.log("list correspondence-------->>", result);
      setCorrespondence(result.data);
      if (result.message === "Tu petición no tiene cabecera de autorización") {
        Swal.fire({
          title: "¡Atención!",
          text: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales",
          icon: "info",
        });
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleChangeSearch = (e) => {
    const value = e.target.value;
    searchTerm.current = e.target.value;
    setSearch(value);
    debouncedSearchQuery(value);
  };

  //# Funcion que se encargar de filtrar por busqueda
  const searchQueryLocal = () => {
    console.log(':::::Dentro searchQueryLocal:::')
    let searchResults = tablePersons.filter((element) => {
      if (
        element.name
          .toString()
          .toLowerCase()
          .includes(searchTerm.current.toLowerCase()) ||
        element.phone
          .toString()
          .toLowerCase()
          .includes(searchTerm.current.toLowerCase())
      ) {
        return element;
      }
    });
    setPersons(searchResults);
    if (searchResults.length === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };

  const cleanInputs = () => {
    reset({ name: "", email: "", phone: "" });
  };

  //# Method POST - API
  const postPerson = async (values) => {
    values.callingCode = `+${selectedCountrie.callingCodes[0]}`;
    values.country = selectedCountrie.country;
    var tags = []
    for(var tag of selectedTags){
      console.log('TAGS==>', tag._id)
      tags.push(tag._id)
    }
    values.tags = tags.join(',')
    values.responsible = selectedAgent.name
    console.log("leyendo el value en persons:::", values);
    try {
      setLoading(true);
      const url = urlAPI_1 + "newPerson";
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(values),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      if (result.message === "Tu petición no tiene cabecera de autorización") {
        Swal.fire({
          title: "¡Atención!",
          text: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales",
          icon: "info",
        });
        navigate("/");
      }
      console.log("Data Send===>> ", result);

      let textTitle = ""
      if (result.type == "warning") {
        textTitle = "¡Ups!"
      } else if (result.type == "succes") {
        textTitle = "¡Atención!"
      } else {
        textTitle = "¡Bien!"
      }
      //# POST Completado, se reinician lo campos
      Swal.fire({
        title: textTitle,
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });
      setModal(false);
      reset();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  //# Method DELETE - API
  const deletePerson = async (id) => {
    try {
      Swal.fire({
        title: "¿Estas seguro que deseas eliminar esta persona?",
        text: "Se borrara la conversación junto con todos los mensajes y no podrás tener acceso de nuevo a su información.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const url = urlAPI_1 + `persons/${id}`;
          const response = await fetch(url, {
            method: "DELETE",
            mode: "cors",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
          const result = await response.json();
          console.log("Data Eliminated", result);

          //# Actualización de la tabla
          let aux = listContactsRef.current.filter((row) => {return row._id != id})
          listContactsRef.current = aux;
          searchTerm.current = "";
          setSearch((prev) => { return "" });
          //searchQuery();
          setPersons((prev) => { return aux });
          setTablePersons((prev) => { return aux });


          setLoading(false);

          //# Confirmación de la eliminación
          Swal.fire({
            title: "¡Bien!",
            text: result.message,
            icon: result.type,
            confirmButtonText: "Aceptar",
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  //# Method GET/:id - API
  const getPerson = async (id) => {
    setLoading(true);
    const url = urlAPI_1 + `persons/${id}`;
    const response = await fetch(url, {
      mode: "cors",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    console.log("Result data data data : : : :", result);
    setPerson(result.data[0]);
    setDataValues(result.data);
    
    const { _id, name, email, phone, callingCode, tags, responsible } = result.data[0];
    if (callingCode) {
      const countrieSelect = Countries.filter(countrie => countrie.callingCodes[0] === callingCode.replace('+', ''));
      console.log('resultado del filtro====>', countrieSelect);
      console.log('resultado -------------------------------====>', Countries[Countries.length - 1]);
      setSelectedClass2(callingCode.replace('+', ''));
      setSelectedCountrie(countrieSelect[0]);
      // if(countrieSelect.length > 0) {
      //   setSelectedCountrie(countrieSelect[0]);
      // } else {
      //   let otter = Countries[Countries.length - 1];
      //   otter.callingCodes = [callingCode]
      //   console.log('---------------otterr----------------====>', otter);
      //   setSelectedCountrie(otter);
      // }
    }

    if (tags) {
      setTagsContact(tags)
    }

    if (responsible) {
      const agentSelected = listAdvisers.filter(agent => agent.name == responsible);
      console.log('resultado del filtro agentSelected====>', agentSelected[0]);
      setSelectedAgent(agentSelected[0]);
    }


    var objectData = {};

    let keys = Object.keys(result.data[0]);
    // console.log("keys", keys);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];

      objectData[key] = result.data[0][key];
      // console.log("objectData[key]", objectData[key]);
    }

    objectData["userId"] = _id;
    console.log("data........", objectData);

    reset(objectData, { keepDefaultValues: true });
    setLoading(false);
  };

  //# Method PUT - API
  const putPerson = async (values) => {
    const code = selectedCountrie.callingCodes[0];
    const id = personData._id;
    values.callingCode = `+${code}`;

    var tags = []
    for(var tag of selectedTags){
      // console.log('TAGS==>', tag._id)
      tags.push(tag._id)
    }
    values.tags = tags.join(',')
    values.responsible = selectedAgent.name
    // console.log("leyendo el value en persons", values);
    try {
      setLoading(true);
      const url = urlAPI_1 + `persons/${id}`;
      const response = await fetch(url, {
        method: "PUT",
        mode: "cors",
        body: JSON.stringify(values),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      });
      const result = await response.json();
      console.log("Data Updated", result);
      let new_value = result.data;
      new_value.tags = selectedTags;
      let old_value = listContactsRef.current.filter((row) => {return row._id == id})
      if(old_value.length){
        new_value.wpp_enabled = old_value[0].wpp_enabled;
      }
      let aditionalFields = new_value.aditionalFields;
      for(let key_dict in aditionalFields){
        new_value[key_dict] = aditionalFields[key_dict];
      }
      let aux = listContactsRef.current.filter((row) => {return row._id != id})
      aux = [...[new_value], ...aux];
      listContactsRef.current = aux;
      searchTerm.current = "";
      setSearch((prev) => { return "" });
      //searchQuery();
      setPersons((prev) => { return aux });
      setTablePersons((prev) => { return aux });

      reset();
      setEditPerson(false);
      setLoading(false);

      //# Confirmacion del cambio
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      console.log(error);
    }
  };

  //# Función que ayuda a cargar y validar la importación de archivos en formato Excel
  const uploadFile = (e) => {
    //# Definiendo el tipo de archivo permitido
    const fileType = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFile(null);
          setExcelFile(e.target.result);
        };
      } else {
        setExcelFile(null);
        Swal.fire({
          title: "¡Atención!",
          text: "Por favor elige el formato correcto.",
          icon: "warning",
        });
      }
    }
  };

  const importUsers = async (values) => {
    try {
      setImportPersons(false);
      setLoading(true);
      const url = urlAPI_1 + "importUsers";
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(values),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      setLoading(false);
      if (response.ok) {
        let processCode = result.processCode;
        let successSwal = {};
        if(result.message == "Todos los registros contienen errores"){
          successSwal = {
            title: "Los contactos no pudieron ser cargados",
            text: "No se pudo cargar ningún contacto",
            icon: "error",
          };
        }else{
          successSwal = {
            title: result.message,
            text:  "La carga de contactos está siendo procesada. Será notificado por mail cuando finalice.",
            icon: result.type,
          };
        }
        Swal.fire(successSwal).then((swalResult) => {
          if (result.contactosError && result.contactosError.length > 0) {
            const errorWorkbook = XLSX.utils.book_new();
            const errorWorksheet = XLSX.utils.json_to_sheet(result.contactosError, { skipHeader: true });
            XLSX.utils.book_append_sheet(errorWorkbook, errorWorksheet, 'Errores');
            const errorWbout = XLSX.write(errorWorkbook, { bookType: 'xlsx', type: 'array' });
            const errorBlob = new Blob([errorWbout], { type: 'application/octet-stream' });
            const errorFileName = 'Errores_Importacion.xlsx';
            
            let title_message = "Algunos contactos no pudieron ser procesados.";
            let text_message = "Haz click en el enlace para descargar un archivo con los detalles de los errores. El resto continuará cargandose en background.";
            if(result.message == "Todos los registros contienen errores"){
              text_message = 'No se pudo cargar ningún contacto de su lista. Puedes descargar el excel para ver los detalles.';
              title_message = 'Los contactos no pudieron ser cargados';
            }
            Swal.fire({
              icon: "error",
              title: title_message,
              text: text_message,
              showCancelButton: true,
              showConfirmButton: true,
              confirmButtonText: 'Descargar Errores',
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#3085d6',
              preConfirm: () => {
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(errorBlob);
                a.href = url;
                a.download = errorFileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
              }
            });
          }
        });
  
      } else {
        setLoading(false);
        Swal.fire({
          title: "¡Atención!",
          text: result.message || "Hubo un error al procesar la solicitud.",
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  
  //# Navegacion
  const navigate = useNavigate();

  const sendFile = () => {
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];

      //#--> Obtenemos las llaves del objeto que nos genera worksheet
      const allKeys = Object.keys(worksheet);
      
      //#--> Ordenamos alfabeticamente las llaves
      const sortKeys = allKeys.sort()
      
      //#--> Obtenemos el ultimo valor
      const lastValue = sortKeys[sortKeys.length - 1]

      //#--> Obtenemos solamente la letra del ultimo valor
      const letra = lastValue.replace(/\d/g, '');
      
      // const ultimaLetra = letra.charAt(letra.length - 1);
      
      //#--> Obtenemos solamente la letra del ultimo valor
      const numericalValue = letra.charCodeAt(0) - 'A'.charCodeAt(0) + 1;

      //#--> Obtener encabezados directamente de las columnas A a la ultima letra
      const headers = Array.from({ length: numericalValue }, (_, index) => String.fromCharCode(65 + index) + '1');
      console.log("headers=====>>", headers);

      //#--> Validar si algún encabezado está vacío
      const emptyHeaders = headers.filter(header => {
        const cellValue = worksheet[header] ? worksheet[header].v : null;
        return cellValue === undefined || cellValue === null || cellValue === '';
      });
      console.log("emptyHeaders=====>>", emptyHeaders);
      
      if (emptyHeaders.length > 0) {
        setImportPersons(false)
        setExcelFile(null);
        //#--> Validamos si es mas de un encabezado para ajustar el mensaje
        let text = ""
        if (emptyHeaders.length > 1) {
          text =  `Los encabezados ${emptyHeaders.join(', ')} no pueden estar vacíos.`
        } else {
          text = `El encabezado ${emptyHeaders.join(', ')} no puede estar vacío.`
        }
        Swal.fire({
          title: "¡Atención!",
          text: text,
          icon: "error",
        });
      } else {
          //#--> Todos los encabezados están llenos
          const data = XLSX.utils.sheet_to_json(worksheet);
          validateAndImport(data);
          setExcelFile(null);
      }
    } else {
        Swal.fire({
          title: "¡Atención!",
          text: "Por favor selecciona el formato correcto.",
          icon: "error",
        });
    }
  };

  const validateAndImport = async (array) => {
    setLoading(true);
    let isValid = true;
    const emptyCells = [];
    const emailRegex = /^\S+@\S+\.\S+/;
    const requiredColumns = ["Nombre", "Código de area", "Teléfono"];
    
    for (let i = 0; i < array.length; i++) {
      const data = array[i];
      const keywords = Object.keys(data);
      
      if (requiredColumns.every((column) => keywords.includes(column))) {
        let errorMessages = [];
        
        //#--> Validar formato de correo
        const correo = data["Correo"];
        if (correo) {
          if (!emailRegex.test(correo)) {
            errorMessages.push("Correo");
            isValid = false;
          }
        }

        //#--> Validar si Código de área es numérico
        const codigoArea = data["Código de area"];
        if (isNaN(codigoArea)) {
          errorMessages.push("Código de área");
          isValid = false;
        }

        //#--> Validar si Teléfono es numérico
        const telefono = data["Teléfono"];
        if (isNaN(telefono)) {
          errorMessages.push("Teléfono");
          isValid = false;
        }

        if (errorMessages.length > 0) {
          const msgFormat = errorMessages.length > 1 ? errorMessages .slice(0, -1).join(", ") + " y " + errorMessages[errorMessages.length - 1] : errorMessages.join(", ");
          emptyCells.push({
            linea: i + 1,
            callingCode: data["Código de area"],
            phone: data.Teléfono,
            columnas: `Formato incorrecto en: ${msgFormat}`,
          });
        }
        
      } else {
        //#--> Al menos uno de los campos requeridos no está presente en esta línea
        isValid = false;
  
        //#--> Buscar las columnas faltantes en la línea actual
        const missingColumns = requiredColumns.filter((column) => !keywords.includes(column));
        const text = missingColumns.length > 1 ? "Campos vaciós:" : "Campo vacio:" ;
        const msgFields = missingColumns.length > 1 ? missingColumns .slice(0, -1).join(", ") + " y " + missingColumns[missingColumns.length - 1] : missingColumns.join(", ");
        emptyCells.push({
          linea: i + 1,
          callingCode: data["Código de area"],
          phone: data.Teléfono,
          columnas: `${text} ${msgFields}`,
        });
      }
    }
    setLoading(false);
    if (isValid) {
      const dataFormatted = {
        dataUsers: array,
      };
      importUsers(dataFormatted);
    } else {
      const dataFormatted = {
        dataUsers: array,
      };
      importUsers(dataFormatted);
    }
  };

  const getLists = async (id) => {
    setAllLists([]);
    setLoadingLists(true);
    console.log(id);
    try {
      setLoading(true);
      const url = urlAPI_1 + "listByUser/" + id;
      const response = await fetch(url, optGET());
      const result = await response.json();
      console.log("Listas in person module", result);
      console.log("result ======>", result);
      if (result.type != "success") {
        setValidationModal(true);
        setListsModal([]);
      } else {
        console.log("result.data ======>", result.data);
        setAllLists(result.data);
        setTableLists(result.data);
        setValidationModal(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setLoadingLists(false);
  };

  const searchLists = (searchTerm) => {
    var searchResults = tableLists.filter((element) => {
      if (
        element.name.toString().toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return element;
      }
    });
    setAllLists(searchResults);
    if (searchResults.length > 0) {
      setValidationModal(false);
    } else {
      setValidationModal(true);
    }
  };

  const addUserToList = async () => {
    console.log("Enviando...");
    const activePersonsIds = tableLists
    .filter((list) => list.inList === true)
    .map((activelist) => activelist._id);

    console.log("activePersonsIds..................", activePersonsIds);

    const { userId, dataSegmentCheck } = watch();
    const values = {
      userId,
      dataSegmentCheck: activePersonsIds,
    };
    console.log("values::::", values);
    console.log("userId::::", values.userId);
    try {
      setLoading(true);
      const url = urlAPI_1 + "addUserToList";
      const response = await fetch(url, optPOST(values));
      const result = await response.json();
      console.log("Result of the API", result);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
      });
      setListsModal(false);
      setLoading(false);
      // getLists(values.userId);
      // navigate('/home/users');
    } catch (error) {
      console.log(error);
    }
  };

  //Listar campos adicionales en el formulario de crear usuario
  const getFields = async () => {
    try {
      const url = urlAPI_2 + "aditionalFields";
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      const res = result.data;
      console.log("respuesta", result);
      console.log("respuesta GET fields", res);
      setFieldsList(res);
    } catch (error) {
      console.log(error);
    }
  };

  //#--> Listar las etiquetas
  const getTags = async () => {
    try {
      // setLoading(true);
      const url = urlAPI_1 + `tags`;
      const response = await fetch(url, optGET());
      const result = await response.json();
      console.log("Data:::", result);
      if(!result.data) {
        console.log("no hay data");
        setListTags([]);
        setValidationTags(true);
      } else {
        console.log("si hay lista");
        setListTags(result.data);
        setValidationTags(false);
      }
      // setLoading(false);
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  }

  //#--> Funcion para almacenar las etiquetas asignadas al contacto a crear
  const handleChange2 = data => {
    console.log('Etiquetas seleccionadas seleccionado=====>>',data);
    setSelectedTags(data)
    setTagsContact(data)
  };

  //Funcion que obtiene la lista de agentes segun el area
  const getAgents = async () => {
    try {
      const url = urlAPI_2 + "usersbyArea";
      const response = await fetch(url, optGET());
      const result = await response.json();
      console.log("Response agentes==============>>", result);
      // console.log("Response result.data.agents = : = ", result.data.agents);

      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (
        result.message === "Tu petición no tiene cabecera de autorización"
      ) {
        Swal.fire({
          title: "¡Atención!",
          text: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales",
          icon: "info",
        });
        navigate("/");
      } else if (!result.data) {
        // setValidation(true);
        setListAdvisers([]);
        // setSearchAdvisers([]);
      } else {
        setListAdvisers(result.data);
        localStorage.setItem("ID_COMPANY", result.idCompany);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //#--> Funcion para almacenar el agente seleccionado
  const handleChange3 = data => {
    console.log('agente====> seleccionado=====>>',data);
    setSelectedAgent(data)
    // setTagsContact(data)
  };

  const customNoOptionsMessage = () => "Sin opciones";

  //#--> Función para descargar plantilla en xlsx
  const downloadTable = () => {
    const table = document.getElementById('tablePersons');
    const worksheet = XLSX.utils.table_to_sheet(table);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Lista de usuarios');
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    const fileName = 'Plantilla sendingup.xlsx';

    // Descargar archivo XLSX
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  const getIsDark = () => {
    const dark = localStorage.getItem("THEME");
    setIsDark(dark);
  }

  const customStyles = {
    control: (provided) => ({
      ...provided, // Esto asegura que no sobrescribes otros estilos
      height: '33px',
      minHeight: '33px',
      backgroundColor: '#fff',
    }),
    option: (provided, state) => ({
      ...provided, // Extiende los estilos predeterminados de option
      backgroundColor: state.isSelected ? 'lightblue' : '#fff',
      color: state.isSelected ? '#333' : '#333',
      '&:hover': {
        backgroundColor: 'lightgray',
        color: '#333',
        cursor: 'pointer',
      },
    }),
    // Asegúrate de incluir otros estilos predeterminados si es necesario
  };
  
  const customStylesDark = {
    control: (provided) => ({
      ...provided,
      height: '33px',
      minHeight: '33px',
      backgroundColor: '#202c33',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#455a64' : '#202c33',
      color: state.isSelected ? 'white' : 'white',
      '&:hover': {
        backgroundColor: '#374954',
        color: 'white',
        cursor: 'pointer',
      },
    }),
  };

  const handlePersonCheck = (e, list) => {
    console.log('========e========>>', e.target.checked)
    let new_list_states = allLists.map((row) => {
      if(row._id === list._id){
        row.inList = parseBoolean(e.target.checked);
      }
      return row;
    })

    setAllLists(new_list_states);

    let new_list_table_states = tableLists.map((row) => {
      if(row._id === list._id){
        row.inList = parseBoolean(e.target.checked);
      }
      return row;
    })

    setTableLists(new_list_table_states);
  };

  function parseBoolean(value) {
    if (typeof value === 'boolean') {
      return value;
    } else if (typeof value === 'string') {
      const lowerCaseValue = value.toLowerCase();
      if (lowerCaseValue === 'true') {
        return true;
      } else if (lowerCaseValue === 'false') {
        return false;
      }
    }
    // Si no es ni booleano ni una cadena válida, devuelve false por defecto
    return false;
  }

  //#--> Funcion para controlar la opcion de ordenar por:
  const handleChange4 = async (e) => {
    const data = e.target.value
    setSelectedSort(data)
    setLoading(true)
    if (data == '2') {
      const sortedItems = persons.sort((a, b) => a.name.localeCompare(b.name));
      console.log('📑sortedItems======= orden alfabetico ========>>', sortedItems);
      setPersons(sortedItems);
    } else if (data == "1") {
      const sortedItems = persons.sort((a, b) => new Date(b.created) - new Date(a.created));
      console.log('📑sortedItems======== fecha de creación =======>>', sortedItems);
      setPersons(sortedItems);
    }
    setLoading(false)
  };

  const handleInputChange = (index, event) => {
    // console.log('INDEX------->', index)
    // console.log('LEYENDO NAME------->', event.target.name)
    // console.log('LEYENDO DATAAAAA------->', event.target.value)
    const newSegments = [...segments];
    newSegments[index][event.target.name] = event.target.value;
    setSegments(newSegments);
  };

  const addSegment = () => {
    setSegments([
      ...segments,
      { attribute: "", operation: "", valor: "" }
    ]);
  };

  const removeSegment = (index) => {
    const newSegments = segments.filter((_, i) => i !== index);
    setSegments(newSegments);
  };

  const validateSegments = () => {
    // Devuelve true si todos los campos en cada segmento están llenos
    return segments.every(segment => {
      return segment.attribute && segment.operation && segment.valor;
    });
  };

  // Para filtrar de acuedo a los segmentos agregados
  const postFilterContacts = async (e) => {
    e.preventDefault(); // Prevenir que se recargue la página

    if (!validateSegments()) {
      setSegmentsValidate(true)
      return;
    }

    setModalFilter(false);
    setSegmentsValidate(false)
    // setSegments([])
    console.log('📑', segments)
    if (segments.length > 0) {
      const data = JSON.stringify(segments)
      const reset = false
      await fetchPersonsPaged(reset, encodeURIComponent(data))
    } else {
      await fetchPersonsPaged()
    }
    // const filteredPersons = filterPersonsList(segments, personsListFilter);
    // console.log('🚩🚩', filteredPersons);
    // setPersonsList(filteredPersons)
  }

  // Exportar contactos
  const exportToExcel = async () => {
    console.log('DATA recibida:', persons)
    let data;
    if (segments.length > 0) {
      data = persons
    } else {
      const reset = false
      const filter = false
      const all = true
      const list = await fetchPersonsPaged(reset, filter, all)
      data = list
    }
    let listContacts = []
    for (let c = 0; c < data.length; c++) {
      const element = data[c];
      if (element != null) {
        let cod = element.callingCode
        if (element.callingCode && element.callingCode.includes('+')) {
          cod = element.callingCode.replace('+','')
        }
        let contact = {
          Nombre: element.name,
          Correo: element.email,
          Código: cod, 
          Teléfono: element.phone,
        }

        // Agregar dinámicamente los campos adicionales que no son fijos
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            // Ignorar los campos fijos
            if (
              key !== 'name' &&
              key !== 'email' &&
              key !== 'callingCode' &&
              key !== 'phone' &&
              key !== '_id' &&
              key !== '__v' &&
              key !== 'wpp_enabled' &&
              key !== 'tags' &&
              key !== 'segments' &&
              key !== 'isV' &&
              key !== 'company' &&
              key !== 'created'
            ) {
              contact[key] = element[key];
            }
          }
        }

        listContacts.push(contact);
      }
    }
    // console.log('DATA Fiiinal a exportar:', listContacts)
    if (listContacts.length <= 0) {
      Swal.fire({
        title: '¡Atención!',
        text: 'Sin contactos para exportar.',
        icon: 'info',
      });
    } else {
      const worksheet = XLSX.utils.json_to_sheet(listContacts);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, `Contactos.xlsx`);
    }
  };

  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}

      {/*-------- popup - tabla registros con error ----------*/}
      {registersPopup && (
        <div className="image-preview-overlay">
          <div className="image-preview-container">
            <div className="records-not-loaded p-2" style={{width: "1000px", height: "auto", borderRadius: "7px"}}>
                <div className="p-3">
                  <h2 className="mb-3 d-flex justify-content-center" >
                    Registros no cargados!
                  </h2>
                  <div className="container-fluid mt-3 px-0" style={{ height: "580px", overflow: "auto" }}>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Línea</th>
                          <th>Código de area</th>
                          <th>Contacto</th>
                          <th>Descripción</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listRegister && listRegister.map((list, l) => (
                          <tr key={l}>
                            <td>{list.linea}</td>
                            <td>{list.callingCode}</td>
                            <td>{list.phone}</td>
                            <td>{list.columnas}</td>
                          </tr>
                        ))}

                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <a
                      href="javascript:;"
                      className="btn btn-lg btn-default mr-1"
                      onClick={() => setRegistersPopup(false)}
                      >
                      Cerrar
                    </a>
                  </div>
                </div>
            </div>
          </div>
        </div>
      )}

      <div className="m-auto container-fluid p-0 h-100">
        <div className="col-xs-9 heading mt-1">
          <div>
            <h1>Contactos</h1>
            <input
              type="button"
              className="btn btn-default"
              value="Agregar Nuevo"
              onClick={() => {
                cleanInputs();
                setModal(true);
                setSelectedCountrie("");
                setTagsContact("");
                setSelectedAgent("");
                getIsDark();
              }}
            />
            <button
              id="inventory-button"
              className="btn btn-default ml-1"
              onClick={() => setImportPersons(true)}
            >
              Subir/Importar contactos
            </button>
            {persons.length > 0 && (
              <button
                id="inventory-button"
                className="btn btn-default ml-1"
                onClick={exportToExcel}
              >
                Descargar/Exportar contactos
              </button>
            )}
          </div>
          <Header />
        </div>

        <header id="search-contact">
          <div className="d-flex" style={{ float: "right" }}>
            <div className='d-flex align-items-center mr-3'>
              <div className='d-flex align-items-center mr-2'>
                <label>Ordenar por: </label>
                <select
                  name="attribute"
                  className="form-control"
                  value={selectedSort}
                  onChange={(e) => handleChange4(e)}
                >
                  <option value="1" key="1">Fecha de Creación</option>
                  <option value="2" key="2">Orden alfabetico</option>
                </select>
              </div>

              <div style={{ position: "relative", display: "flex" }}>
                <HiAdjustmentsHorizontal
                  className="icon-filter-2 f-36 pointer ml-2"
                  onClick={() => {
                    setModalFilter(true);
                    getIsDark();
                  }}
                />
                {segments.length > 0 && (
                  <div
                    className="number-messages"
                    style={{
                      fontSize: "12px",
                      position: "absolute",
                      top: "-4px", // Ajusta según sea necesario
                      right: "-8px", // Ajusta según sea necesario
                      backgroundColor: "#00a884",
                      // backgroundColor: "red",
                      color: "white",
                      borderRadius: "50%",
                      padding: "0px 5px",
                      width: "18px",
                      height: "18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {segments.length}
                  </div>
                )}
              </div>
            </div>
          
            <div className='d-flex align-items-center'>
              <div className="form-inline">
                <div className="su-control my-1">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Búsqueda"
                    value={search}
                    onChange={handleChangeSearch}
                  />
                  <button
                    className="btn btn-info"
                    type="button"
                    id="buttonSearchProductBack"
                    style={{ height: "34px" }}
                    onClick={() => {
                      searchQuery();
                      resetPage();
                    }}
                  >
                    <BsSearch />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="container-fluid cont-contacts mt-3 px-0" style={{ height: "75%", overflow: "auto" }}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Acciones</th>
                <th className="hide-responsive"></th>
                {/* <th></th> */}
                <th className="hide-responsive">Etiquetas</th>
                <th>Nombre</th>
                <th className="hide-responsive">Email</th>
                <th>Teléfono</th>
                {/* {FieldsNames &&
                  FieldsNames.map((item, k) => <th key={k}>{item}</th>)} */}
                <th className="hide-responsive">Activo</th>
              </tr>
            </thead>
            <tbody>
              {persons.length > 0 && persons.slice((page - 1) * byPage, (page - 1) * byPage + byPage).map((person) => {
                const initialWords = person.name.split(" ");
                const firstWord = initialWords[0];
                const secondWord = initialWords[1];
                let icon = "";
                if (person.lastName) {
                  const initialWordsLastName = person.lastName.split(" ");
                  const firstWordLastName = initialWordsLastName[0];
                  // console.log('nombress::', firstWord[0] + firstWordLastName[0])
                  icon = firstWord[0] + firstWordLastName[0];
                } else {
                  if (secondWord === undefined) {
                    icon = firstWord[0];
                  } else {
                    icon = firstWord[0] + secondWord;
                  }
                }

                var title = "";
                if (person.tags.length > 0) {
                  title = person.tags[0].title
                }

                return (
                  <tr key={person._id}>
                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        {/* Acciones en dropdown */}
                        <div className="dropdown">
                          <button
                            onClick={() => getPerson(person._id)}
                            className="bg-transparent border-0"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <FaEllipsisVertical className="text-success f-24 pointer" />
                          </button>
                          <div className="dropdown-menu p-2" aria-labelledby="dropdownMenuButton">
                            <button
                              onClick={() => {
                                getPerson(person._id);
                                setEditPerson(true);
                                setSelectedCountrie("");
                                setTagsContact("");
                                setSelectedAgent("");
                                getIsDark();
                              }}
                              className="text-primary border-0 bg-transparent d-block pointer mt-2"
                            >
                              <BsPencilFill style={{ fontSize: "18px" }}/>
                              <span className="text-dark ml-2 f-14">
                                Editar
                              </span>
                            </button>
                            {isAdmin &&
                              <button className="text-danger border-0 bg-transparent d-block pointer mt-2" onClick={() => deletePerson(person._id)}>
                                <BsTrash3 style={{ fontSize: "18px" }}/>
                                <span className="text-dark ml-2 f-14">
                                  Eliminar
                                </span>
                              </button>
                            }
                            <button className="border-0 bg-transparent d-block pointer mt-2" 
                              onClick={() => navigate(`/home/contact/${person._id}`)}
                            >
                              <FaEye style={{fontSize: "18px", color: "#ff9317"}}/>
                              <span className="text-dark ml-2 f-14">
                                Ver perfil
                              </span>
                            </button>
                            <button className="border-0 bg-transparent d-block pointer mt-2" 
                              onClick={() => {setListsModal(true);getPerson(person._id);getLists(person._id);}}
                            >
                              <BiListPlus className="text-success" style={{ fontSize: "19px" }}/>
                              <span className="text-dark ml-2 f-14">
                                Agregar a lista
                              </span>
                            </button>
                          </div>
                          <BsWhatsapp 
                            style={{ fontSize: "23px", color: person.wpp_enabled ? "#00a884" : "gray" }} 
                            title="Enviar mensaje"
                            onClick={person.wpp_enabled ? () => {
                              navigate(`/home/chat`, { 
                                  state: { 
                                      parametro: person.callingCode + person.phone, 
                                      idContact: person._id,
                                      isChat: true
                                  } 
                              })
                            } : undefined}
                            disabled={!person.wpp_enabled}
                          />
                        </div>
                      </div>
                    </td>
                    <td className="icon-avatar hide-responsive">
                      <Avvvatars value={icon} />
                    </td>
                    <td className="hide-responsive py-0">
                        <ul className="d-flex flex-wrap" style={{lineHeight: "1.8"}}>
                          {person.tags && person.tags.slice(0, 6).map((tag, t) => (
                            <li
                              key={t}
                              className="px-2 py-0 mt-1 mr-2 border-0"
                              style={{
                                backgroundColor: tag.color,
                                fontSize: "0.75rem",
                                color: "#fff",
                                borderRadius: "7px"
                              }}
                            >
                              {tag.title.length >= 10
                                ? tag.title.substr(0, 10) + "..."
                                : tag.title
                              }
                            </li>
                          ))}
                          {person.tags && person.tags.length > 6 && (
                            <li
                              className="px-0 py-0 mr-2 border-0"
                              style={{
                                fontSize: "0.8rem",
                              }}
                            >
                              ...+1
                            </li>
                          )}
                        </ul>
                    </td>
                    <td>
                      {person.name && person.name.length >= 24 ? person.name.substr(0, 24) + "..." : person.name}
                      {" "}
                      {person.lastName && person.lastName.length >= 24 ? person.lastName.substr(0, 24) + "..." : person.lastName}
                    </td>
                    <td className="hide-responsive">
                      {person.email && person.email.length >= 20 ? person.email.substr(0, 20) + "..." : person.email}
                    </td>
                    <td>{person.phone}</td>
                    <td className="hide-responsive">
                      Desde{" "}{dayjs(person.created).locale("es").format("DD MMMM YYYY")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {validation && (
            <div className="text-center mt-3">
              <div className="empty">
                <AiOutlineUser style={{ fontSize: "55px" }}/> 
                <h1>No tienes contactos</h1>
                <input
                  type="button"
                  className="btn btn-default"
                  value="Agregar Nuevo"
                  onClick={() => {
                    cleanInputs();
                    setModal(true);
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <footer>
          <div className="row">
            <div className="col-md-8">
              {/* <label>Para los elementos seleccionados:</label>
              <select className="form-control">
                <option>Selecciona una acción</option>
                <option>Ocultar</option>
                <option>Eliminar</option>
              </select>
              <button type="submit" className="btn btn-default">Aplicar</button> */}
            </div>

            <div className="col-md-4">
              {/* Creación de la paginación simple */}
              <Pagination
                totalData={persons.length}
                byPage={byPage}
                setPage={setPage}
                onLastPage={fetchPersonsPaged}
              />
            </div>
          </div>
        </footer>
      </div>

      <table
        className="table table-striped"
        id="tablePersons"
        style={{ display: "none" }}
      >
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Correo</th>
            <th>Código de area</th>
            <th>Teléfono</th>
            {FieldsNames &&
              FieldsNames.map((item, k) => <th key={k}>{item}</th>)}
          </tr>
        </thead>
      </table>

      {/* Modal para filtrar contactos */}
      <Modal
        status={modalFilter}
        changeStatus={setModalFilter}
        title='Filtrar contactos'
        width={"800px"}
      >
        <Content>
          <form onSubmit={postFilterContacts} style={{ width: "100%" }}>
            <div className="">
              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                <div id="content-segments" style={{maxHeight:"50vh", overflow:"auto"}}>
                  {segments.map((segment, index) => (
                    <div key={index} className="su-segmentation-disable d-flex align-items-center justify-content-around mb-2">
                      <div className="mr-2">
                        <select
                          name="attribute"
                          className="form-control same-width"
                          value={segment.attribute}
                          onChange={(e) => handleInputChange(index, e)}
                        >
                          <option value="" key="0" disabled>Atributo</option>
                          <option value="tags" key="213">Etiqueta</option>
                          {correspondence && correspondence.map((element, k) => (
                            <option value={element.field} key={k}>
                              {element.fieldName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mr-2">
                        <select
                          name="operation"
                          className="form-control same-width"
                          value={segment.operation}
                          onChange={(e) => handleInputChange(index, e)}
                        >
                          <option value="" key="0" disabled>Operación</option>
                          <option value="contains" key="1">Contiene</option>
                          {segment.attribute !== "tags" && (<>
                            <option value="not_contains" key="2">No contiene</option>
                            <option value="=" key="3">{"="}</option>
                            <option value=">" key="4">{">"}</option>
                            <option value="<" key="5">{"<"}</option>
                            <option value=">=" key="6">{">="}</option>
                            <option value="<=" key="7">{"<="}</option>
                            <option value="!=" key="8">{"!="}</option>
                          </>)}
                        </select>
                      </div>
                      <div className="mr-2">
                        {segment.attribute === "tags" ? (
                          <select
                            className="form-control same-width"
                            name="valor"
                            value={segment.valor}
                            onChange={(e) => handleInputChange(index, e)}
                          >
                            <option value="" disabled>Selecciona una etiqueta</option>
                            {listTags.map((etiqueta, idx) => (
                              <option key={idx} value={etiqueta._id}>
                                {etiqueta.title}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <input
                            className="form-control same-width"
                            type="text"
                            name="valor"
                            placeholder="Valor"
                            value={segment.valor}
                            onChange={(e) => handleInputChange(index, e)}
                          />
                        )}
                      </div>
                      <div>
                        <BsTrash3 className="pointer f-30" onClick={() => removeSegment(index)} />
                      </div>

                    </div>
                  ))}
                </div>
                <div className="text-center mt-3">
                  {segmentsValidate && (
                    <div className="mb-2">
                      <Alert>* Debes llenar todos los campos.</Alert>
                    </div>
                  )}
                  <a
                    href="javascript:;"
                    className="btn btn-lg btn-default mr-1"
                    onClick={addSegment}
                  >
                    Agregar segmento nuevo +
                  </a>
                </div>
              </div>
              
            </div>

            <div className="row justify-content-end bd col-12 mt-4">
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => {
                  setModalFilter((prevMessage) => { return false});
                }}
              >
                Cancelar
              </a>
              <input
                className="btn btn-lg btn-info"
                // type="button"
                type="submit"
                value="Aceptar"
                // onClick={() => {
                //   // filterByTags()
                //   setModalFilter((prevMessage) => { return false });
                //   setSegments([])
                //   console.log('📑', segments)
                //   const filteredPersons = filterPersonsList(segments, personsList);
                //   console.log('🚩🚩', filteredPersons);
                //   setPersonsList(filteredPersons)
                // }}
              />
            </div>
          </form>


        </Content>
      </Modal>

      <Modal
        status={modal}
        changeStatus={setModal}
        title="Nuevo contacto"
        action={cleanInputs}
        width={"750px"}
      >
        <Content>
          {/* Creacion del formulario para el registro de cada usuario o persona */}
          <div className="justify-content-center">
            <div className="">
              <form onSubmit={handleSubmit(postPerson)}>
                <div className="justify-content-center scrollable-content">
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                        <label htmlFor="name">Nombre: </label>
                        <div className="su-control-group">
                          <input
                            className="form-control"
                            id="name"
                            name="name"
                            type="text"
                            {...register("name", {
                              required: {
                                value: true,
                                message: "El nombre es requerido.",
                              },
                            })}
                          />
                        </div>
                        {errors.name && <Alert>* {errors.name.message}</Alert>}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                        <label htmlFor="name">Apellido: </label>
                        <div className="su-control-group">
                          <input
                            className="form-control"
                            id="lastName"
                            name="lastName"
                            type="text"
                            {...register("lastName")}
                          />
                        </div>
                        {errors.lastName && <Alert>* {errors.lastName.message}</Alert>}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                        <label htmlFor="phone">Teléfono: </label>
                        <div className="su-control-group d-flex">
                          <Select
                            styles={isDark == 'true' ? customStylesDark : customStyles}
                            className={selectedClass && selectedClass.length === 1 ? "form-control select-countries one" : selectedClass.length === 2 ? "form-control select-countries two" : "form-control select-countries" }
                            placeholder="+123"
                            value={selectedCountrie}
                            onChange={handleChange}
                            options={Countries}
                            getOptionLabel={(option) => (
                              <div className="option countrie d-flex" style={{fontSize: "15px"}}>
                                <img src={option.flag} alt={option.name} width="20" style={{width: "27px", marginRight: "3px"}} />(+{option.callingCodes}) {option.name} 
                              </div>
                            )}
                            getOptionValue={(option) => option.name}
                          />
                          <input
                            className="form-control"
                            style={{width: "65%"}}
                            id="phone"
                            name="phone"
                            type="number"
                            min={0}
                            {...register("phone", {
                              required: {
                                value: true,
                                message: "El teléfono es requerido.",
                              },
                              pattern: {
                                value: /^[0-9]{7,11}$/i,
                                message: "El formato no es correcto.",
                              },
                              minLength: {
                                value: 7,
                                message: "Mínimo 7 dígitos.",
                              },
                              maxLength: {
                                value: 11,
                                message: "Máximo 11 dígitos.",
                              },
                            })}
                          />
                        </div>
                        {errors.phone && <Alert>* {errors.phone.message}</Alert>}
                        {errorCountrie && <Alert>* El código es requerido.</Alert>}
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                        <label htmlFor="email">Correo: </label>
                        <div className="su-control-group">
                          <input
                            className="form-control"
                            id="email"
                            name="email"
                            type="text"
                            {...register("email", {
                              // required: {
                              //   value: true,
                              //   message: "El Email es requerido.",
                              // },
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "El formato no es correcto",
                              },
                            })}
                          />
                        </div>
                        {errors.email && <Alert>* {errors.email.message}</Alert>}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                        <label htmlFor="phone">Etiquetas: </label>
                        <div id={selectedTags.length > 0 ? "isSelect" : "noSelect" } className="su-control-group d-flex select-all select-tags" style={selectedTags.length > 0 ? {height:"auto"} : {height:"36px"}}>
                          <Select
                            styles={isDark == 'true' ? customStylesDark : customStyles}
                            className="form-control basic-multi-select"
                            isMulti
                            placeholder="Seleccione..."
                            name="tags"
                            onChange={handleChange2}
                            options={listTags}
                            classNamePrefix="select"
                            noOptionsMessage={customNoOptionsMessage}
                            getOptionLabel={(option) => (
                              <div className="option d-flex" style={{fontSize: "15px"}}>
                                {option.title} 
                              </div>
                            )}
                            getOptionValue={(option) => option.title}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                        <label htmlFor="name">Responsable: </label>
                        <div className="su-control-group d-flex select-all" style={{height:"36px"}}>
                        <Select
                            styles={isDark == 'true' ? customStylesDark : customStyles}
                            className="form-control basic-multi-select"
                            placeholder="Seleccione..."
                            // value={selectedAdviser}
                            onChange={handleChange3}
                            options={listAdvisers}
                            getOptionLabel={(option) => (
                              <div className="option d-flex" style={{fontSize: "15px"}}>
                                {option.name}
                              </div>
                            )}
                            getOptionValue={(option) => option.name}
                          />
                        </div>
                      </div>
                    </div>

                    {fieldsList && fieldsList.map((field, k) => (
                      <div key={k} className="col-md-6">
                        <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                          <label htmlFor="phone">{field.name}: </label>
                          <div className="su-control-group">
                            {field.type == "textarea" ? (
                              <textarea
                                name={field.name}
                                className="form-control"
                                id={field.name}
                                {...register(field.name)}
                              ></textarea>
                            ) : (
                              <input
                                className="form-control"
                                id={field.name}
                                name={field.name}
                                type={field.type}
                                {...register(field.name)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row justify-content-end mb-2">
                  <a href="javascript:;" className="btn btn-lg btn-default mr-1" onClick={() => setModal(false)}>
                    Volver
                  </a>
                  <input className="btn btn-lg btn-info" value="Guardar" name="submit" id="submit" type="submit"
                    onClick={() => {
                      if (selectedCountrie) {
                        setErrorCountrie(false)
                      } else {
                        setErrorCountrie(true)
                      }
                    }}
                  />
                </div>
              </form>
            </div>
          </div>
        </Content>
      </Modal>

      <Modal
        status={editPerson}
        changeStatus={setEditPerson}
        title="Editar contacto"
        action={cleanInputs}
        width={"750px"}
      >
        <Content>
          {/* Creacion del formulario para el registro de cada usuario o persona */}
          <div className="justify-content-center">
          <div className="">
            <form onSubmit={handleSubmit(putPerson)}>
              <div className="justify-content-center scrollable-content">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable" >
                      <label htmlFor="name">Nombre:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        {...register("name", {
                          required: {
                            value: true,
                            message: "El nombre es requerido.",
                          },
                        })}
                      />
                      {errors.name && <Alert>* {errors.name.message}</Alert>}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                      <label htmlFor="name">Apellido:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        id="lastName"
                        {...register("lastName")}
                      />
                      {errors.lastName && <Alert>* {errors.lastName.message}</Alert>}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                      <label htmlFor="phone">Teléfono:</label>
                      <div className="su-control-group d-flex">
                        <Select
                          styles={isDark == 'true' ? customStylesDark : customStyles}
                          name="callingCode"
                          className={selectedClass2 && selectedClass2.length === 1 ? "form-control select-countries one-2" : selectedClass2.length === 2 ? "form-control select-countries two" : "form-control select-countries" }
                          placeholder="+123"
                          value={selectedCountrie}
                          onChange={handleChange}
                          options={Countries}
                          getOptionLabel={(option) => (
                            <div className="option d-flex" style={{fontSize: "15px"}}>
                              <img src={option.flag} alt={option.name} width="20" style={{width: "27px", marginRight: "3px"}} />(+{option.callingCodes}) {option.name} 
                            </div>
                          )}
                          getOptionValue={(option) => option.name}
                        />
                        <input
                          type="number"
                          className="form-control"
                          style={{width: "65%"}}
                          name="phone"
                          id="phone"
                          min={0}
                          {...register("phone", {
                            required: {
                              value: true,
                              message: "El teléfono es requerido.",
                            },
                            pattern: {
                              value: /^[0-9]{7,11}$/i,
                              message: "El formato no es correcto.",
                            },
                            minLength: {
                              value: 7,
                              message: "Mínimo 7 dígitos.",
                            },
                            maxLength: {
                              value: 11,
                              message: "Máximo 11 dígitos.",
                            },
                          })}
                        />
                      </div>
                      {errors.phone && <Alert>* {errors.phone.message}</Alert>}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                      <label htmlFor="email">Correo:</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        {...register("email", {
                          // required: {
                          //   value: true,
                          //   message: "El Email es requerido.",
                          // },
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "El formato no es correcto",
                          },
                        })}
                      />
                      {errors.email && <Alert>* {errors.email.message}</Alert>}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                      <label htmlFor="phone">Etiquetas: </label>
                      <div id={tagsContact.length > 0 ? "isSelect" : "noSelect" } className="su-control-group d-flex select-all select-tags" style={tagsContact.length > 0 ? {height:"auto"} : {height:"36px"}}>
                        <Select
                        styles={isDark == 'true' ? customStylesDark : customStyles}
                          isMulti
                          name="tags"
                          className="form-control basic-multi-select"
                          placeholder="Seleccione..."
                          // defaultValue={tagsContact}
                          value={tagsContact}
                          onChange={handleChange2}
                          options={listTags}
                          classNamePrefix="select"
                          noOptionsMessage={customNoOptionsMessage}
                          getOptionLabel={(option) => (
                            <div className="option d-flex" style={{fontSize: "15px"}}>
                              {option.title} 
                            </div>
                          )}
                          getOptionValue={(option) => option.title}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                      <label htmlFor="name">Responsable: </label>
                      <div className="su-control-group d-flex select-all" style={{height:"36px"}}>
                      <Select
                      styles={isDark == 'true' ? customStylesDark : customStyles}
                          className="form-control basic-multi-select"
                          placeholder="Seleccione..."
                          value={selectedAgent}
                          onChange={handleChange3}
                          options={listAdvisers}
                          getOptionLabel={(option) => (
                            <div className="option d-flex" style={{fontSize: "15px"}}>
                              {option.name}
                            </div>
                          )}
                          getOptionValue={(option) => option.name}
                        />
                      </div>
                    </div>
                  </div>
                  
                  {/* --------------------Listamos los campos nuevos creados-------------------- */}
                  {fieldsList && fieldsList.map((field, k) => (
                      <div key={k} className="col-md-6">
                        <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                          <label htmlFor="phone">{field.name}</label>
                          {field.type == "textarea" ? (
                            <textarea
                              name={field.name}
                              className="form-control"
                              id={field.name}
                              {...register(field.name)}
                            ></textarea>
                          ) : (
                            <input
                              type={field.type}
                              className="form-control"
                              name={field.name}
                              id={field.name}
                              {...register(field.name)}
                            />
                          )}
                        </div>
                      </div>
                  ))}
                  
                </div>
              </div>
              <div className="row justify-content-end mb-2">
                <a
                  href="javascript:;"
                  className="btn btn-lg btn-default mr-1"
                  onClick={() => {
                    reset({ name: "", email: "", phone: "" });
                    setEditPerson(false);
                  }}
                >
                  Volver
                </a>
                <input
                  className="btn btn-lg btn-info"
                  value="Guardar"
                  name="submit"
                  id="submit"
                  type="submit"
                />
              </div>
            </form>
          </div>
        </div> 
        </Content>
      </Modal>

      <Modal
        status={importPersons}
        changeStatus={setImportPersons}
        title="Subir/Importar contactos"
        action={() => null}
      >
        <Content>
          <div className="mb-3">
            <h5>
              Descargue{" "}
              <span className="border-0 pointer bg-transparent" style={{color: "#5bc0de"}} onClick={downloadTable}>aquí</span>
              {" "}la plantilla en formato Excel.
            </h5>
          </div>
          <InputContent>
            <form>
              {excelFile ? (
                <label htmlFor="file">
                  <BsFillCheckCircleFill style={{ color: "#5cb85c" }} className="mr-1"/>
                  Archivo cargado
                </label>
              ) : (
                <label htmlFor="file">
                  <BsFileEarmarkExcelFill className="mr-1"/>
                  Selecciona un archivo
                </label>
              )}
              <input
                type="file"
                id="file"
                multiple
                className="col-2-sm btn my-auto mb-1 "
                onChange={uploadFile}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </form>
          </InputContent>

          <div className="row justify-content-end col-12 mt-4">
            <a
              href="javascript:;"
              className="btn btn-lg btn-default mr-1"
              onClick={() => {
                setImportPersons(false);
              }}
            >
              Cerrar
            </a>
            <button
              onClick={() => {
                sendFile();
              }}
              className="btn btn-success btn-sm"
            >
              Cargar archivo
            </button>
          </div>
        </Content>
      </Modal>

      <Modal
        status={listsModal}
        changeStatus={setListsModal}
        title={"Agregar a lista"}
        action={() => reset()}
      >
        <form className="form-inline justify-content-center mb-2 modal-addList">
          <div className="su-control mt-1">
            <input
              type="text"
              className="form-control"
              placeholder="Búsqueda"
              value={searchModal}
              onChange={(e) => {
                setSearchModal(e.target.value);
                searchLists(e.target.value);
              }}
            />
            <button
              className="btn btn-info"
              type="button"
              id="buttonSearchProductBack"
              style={{ height: "34px" }}
            >
              <BsSearch />
            </button>
          </div>
        </form>
        {errors.dataSegmentCheck && (
          <p className="text-danger f-14 f-w-900">
            * {errors.dataSegmentCheck.message}
          </p>
        )}
        <form onSubmit={(e) => {
            e.preventDefault();
            addUserToList();
          }}
        >
          <ModalContent className="list-users">
            {validationModal && (
              <div className="text-center mt-3">
                <div className="empty">
                  <FaListUl style={{ fontSize: "55px" }}/>
                  <h1 style={{ fontSize: "30px" }}>No tienes listas</h1>
                  <input
                    type="button"
                    className="btn btn-default"
                    value="Agregar Nueva"
                    style={{ fontFamily: "sans-serif" }}
                    onClick={() => {
                      navigate("/home/lists");
                    }}
                  />
                </div>
              </div>
            )}
            <ul className="o-auto d-flex flex-column justify-content-center align-items-center">
              {formatLists && formatLists.map((list) => (
                  <li key={list._id} className="bg-light b-primary m-t-5 p-3 row w-90 align-items-center" id="li-lists">
                    <span className="col-8">{list.name}</span>
                    <input
                      type="checkbox"
                      value={parseBoolean(list.inList)}
                      className="cmn-toggle cmn-toggle-round-flat"
                      name={list.name}
                      id={list.name}
                      defaultChecked={parseBoolean(list.inList)}
                      onChange={(e) => {
                        handlePersonCheck(e, list)
                      }}
                    />
                    <label className="ml-4" htmlFor={list.name}></label>
                  </li>
                ))}
            </ul>
          </ModalContent>
          <div className="row justify-content-end mt-3 pt-3">
            <div className="col-sm-6 right">
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => setListsModal(false)}
              >
                Volver
              </a>
              {validationModal ? (
                <input
                  className="btn btn-lg btn-info"
                  value="Añadir"
                  type="submit"
                  disabled
                />
              ) : (
                <input
                  className="btn btn-lg btn-info"
                  value="Añadir"
                  type="submit"
                />
              )}
            </div>
          </div>
        </form>
      </Modal>
    </Fragment>
  );
};

export default Persons;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  img {
    width: 100%;
    vertical-align: top;
    border-radius: 3px;
  }

  input {
    padding: 5px 10px;
    border-radius: 0px;
    font-size: 17px;
  }

  h5 button {
    color: #5bc0de;
  }

  h5 button:hover {
    color: #28a1c5;
  }
`;

const Alert = styled.span`
  color: red;
  display: block;
  width: 250px;
`;

const InputContent = styled.div`
  color: #6d7d88;
  background-color: #fdfefe;
  border: 1px solid #dae2e4;
  -moz-box-shadow: 2px 2px 0px 0px #dae2e4;
  -webkit-box-shadow: 2px 2px 0px 0px #dae2e4;
  box-shadow: 2px 2px 0px 0px #dae2e4;

  // background-color: #7c7c7c;
  transition: all 0.3s ease;
  border-radius: 12px;

  input[type="file"] {
    display: none;
    color: white;
  }

  label {
    // color: white;
    height: 60px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    margin: auto;
    font-size: 20px;
    cursor: pointer;
  }

  &:hover {
    color: #6d7d88;
    background-color: #e0e7e8;
    border-color: #b7c6ca;
    -moz-box-shadow: 2px 2px 0px 0px #b7c6ca;
    -webkit-box-shadow: 2px 2px 0px 0px #b7c6ca;
    box-shadow: 2px 2px 0px 0px #b7c6ca;
  }
`;

const ModalContent = styled.div`
  overflow: auto;
  height: 335px;

  .checkbox {
    padding: 25px;
    width: 25px;
    height: 25px;
  }
`;

const SelectTags = styled.select`
  border: none;
  background-color: transparent;
  height: 40px;
  font-size: 15px;
  color: #495057;
  cursor: pointer;
  text-align: center;

  option:hover {
    background-color: transparent;
  }
`
