import React, { useState, useEffect, useCallback, useRef } from "react";
import Header from "../layout/Header";
import Spinner from "../plugins/Spinner";
import Modal from "../layout/Modal";
import Pagination from "../plugins/Pagination";
import dayjs from "dayjs";
import ReactFlow, {
  Background,
  BackgroundVariant,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  ReactFlowProvider,
  reconnectEdge,
  MarkerType,
  MiniMap,
  Controls 
} from 'reactflow';
import 'reactflow/dist/style.css';
import usePage from "../../hooks/usePage";
import useAPI from "../../hooks/useAPI";
import Swal from "sweetalert2";
import { upload_s3 } from './UploaderS3';
import Picker from "emoji-picker-react";
import Select from "react-select";
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json'; 
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-github_dark';
import { RichTreeView, TreeItem } from '@mui/x-tree-view';
import { Dialog, DialogTitle } from '@mui/material';
import { styled } from '@mui/system';

import { BsSearch, BsPencilFill, BsTrash3, BsFillCameraVideoFill, BsFillFileEarmarkTextFill, BsDatabaseFillAdd } from "react-icons/bs";
import { FaEllipsisVertical } from "react-icons/fa6";
import { FaAngleLeft, FaRobot, FaImages, FaHeadphones, FaBrain, FaCheckCircle } from "react-icons/fa";
import { FiMessageSquare } from "react-icons/fi";
import { AiOutlineSelect } from "react-icons/ai";
import { LuTags, LuListTree } from "react-icons/lu";
import { MdOutlineSupportAgent } from "react-icons/md";
import { HiOutlineSave } from "react-icons/hi";
import { TiFlowMerge } from "react-icons/ti";
import { BiSolidError, BiTransfer } from "react-icons/bi";
import { IoIosArrowDropdown, IoIosArrowDropup, IoMdAdd } from "react-icons/io";
import { CiSettings } from "react-icons/ci";

import BasicAnswer from "./BotsContent/NodeTypes/BasicAnswer";
import BottomNode from "./BotsContent/NodeTypes/BottomNode";
import TransferAgent from "./BotsContent/NodeTypes/TransferAgent";
import Tags from "./BotsContent/NodeTypes/Tags";
import AssitantAI from "./BotsContent/NodeTypes/AssitantAI";
import SaveAnswer from "./BotsContent/NodeTypes/SaveAnswer";
import FlowsWhatsApp from "./BotsContent/NodeTypes/FlowsWhatsApp";
import QueryApi from "./BotsContent/NodeTypes/QueryApi";
import BottomNodeApi from "./BotsContent/NodeTypes/BottomNodeApi";

const initialNodes = [
  {
    id: '1-basicAnswer',
    type: 'basicAnswer',
    data: { 
      title: 'Saludo Inicial',
      first: true,
      bot_response: "¡Hola! ¿Cómo puedo ayudarte hoy?",
      user_response: null,
      options: [
        {
          idNode: "1-1-basicAnswer",
          label: 'Conversar con agente',
          id: 'Conversar con agente',
          name: 'agente',
          position: { x: 15, y: 105 }
        },
        {
          idNode: "1-2-basicAnswer",
          label: 'Continuar con Bot',
          id: 'Continuar con Bot',
          name: 'Bot',
          position: { x: 15, y: 125 }
        },
      ],
      file: {},
      saved: false,
      variables: null,
      color: '#00A884'
    },
    position: { x: 250, y: 5 }
  },
  {
    id: '1-1-basicAnswer',
    type: 'bottomNode',
    data: { 
      label: 'Conversar con agente',
      id: 'Conversar con agente',
      name: 'agente',
      color: '#00A884'
    },
    position: { x: 15, y: 105 },
    selectable: false,
    parentId: '1-basicAnswer',
    extent: 'parent'
  },
  {
    id: '1-2-basicAnswer',
    type: 'bottomNode',
    data: { 
      label: 'Continuar con Bot',
      id: 'Continuar con Bot',
      name: 'Bot',
      color: '#00A884'
    },
    position: { x: 15, y: 125 },
    selectable: false,
    parentId: '1-basicAnswer',
    extent: 'parent'
  },
];

const nodeTypes = { 
  basicAnswer: BasicAnswer, 
  bottomNode: BottomNode, 
  transferAgent: TransferAgent,
  tags: Tags,
  assistantAI: AssitantAI,
  saveAnswer: SaveAnswer,
  flowsWhatsApp: FlowsWhatsApp,
  queryApi: QueryApi,
  bottomNodeApi: BottomNodeApi
};

const nodeTypesColors = {
  basicAnswer: '#00A884', 
  bottomNode: '#00A884', 
  transferAgent: '#F56F89',
  tags: '#5BC0DE',
  assistantAI: '#A7ADA7',
  saveAnswer: '#D89C7E',
  flowsWhatsApp: '#B85C7C',
  queryApi: '#7ECCD8',
  bottomNodeApi: '#7ECCD8'
}

const getId = () => {
  return makeRandomId(10);
};
const makeRandomId = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const companyScheduleOptions = [
  {
    label: "24 Horas",
    value: "24h"
  },
  {
    label: "Horario comercial",
    value: "companySchedule"
  }
]

const Flow = () => {

  //# Usos
  const { optGET, optPOST, optPUT, optDELETE, urlAPI_2, urlAPI_1 } = useAPI(); 
  const [isDark, setIsDark] = useState(false);

  //# Estados de plugins (Modales, Spinner...)
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("list");
  const [modalCreate, setModalCreate] = useState(false);
  const [modalHeaders, setModalHeaders] = useState(false);
  const [modalRequestParams, setModalRequestParams] = useState(false);
  const [modalResponseParams, setModalResponseParams] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [requestResponseModal, setRequestResponseModal] = useState(false);
  const [search, setSearch] = useState("");
  const [selectHeaders, setSelectHeaders] = useState(false);
  const [selectRequest, setSelectRequest] = useState(false);
  const [selectResponse, setSelectResponse] = useState(false);
  const [selectTimeInactivity, setSelectTimeInactivity] = useState(false);
  const [selectCompanySchedule, setSelectCompanySchedule] = useState(false);
  const [configModal, setConfigModal] = useState(false);
  const [variablesModal, setVariablesModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [typeInput, setTypeInput] = useState("");

  //# Estados del Modulo
  const [listBots, setListBots] = useState([]);
  const [validation, setValidation] = useState(false);
  const { page, setPage, byPage, resetPage } = usePage();
  const [nameBot, setNameBot] = useState("")
  const [descriptionBot, setDescriptionBot] = useState("")
  const [listAreas, setListAreas] = useState([]);
  const [listAdvisers, setListAdvisers] = useState([]);
  const [listTags, setListTags] = useState([]);
  const [listAssistants, setListAssistants] = useState([]);
  const [correspondence, setCorrespondence] = useState([]);
  const [listRequestParams, setListRequestParams] = useState([]);
  const [flows, setFlows] = useState([]);
  const [listInactivity, setListInactivity] = useState([]);

  // FLUJO
  const { screenToFlowPosition } = useReactFlow();
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const reconnectDone = useRef(true);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedChildNode, setSelectedChildNode] = useState(null);
  const [typeTransfer, setTypeTransfer] = useState('areas');
  const [showPicker, setShowPicker] = useState(false);
  const [headerParams, setHeaderParams] = useState({});
  const [requestParams, setRequestParams] = useState({});
  const [responseParams, setResponseParams] = useState({});
  const [requestResponse, setRequestResponse] = useState({});
  const [configBot, setConfigBot] = useState({});
  const [validateMessageConfig, setValidateMessageConfig] = useState(false);
  const [editFlow, setEditFlow] = useState(null)

  // #--> FLUJO
  const onConnect = useCallback(
    (params) => {

      let color = ""  
      if(params.source.includes('basicAnswer')) {
        color = nodeTypesColors['basicAnswer']
      }
      if(params.source.includes('bottomNode')) {
        color = nodeTypesColors['bottomNode']
      }
      if(params.source.includes('transferAgent')) {
        color = nodeTypesColors['transferAgent']
      }
      if(params.source.includes('tags')) {
        color = nodeTypesColors['tags']
      }
      if(params.source.includes('assistantAI')) {
        color = nodeTypesColors['assistantAI']
      }
      if(params.source.includes('saveAnswer')) {
        color = nodeTypesColors['saveAnswer']
      }
      if(params.source.includes('flowsWhatsApp')) {
        color = nodeTypesColors['flowsWhatsApp']
      }
      if(params.source.includes('queryApi')) {
        color = nodeTypesColors['queryApi']
      }
      if(params.source.includes('bottomNodeApi')) {
        color = nodeTypesColors['bottomNodeApi']
      }

      const newEdge = {
        ...params,
        type: 'smoothstep',
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 15,
          height: 15,
          color: color,
        },
        style: {
          strokeWidth: 2,
          stroke: color,
        },
        zIndex: 100
      }
      setEdges((eds) => addEdge(newEdge, eds))
    },
    [],
  );
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const type = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      let newNode
      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      if(type == 'BasicAnswer') {
        
        newNode = {
          id: getId() + "-basicAnswer",
          type: 'basicAnswer',
          data: { 
            title: 'Respues Básica',
            bot_response: "",
            user_response: null,
            options: [],
            file: {},
            variables: null,
            saved: false,
            onRemove: (value) => removeNode(value)
          },
          position,
        };
      }
      if(type == 'TransferAgent') {
        
        newNode = {
          id: getId() + "-transferAgent",
          type: 'transferAgent',
          data: { 
            title: 'Transferir Agente/Area',
            first: false,
            bot_response: "",
            user_response: null,
            agent_area: null,
            variables: null,
            saved: false,
            onRemove: (value) => removeNode(value)
          },
          position,
        };
      }
      if(type == 'Tags') {
        
        newNode = {
          id: getId() + "-tags",
          type: 'tags',
          data: { 
            title: 'Etiquetar',
            first: false,
            user_response: null,
            tags: null,
            variables: null,
            saved: false,
            onRemove: (value) => removeNode(value)
          },
          position,
        };
      }
      if(type == 'AssitantAI') {
        
        newNode = {
          id: getId() + "-assistantAI",
          type: 'assistantAI',
          data: { 
            title: 'Asistente IA',
            user_response: null,
            assitant: null,
            variables: null,
            saved: false,
            onRemove: (value) => removeNode(value)
          },
          position,
        };
      }
      if(type == 'SaveAnswer') {
        
        newNode = {
          id: getId() + "-saveAnswer",
          type: 'saveAnswer',
          data: { 
            title: 'Guardar Respuesta',
            variable: null,
            variables: null,
            saved: false,
            onRemove: (value) => removeNode(value)
          },
          position,
        };
      }
      if(type == 'FlowsWhatsApp') {
        
        newNode = {
          id: getId() + "-flowsWhatsApp",
          type: 'flowsWhatsApp',
          data: { 
            title: 'Flujo de WhatsApp',
            user_response: null,
            flow: null,
            variables: null,
            saved: false,
            onRemove: (value) => removeNode(value)
          },
          position,
        };
      }
      const id_node_api = getId() + "-queryApi"
      if(type == 'QueryApi') {
        
        newNode = {
          id: id_node_api,
          type: 'queryApi',
          data: { 
            title: 'Consulta API',
            user_response: null,
            succes: 'Exito',
            failed: 'Fallo',
            headers: [],
            requestParams: [],
            responseParams: [],
            variables: null,
            saved: false,
            onRemove: (value) => removeNode(value)
          },
          position,
        };
      }

      setNodes((nds) => nds.concat(newNode));

      if(type == 'QueryApi') {
        addNodeChildApi(
          {
            id: id_node_api,
            position: position
          }
        )
      }
    },
    [screenToFlowPosition],
  );
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };
  const addNodeChildBasicAnswer = (item) => {
    const newNode = {
      id: item.idNode,
      type: 'bottomNode',
      data: { 
        label: item.label,
        id: item.label,
        name: 'Title',
      },
      position: { x: 15, y: item.position.y },
      selectable: false,
      zIndex: 100,
      parentId: selectedNode.id,
      extent: 'parent'
    };

    setNodes((nds) => nds.concat(newNode));
  };
  const addNodeChildApi = (parent) => {
    let data = [
      {
        label: "Exito",
        id: "succes",
        height: 140
      },
      {
        label: "Fallo",
        id: "failed",
        height: 170
      },
    ]

    for(let i = 0; i < 2; i++) {
      const newNode = {
        id: parent.id+'-'+(i),
        type: 'bottomNodeApi',
        data: { 
          label: data[i].label,
          id: data[i].id,
          name: 'Title',
        },
        position: { x: 15, y: data[i].height },
        parentId: parent.id,
        extent: 'parent' + '-bottomNodeApi'
      };

      setNodes((nds) => nds.concat(newNode));
    }
  };
  const onReconnectStart = useCallback(() => {
    reconnectDone.current = false;
  }, []);
  const onReconnect = useCallback((oldEdge, newConnection) => {
    reconnectDone.current = true;
    setEdges((els) => reconnectEdge(oldEdge, newConnection, els));
  }, []);
  const onReconnectEnd = useCallback((_, edge) => {
    if (!reconnectDone.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    reconnectDone.current = true;
  }, []);
  const handleNodeClick = (event, node) => {
    if(!node.parentId){
      setSelectedNode(node);
    }
  };
  const changeValueBtns = (value, type) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedChildNode.id) {
          return {
            ...node,
            data: {
              label: value,
              id: type == "basic" ? value : node.data.id,
              name: 'title'
            },
          };
        }

        return node;
      }),
    );
  };
  const onEmojiClick = (event, emojiObject) => {
    setSelectedNode((prevInputValue) => ({
      ...prevInputValue,
      data: {
        ...prevInputValue.data,
        bot_response: prevInputValue.data.bot_response + emojiObject.emoji
      }
    }))
  };
  const removeNode = (id) => {
    const dropNode = nodes.filter(v => v.id !== id)
    const newNodes = dropNode.filter(v => v.parentId !== id)
    setNodes(newNodes)

    setTimeout(() => {
      setSelectedNode(null);
    }, 200);
  };

  // CRUD
  //#--> Listar
  const getBots = async () => {
    try {
      setLoading(true);
      const url = urlAPI_2 + 'chatBot';
      const response = await fetch(url, optGET());
      const result = await response.json();
      if(result.length <= 0) {
        console.log("no hay data");
        setListBots([]);
        setValidation(true);
      } else {
        console.log("si hay lista");
        setListBots(result);
        setValidation(false);
      }
      setLoading(false);
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  };
  //#--> Crear
  const postBot = async () => {
    try {
      setLoading(true);

      const dataBot = await buildJson("create")

      const url = urlAPI_2 + "chatBot";
      await fetch(url, optPOST(dataBot));
      setEditFlow(dataBot)
      getBots();
      
      setLoading(false);
      setSelectedNode(null);
      Swal.fire({
        title: "¡Bien!",
        text: "Bot guardado con éxito",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      console.log(error);
    }
  };
  //#--> Actualizar
  const putBot = async (data) => {
    try {
      setLoading(true);
      
      const dataBot = await buildJson("update")

      if(dataBot.flows.length){
        data.flows = dataBot.flows
        data.nodes = dataBot.nodes
        data.edges = dataBot.edges
      }

      const url = urlAPI_2 + `chatBot/${data._id}`;
      await fetch(url, optPUT(data));
      setEditFlow(data)
      getBots();

      setLoading(false);
      setSelectedNode(null);
      Swal.fire({
        title: "¡Bien!",
        text: "Bot Actualizado con éxito",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      console.log(error);
    }
  };
  //#--> Eliminar
  const deleteBot = async (id) => {
    try {
      Swal.fire({
        title: "¿Estas seguro que deseas eliminar este bot?",
        text: "No podrás recuperarlo.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const url = urlAPI_2 + `chatBot/${id}`;
          const response = await fetch(url, optDELETE());
          const result = await response.json();

          //#--> Actualización de la tabla
          getBots()
          setLoading(false);

          //#--> Confirmación de la eliminación
          Swal.fire({
            title: "¡Bien!",
            text: "Bot Eliminado con éxito",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  // #--> Armar JSON
  const buildJson = async (type) => {

    const nodesArray = nodes.filter(v => !v.parentId)
    let flows = []
    for(let i in nodesArray) {
      const node = nodesArray[i]
      let newFlow = {};
      const flowsChild = await buildJsonChild(node.id)
      const findEdge = edges.find(v => v.source == node.id)

      let transitions = []
      if(findEdge) {
        transitions = [
          {
            next_flow_id: findEdge.target, 
            conditions: [],
          }
        ]
      }

      if(node.type == "basicAnswer"){
        newFlow = {
          id: node.id,
          name: node.data.title,
          triggers: [
            {
              type: 'message',
              pattern: node.data.first ? '*' : node.data.user_response,
            },
          ],
          actions: [
            {
              type: 'send_message',
              content: node.data.bot_response,
            },
          ],
          transitions: transitions,
          childs: flowsChild
        };
      }
      if(node.type == "transferAgent"){
        let type_transfer = 'transfer_agent'
        if(node.data?.agent_area?.type == 'area') {
          type_transfer = 'transfer_area'
        }
        newFlow = {
          id: node.id,
          name: node.data.title,
          triggers: [
            {
              type: 'message',
              pattern: node.data.user_response,
            },
          ],
          actions: [
            {
              type: 'send_message',
              content: node.data.bot_response,
            },
            {
              type: type_transfer,
              content: node.data?.agent_area?.type,
            }
          ],
          transitions: transitions,
          childs: flowsChild
        };
      }

      flows.push(newFlow)
    }

    let json = {
      name: nameBot,
      description: descriptionBot,
      enabled: false,
      inactivity: 15,
      inactivityMessage: null,
      companySchedule: "24h",
      flows: flows,
      nodes: nodes,
      edges: edges
    }

    if(type == 'create') {
      return json
    } else {
      return {
        flows: flows,
        nodes: nodes,
        edges: edges
      }
    }
  }
  // #--> Armar JSON Child
  const buildJsonChild = (parent) => {
    const childs = nodes.filter(v => v.parentId && v.parentId == parent)
    if(childs.length) {
      let flows = []
      for(let i in childs) {
        const node = childs[i]
        let newFlow = {}
        const findEdge = edges.find(v => v.source == node.id)

        let transitions = []
        if(findEdge) {
          transitions = [
            {
              next_flow_id: findEdge.target, 
              conditions: [],
            }
          ]
        }

        newFlow = {
          id: node.id,
          name: node.data.title,
          triggers: [
            {
              type: 'message',
              pattern: node.data.user_response,
            },
          ],
          actions: [],
          transitions: transitions,
        };

        flows.push(newFlow)
      }
      return flows
    } else {
      return []
    }
  }
  // #--> ResetData
  const reset = () => {
    setNodes([]);
    setEdges([]);
    setNameBot([]);
    setDescriptionBot([]);
    setHeaderParams({});
    setRequestParams({});
    setResponseParams({});
    setRequestResponse({});
    setConfigBot({});
    setShowPicker(false);
    setSelectHeaders(false);
    setSelectRequest(false);
    setSelectResponse(false);
    setSelectedNode(null);
    setSelectedChildNode(null);
  }
  // #--> BotIsActive
  const handleBotIsActive = async (e, bot) => {
    let enabled = e.target.checked
    let json = {
      ...bot,
      enabled: enabled
    }

    const validate = listBots.find(v => v.enabled == true) 
    if(validate && enabled == true) {
      setListBots([])
      await getBots()
      Swal.fire({
        title: "Atención!",
        text: "No se puede tener más de un Bot activo. Por favor desactiva el que tengas en uso para poder activar este.",
        icon: "info",
        confirmButtonText: "Aceptar",
      });
      return
    }

    putBot(json)
  }
  // #--> BotConfig
  const handleBotConfig = () => {
    
    
    if(!configBot?.inactivityMessage) {
      setValidateMessageConfig(true)
      return
    }

    let json = {
      ...bot,
      inactivity: configBot.inactivity.value,
      inactivityMessage: configBot.inactivityMessage.value,
      companySchedule: configBot.companySchedule
    }

    setConfigBot({})
    setConfigModal(false)
    setValidateMessageConfig(false)
    putBot(json)
  }

  // RESOURCES
  const getAreas = async () => {
    try {
      const url = urlAPI_2 + "getAreasByCompany";
      const response = await fetch(url, optGET());
      const result = await response.json();
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
        setListAreas([])
      }else{
        const opts = result.data.map(v => {
          return {
            label: v.description,
            value: v._id,
            type: "area"
          }
        })
        setListAreas(opts);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAgents = async () => {
    try {
      const url = urlAPI_2 + "usersbyArea";
      const response = await fetch(url, optGET());
      const result = await response.json();
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (result.message === "Tu petición no tiene cabecera de autorización") {
        Swal.fire({
          title: "¡Atención!",
          text: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales",
          icon: "info",
        });
        navigate("/");
      } else if (result.data.length <= 0) {
        setListAdvisers([]);
      } else {
        const opts = result.data.map(v => {
          return {
            label: v.name,
            value: v._id,
            type: "agent"
          }
        })
        setListAdvisers(opts);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getTags = async () => {
    try {
      const url = urlAPI_1 + `tags`;
      const response = await fetch(url, optGET());
      const result = await response.json();
      if(!result.data) {
        setListTags([]);
      } else {
        setListTags(result.data);
      }
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  };
  const getCorresp = async () => {
    try {
      const url = urlAPI_2 + "getDataEmail";
      const response = await fetch(url, optGET());
      const result = await response.json();
      setCorrespondence(result.data);
      if (result.message === "Tu petición no tiene cabecera de autorización") {
        Swal.fire({
          title: "¡Atención!",
          text: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales",
          icon: "info",
        });
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getFlows = async () => {
    try {
      const url = urlAPI_2 + "flows";
      const response = await fetch(url, optGET());
      const result = await response.json();
      if (result.type === "error") {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      }else if (result.message === 'Tu petición no tiene cabecera de autorización') {
        Swal.fire({
          title: "¡Atención!",
          text: 'Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales',
          icon: 'info',
        });
        navigate('/');
      }  else if (!result.data) {
        setFlows([]);
      } else {
        setFlows(result.data);
        setTableFlows(result.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAssistants = async () => {
    try {
      const url = urlAPI_2 + "assistants";
      const response = await fetch(url, optGET());
      const result = await response.json();
      if (result.type === "error") {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (!result.data) {
        setListAssistants([])
      } else {
        setListAssistants(result.data)
      }
    } catch (error) {
      console.log('error---->', error);
    }
  };
  const getOptionsInactivity = () => {
    let options = []
    for(let i = 1; i <= 15; i++) {
      options.push({
        label: i + ' min',
        value: i
      })
    }
    
    setListInactivity(options) 
  }

  // RESOURCES 
  const onFileInput = async (e, type_file) => {

    const conversionFunctions = {
      image: convertBase64,
      video: async (file) => URL.createObjectURL(file),
      document: convertDocumentBase64,
      audio: convertAudioBase64,
    };
    
    const conversionFunction = conversionFunctions[type_file];
    if (conversionFunction) {
      let file = e.target.files[0];
      const base64 = await conversionFunction(file);
      if (base64) {
        selectedNode.data.file['view'] = base64
        selectedNode.data.file['name'] = file.name
        const data_file = await uploadToS3(file)
        if(!data_file.error) {
          selectedNode.data.file['url'] = data_file.data.url
          selectedNode.data.file['type'] = data_file.data.type
        }
      }
    }
  };
  const convertBase64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };
  const convertDocumentBase64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };
  const convertAudioBase64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };
  const getSignedUrl = async (key_name, size) => {
    try {
      key_name = encodeURIComponent(key_name);
      const url = urlAPI_1 + `getSignedURL/${key_name}/${size}`;
      const response = await fetch(url, optGET());
      if(response.status === 200){
          let url_signed = await response.json();
          if(url_signed.error){
              return false;
          }
          return url_signed;
      }else{
          console.error('Error getting signed URL');
          return false;
      }
    } catch (error) {
      console.error('Error getting signed URL:', error);
      return false;
    }
  };
  const uploadToS3 = async (file) => {
    try {
      setLoading(true);
      const max_size = checkMaxSize(file);
      if (max_size) {
        setLoading(false);
        return {error: true, description: "tam_limit_error", data: {name: file.name, size: file.size, file: file, type: file.type}};
      }else {
        const signedUrl = await getSignedUrl(file.name, file.size);
        if(signedUrl){
          const url = await upload_s3(file, signedUrl);
          setLoading(false);
          return {error: false,  data: {url: url, name: file.name, size: file.size, file: file, type: file.type}};
        }
        setLoading(false);
        return {error: true, description: "signed_url_error", data: {name: file.name, size: file.size, file: file, type: file.type}};
      }
    } catch (error) {
      setLoading(false);
      return {error: true, description: "unknown_error", data: error, data: {name: file.name, size: file.size, file: file, type: file.type}};
    }
  };
  const checkMaxSize = (file) => {
    let max_size = false;
    if(file.type.includes("audio") && file.size > ((1024 * 1024) * 16)){
      max_size = true;
      Swal.fire({
        title: "¡Atención!",
        text: `El archivo ${file.name} supera el peso máximo permitido para audio: 16MB. Intente enviarlo como documento una vez finalizado el proceso de carga.`,
        icon: "info",
      });
    }
    if(file.type.includes("video") && file.size > ((1024 * 1024) * 16)){
      max_size = true;
      Swal.fire({
        title: "¡Atención!",
        text: `El archivo ${file.name} supera el peso máximo permitido para video: 16MB. Intente enviarlo como documento una vez finalizado el proceso de carga.`,
        icon: "info",
      });
    }
    if(file.type.includes("image") && file.size > ((1024 * 1024) * 5)){
      max_size = true;
      Swal.fire({
        title: "¡Atención!",
        text: `El archivo ${file.name} supera el peso máximo permitido para imagen: 5MB. Intente enviarlo como documento una vez finalizado el proceso de carga.`,
        icon: "info",
      });
    }
    if(file.type.includes("document") && file.size > ((1024 * 1024) * 100)){
      max_size = true;
      Swal.fire({
        title: "¡Atención!",
        text: `El archivo ${file.name} supera el peso máximo permitido para documentos: 100MB. Intente enviarlo como documento una vez finalizado el proceso de carga.`,
        icon: "info",
      });
    }
    return max_size;
  }
  const getIsDark = () => {
    const dark = localStorage.getItem("THEME");
    setIsDark(dark);
  }
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'lightblue' : '#fff',
      color: state.isSelected ? '#333' : '#333',
      '&:hover': {
        backgroundColor: 'lightgray',
        color: '#333',
        cursor: 'pointer',
      },
    }),
  };
  const customStylesDark = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#455a64' : '#202c33',
      color: state.isSelected ? 'white' : 'white',
      '&:hover': {
        backgroundColor: '#374954',
        color: 'white',
        cursor: 'pointer',
      },
    }),
  };

  const onAditionalHeaders = () => {
    if (selectHeaders) {
      setSelectHeaders(false);
    } else {
      setSelectHeaders(true);
    }
  };
  const onAditionalRequest = () => {
    if (selectRequest) {
      setSelectRequest(false);
    } else {
      setSelectRequest(true);
    }
  };
  const onAditionalResponse = () => {
    if (selectResponse) {
      setSelectResponse(false);
    } else {
      setSelectResponse(true);
    }
  };
  const onAditionalTimeInactivity = () => {
    if (selectTimeInactivity) {
      setSelectTimeInactivity(false);
    } else {
      setSelectTimeInactivity(true);
    }
  };
  const onAditionalCompanySchedule = () => {
    if (selectCompanySchedule) {
      setSelectCompanySchedule(false);
    } else {
      setSelectCompanySchedule(true);
    }
  };

  const requestApiNode = async () => {

    const url = selectedNode.data.url_api;
    const options = {}
    if(selectedNode.data.protocol) {
      options.method = selectedNode.data.protocol
    }

    try {
      setLoading(true);
      const response = await fetch(url, options);
      const result = await response.json();
      if(result.length <= 0) {
        setLoading(false);
        Swal.fire({
          title: "Atención!",
          text: "Ocurrio un error al realizar la consulta, por favor valída los datos necearios para realizar la consulta",
          icon: "error",
          type: "error",
          confirmButtonText: "Aceptar",
        });
      } else {
        setLoading(false);
        setRequestResponse(result);
        setRequestResponseModal(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Atención!",
        text: "Ocurrio un error al realizar la consulta, por favor valída los datos necearios para realizar la consulta",
        icon: "error",
        type: "error",
        confirmButtonText: "Aceptar",
      });
    }

  }
  const convertToTree = (item, key) => {
    if (item === null || item === undefined) {
      return { id: key, label: key };
    }

    if (Array.isArray(item)) {
      const children = item.map((subItem, index) => convertToTree(subItem, `${key}[${index}]`));
      return { id: key, label: key, children };
    } else if (typeof item === "object") {
      const children = Object.keys(item).map((subKey) => convertToTree(item[subKey], `${key}.${subKey}`));
      return { id: key, label: key, children };
    } else {
      return { id: key, label: key };
    }
  };
  const handleItemValSelect = (event, ids) => {
    setResponseParams((prev) => ({
      ...prev,
      value: ids
    }))
  };
  const nodeColor = (node) => {
    if(node.id.includes('basicAnswer')) {
      return nodeTypesColors['basicAnswer']
    }
    if(node.id.includes('bottomNode')) {
      return nodeTypesColors['bottomNode']
    }
    if(node.id.includes('transferAgent')) {
      return nodeTypesColors['transferAgent']
    }
    if(node.id.includes('tags')) {
      return nodeTypesColors['tags']
    }
    if(node.id.includes('assistantAI')) {
      return nodeTypesColors['assistantAI']
    }
    if(node.id.includes('saveAnswer')) {
      return nodeTypesColors['saveAnswer']
    }
    if(node.id.includes('flowsWhatsApp')) {
      return nodeTypesColors['flowsWhatsApp']
    }
    if(node.id.includes('queryApi')) {
      return nodeTypesColors['queryApi']
    }
    if(node.id.includes('bottomNodeApi')) {
      return nodeTypesColors['bottomNodeApi']
    }
  }

  const handleClickModalVariables = (event, typeVal) => {
    setAnchorEl(event.currentTarget);
    setVariablesModal(true);
    setTypeInput(typeVal);
  };
  const handleItemValSelectTest = (event, ids) => {
    setSelectedNode((prevInputValue) => ({
      ...prevInputValue,
      data: {
        ...prevInputValue.data,
        [typeInput]: '{{' + ids + '}}'
      },
    }))
  };
  const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
    '& .MuiTreeItem-content': {
      backgroundColor: '#7ECCD8',
      color: '#fff',
      marginBottom: '8px',
    },
    '&:first-of-type .MuiTreeItem-iconContainer': {
      display: 'flex',
    },
    '& .MuiTreeItem-iconContainer': {
      display: 'none',
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
  }));

  useEffect(() => {
    getIsDark()
    getOptionsInactivity()

    const fetchData = async () => {
      try {
        await Promise.all([
          getAgents(),
          getBots(),
          getAreas(),
          getTags(),
          getCorresp(),
          getFlows(),
          getAssistants()
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  
  useEffect(() => {
    setNodes((nds) => 
      nds.map((node) => {
        if (selectedNode && node.id === selectedNode.id) {
          node.data = {
            ...selectedNode.data
          };
        }

        return node;
      })
    )
  }, [selectedNode])

  return (
    <>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}

      {mode === "list" && (
        <>
          <div className="m-auto container-fluid p-0 h-100">
            <div className="col-xs-9 heading">
              <div className="mt-2">
                <h1>Constructor de Bots</h1>
                <input
                  type="button"
                  className="btn btn-default"
                  value="Nuevo Bot"
                  onClick={() => {
                    setModalCreate(true);
                  }}
                />
              </div>

              <Header />
            </div>

            <header>
              <div style={{ float: "right" }}>
                <form className="form-inline">
                  <div className="su-control my-1">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Búsqueda"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        resetPage(1);
                      }}
                    />
                    <button
                      className="btn btn-info"
                      type="button"
                      id="buttonSearchProductBack"
                      style={{ height: "34px" }}
                    >
                      <BsSearch />
                    </button>
                  </div>
                </form>
              </div>
            </header>

            <div className="container-fluid mt-3 px-0" style={{ height:"75%", overflowY:"auto", overflowX:"none" }}>
              <table className="table table-striped table-campaigns">
                <thead style={{ position: "sticky", top: "-1px", zIndex: "99" }}>
                  <tr>
                    <th>Acciones</th>
                    <th>Activar/Desactivar</th>
                    <th>Nombre</th>
                    <th>Descripción</th>
                    <th>Estado</th>
                    <th>Creación</th>
                  </tr>
                </thead>
                <tbody>
                  {listBots.length > 0 && listBots.slice((page - 1) * byPage, (page - 1) * byPage + byPage).map((bot) => (
                    <tr key={bot._id}>
                      <td>
                        <div className="row justify-content-around">
                          <div className="dropdown">
                            <button
                              className="bg-transparent border-0"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <FaEllipsisVertical className="text-success f-24 pointer" />
                            </button>
                            <div
                              className="dropdown-menu p-2"
                              aria-labelledby="dropdownMenuButton"
                            > 
                              <button
                                onClick={() => {
                                  setMode("create")
                                  setNodes(bot.nodes)
                                  setEdges(bot.edges)
                                  setEditFlow(bot)
                                }}
                                className="text-primary border-0 bg-transparent d-block pointer mt-2"
                              >
                                <BsPencilFill style={{ fontSize: "18px" }}/>
                                <span className="text-dark ml-2 f-14">
                                  Editar
                                </span>
                              </button>
                              <button className="text-danger border-0 bg-transparent d-block pointer mt-2" onClick={() => deleteBot(bot._id)}>
                                <BsTrash3 style={{ fontSize: "18px" }}/>
                                <span className="text-dark ml-2 f-14">
                                  Eliminar
                                </span>
                              </button>
                              <button className="text-muted border-0 bg-transparent d-block pointer mt-2" onClick={() => {
                                setConfigBot({
                                  inactivity: listInactivity.find(v => v.value == bot.inactivity) || null,
                                  inactivityMessage: bot.inactivityMessage,
                                  companySchedule: companyScheduleOptions.find(v => v.value == bot.companySchedule) || null,
                                  bot: bot
                                })
                                setConfigModal(true)
                              }}>
                                <CiSettings style={{ fontSize: "25px" }}/>
                                <span className="text-dark ml-2 f-14">
                                  Configuración
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center align-items-center">
                          <input
                            type="checkbox"
                            value={bot.enabled}
                            className="cmn-toggle cmn-toggle-round-flat"
                            name={bot._id}
                            id={bot._id}
                            defaultChecked={bot.enabled}
                            onChange={(e) => {
                              handleBotIsActive(e, bot)
                            }}
                          />
                          <label className="m-0" htmlFor={bot._id}></label>
                        </div>
                      </td>
                      <td>
                        {bot?.name}
                      </td>
                      <td>
                        {
                          bot?.description.length >= 35 ? 
                            bot?.description.substr(0, 35) + "..."
                            : bot?.description
                        }
                      </td>
                      <td className="status-template">
                        <p>
                          <a
                            href="javascript:;"
                            className={
                              bot.enabled
                                ? "green"
                                : "red"
                            }
                          >
                            {bot.enabled ? 'ACTIVO' : 'INACTIVO'}
                          </a>
                        </p>
                      </td>
                      <td>
                        Creada el{" "}
                        {dayjs(bot.created)
                          .locale("es")
                          .format("DD MMMM YYYY")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {validation && (
                <div className="text-center mt-3">
                  <div className="empty">
                    <FaRobot style={{ fontSize: "55px" }}/>
                    <h1>No tienes Bots</h1>
                    <input
                      type="button"
                      className="btn btn-default"
                      value="Agregar Nuevo"
                      onClick={() => {
                        setModalCreate(true);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            <footer>
              <div className="row">
                <div className="col-md-8"></div>

                <div className="col-md-4">
                  {/* Creación de la paginación simple */}
                  <Pagination
                    totalData={listBots.length}
                    byPage={byPage}
                    setPage={setPage}
                  />
                </div>
              </div>
            </footer>
          </div>
        </>
      )}

      {mode === "create" && (
        <>
          <div className="row mx-auto container-fluid p-0 justify-content-between border-top-0 border-right-0 border-left-0 border-secondary mb-2" style={{height:"6%"}}>
            <div style={{ height: "45px", lineHeight: "95px" }}>
              <button
                type="button"
                className="btn btn-left col-1-sm-3 my-auto position-absolute d-flex"
                onClick={() => {
                  setMode("list");
                  setFlows([]);
                  reset();
                }}
              >
                <FaAngleLeft className="mr-1" /> Bots
              </button>
            </div>

            <Header />
          </div>
          <section className="module-create-user" style={{ width: "100%", height:"90%" }}>
            <header className="message" style={{ height:"10%" }}>
              <div className="row justify-content-between align-items-center">
                <div>
                  <button
                    type="button"
                    className="btn btn-left col-1-sm-3 d-flex align-items-center"
                    onClick={() => {
                      setMode("list");
                      setFlows([]);
                      reset();
                    }}
                  >
                    <FaAngleLeft className="mr-1" /> {nameBot}
                  </button>
                </div>
              </div>
            </header>
            <div style={{ height: '90%' }} className="reactflow-wrapper" ref={reactFlowWrapper}>
              <ReactFlow 
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onReconnect={onReconnect}
                onReconnectStart={onReconnectStart}
                onReconnectEnd={onReconnectEnd}
                onDrop={onDrop}
                onDragOver={onDragOver}
                nodeTypes={nodeTypes}
                onNodeClick={handleNodeClick}
                minZoom={0.2}
                maxZoom={2}
                fitView
              > 
                <Background color="#5BC0DE" variant={BackgroundVariant.Background}/>
                <MiniMap nodeStrokeWidth={3} pannable zoomable nodeColor={nodeColor}/>
                <Controls/>

                <div 
                  className="card ml-5 mt-4 border border-12 border-black" 
                  style={{width: '300px', maxHeight: "460px", zIndex: 100, boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
                    {selectedNode ?
                     (
                      <>
                        {/* Basic Anwser */}
                        {selectedNode.type == 'basicAnswer' && (
                          <>
                            <div className="d-flex align-items-center justify-content-between text-white" style={{height: "50px", padding: "10px", borderBottom: '1px solid #CED4DA', backgroundColor: '#00A884'}}>
                              <div className="d-flex align-items-center">
                                <FiMessageSquare className="mr-1" style={{fontSize: "24px"}}/>
                                <span style={{fontSize: "22px"}} >
                                    {selectedNode.data.title}
                                </span>
                              </div>
                              {!selectedNode.data.first && (
                                <button className="text-white border-0 bg-transparent d-block pointer mt-2" onClick={() => {
                                  const dropNode = nodes.filter(v => v.id !== selectedNode.id)
                                  const newNodes = dropNode.filter(v => v.parentId !== selectedNode.id)
                                  setNodes(newNodes)
                                  setSelectedNode(null)
                                }}>
                                  <BsTrash3 style={{ fontSize: "18px" }}/>
                                </button>
                              )}
                            </div>
                            <div id="content-list-chats" className="card-body" style={{overflowY: 'auto'}}>
                              <div>
                                <div className="col p-0">
                                  <label htmlFor="title" style={{color: '#6C757D', fontWeight: 200}}>Título: </label>
                                  <div>
                                    <input
                                      id="title"
                                      name="title"
                                      type="text"
                                      style={{width: "100%", height: "35px"}}
                                      value={selectedNode.data.title || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.title) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              title: 'Título base' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            title: e.target.value
                                          },
                                        }))
                                      }
                                    />
    
                                    {!selectedNode.data.title ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Título es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Título identificativo de la respuesta para el diagrama de flujo.
                                      </p>
                                    )}
                                  </div>
                                </div>
                                {!selectedNode.data.first && (
                                  <div className="col p-0 mt-4">
                                    <label htmlFor="user_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del cliente: </label>
                                    <div>
                                      <input
                                        id="user_response"
                                        name="user_response"
                                        type="text"
                                        style={{width: "100%", height: "35px"}}
                                        value={selectedNode.data.user_response || ""}
                                        onBlur={() => {
                                          if(!selectedNode.data.first && !selectedNode.data.user_response) {
                                            setSelectedNode((prevInputValue) => ({
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                user_response: 'Respuesta del cliente.' 
                                              },
                                            }))
                                          }
                                        }}
                                        onChange={(e) => 
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              user_response: e.target.value
                                            },
                                          }))
                                        }
                                      />
                                      {!selectedNode.data.user_response ? (
                                        <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                          Campo Respuesta del cliente es obligatorio
                                        </p>
                                      ): (
                                        <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                          Mensaje que envía el cliente para activar la respuesta.
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                )}
                                <div className="col p-0 mt-4">
                                  <label htmlFor="bot_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del Bot: </label>
                                  <div>
                                    <textarea
                                      id="bot_response"
                                      name="bot_response"
                                      placeholder="Escribe la respuesta del Bot"
                                      style={{width: "100%", height: "90px", borderRadius: "4px"}}
                                      value={selectedNode.data.bot_response || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.bot_response) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              bot_response: '¡Hola! ¿Cómo puedo ayudarte hoy?' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            bot_response: e.target.value
                                          },
                                        }))
                                      }
                                    />
                                    <div className="d-flex align-items-center">
                                      <div className="pt-2">
                                        <button 
                                          className="text-dark border-0 bg-transparent d-block pointer" 
                                          type="button"
                                          onClick={(event) => handleClickModalVariables(event, 'bot_response')}
                                        >
                                          <LuListTree style={{ fontSize: "18px" }}/>
                                        </button>
                                      </div>
                                      <div className="picker-container pt-4" style={{width: "100%"}}>
                                        <i
                                          className={
                                            showPicker
                                              ? "icon-smile emoji-icon active"
                                              : "icon-smile emoji-icon"
                                          }
                                          style={{ fontSize: "22px" }}
                                          onClick={() => setShowPicker((val) => !val)}
                                        />

                                        {showPicker && (
                                          <Picker
                                            pickerStyle={{
                                              width: "100%",
                                              top: "30px",
                                              position: "absolute",
                                              zIndex: "999",
                                            }}
                                            onEmojiClick={onEmojiClick}
                                            groupNames={{
                                              smileys_people: "Emoticones y personas",
                                              animals_nature: "Animales y naturaleza",
                                              food_drink: "Alimentos y bebidas",
                                              travel_places: "Viajes y lugares",
                                              activities: "Actividades",
                                              objects: "Objetos",
                                              symbols: "Símbolos",
                                              flags: "Banderas",
                                              recently_used: "Recientes",
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                    
                                    {!selectedNode.data.bot_response || selectedNode.data.bot_response.length > 1024 ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        {!selectedNode.data.bot_response ? 'Campo Respuesta del Bot es obligatorio' 
                                        : 'El campo Respuesta del Bot no debe ser mayor a 1024 caracteres'}
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Respuesta que se le envía al cliente.
                                      </p>
                                    )}
                                  </div>
                                  <div className="row px-3">
                                    <div 
                                      className="mr-2"
                                      style={{
                                        width: "120px",
                                        backgroundColor: '#fff',
                                        border: '1px solid #00A884',
                                        borderRadius: '4px',
                                        color: '#00A884',
                                        padding: '2px',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <input
                                        type="file"
                                        name="image"
                                        id="images"
                                        accept="image/gif,image/jpeg,image/jpg,image/png"
                                        style={{
                                          display: 'none',
                                        }}
                                        onChange={(e) => onFileInput(e, "image")}
                                      />
                                      <label htmlFor="images" title="Imagenes" className="pointer d-flex m-0 pt-1 pl-3">
                                        <FaImages className="mr-2 icon-picture" style={{ fontSize: "20px", color: "#00A884" }}/>
                                        Imagen
                                      </label>
                                    </div>
                                    <div 
                                      style={{
                                        width: "120px",
                                        backgroundColor: '#fff',
                                        border: '1px solid #00A884',
                                        borderRadius: '4px',
                                        color: '#00A884',
                                        padding: '2px',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <input
                                        type="file"
                                        name="videos"
                                        id="videos"
                                        accept="video/*"
                                        style={{ display: "none" }}
                                        onChange={(e) => onFileInput(e, "video")}
                                        multiple
                                      />
                                      <label htmlFor="videos" title="Videos" className="pointer d-flex m-0 pt-1 pl-3">
                                        <BsFillCameraVideoFill className="mr-2 icon-file-video" style={{ fontSize: "20px", color: "#00A884" }}/>
                                        Video
                                      </label>
                                    </div>
                                    <div 
                                      className="mr-2 mt-2"
                                      style={{
                                        width: "120px",
                                        backgroundColor: '#fff',
                                        border: '1px solid #00A884',
                                        borderRadius: '4px',
                                        color: '#00A884',
                                        padding: '2px',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <input
                                        type="file"
                                        name="document"
                                        id="documents"
                                        accept=".pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/plain, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        style={{ display: "none" }}
                                        onChange={(e) => onFileInput(e, "document")}
                                      />
                                      <label htmlFor="documents" title="Documentos" className="pointer d-flex m-0 pt-1 px-1">
                                        <BsFillFileEarmarkTextFill className="mr-1 icon-doc" style={{ fontSize: "18px", color: "#00A884" }}/>
                                        Documento
                                      </label>
                                    </div>
                                    <div 
                                      className="mt-2"
                                      style={{
                                        width: "120px",
                                        backgroundColor: '#fff',
                                        border: '1px solid #00A884',
                                        borderRadius: '4px',
                                        color: '#00A884',
                                        padding: '2px',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <input
                                        type="file"
                                        name="audio"
                                        id="audios"
                                        accept="audio/*"
                                        style={{ display: "none" }}
                                        onChange={(e) => onFileInput(e, "audio")}
                                        multiple
                                      />
                                      <label htmlFor="audios" title="Audios" className="pointer d-flex m-0 pt-1 pl-3">
                                        <FaHeadphones className="mr-2 icon-headphones" style={{ fontSize: "20px", color: "#00A884" }}/>
                                        Audio
                                      </label>
                                    </div>
                                    <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                      Multimedia que puedes cargar en la respuesta.
                                    </p>

                                    {Object.keys(selectedNode.data.file).length > 0 && (
                                      <button
                                        type="button"
                                        style={{
                                          backgroundColor: '#00A884',
                                          border: 0,
                                          borderRadius: '4px',
                                          color: '#fff',
                                          cursor: 'pointer',
                                          fontFamily: 'Helvetica, Arial, sans-serif',
                                          fontSize: '14px',
                                          fontWeight: 'bold',
                                          height: '30px',
                                          width: '100%'
                                        }}
                                        onClick={() => 
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              file: {}
                                            },
                                          }))
                                        }
                                      > 
                                        <BsTrash3 className="mr-1"/>
                                        Eliminar Multimedia
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className="col p-0 mt-2">
                                  <div>
                                    {selectedNode.data.options && selectedNode.data.options.map((option, index) => {
                                      return (
                                        <div className="col p-0 mt-2" key={index}>
                                          <label htmlFor={option.name} style={{color: '#6C757D', fontWeight: 200}}>Título: </label>
                                          <div>
                                            <div className="d-flex">
                                              <input
                                                id={option.name}
                                                name={option.name}
                                                type="text"
                                                style={{width: "100%", height: "35px"}}
                                                value={option.label || ""}
                                                onFocus={() => {
                                                  const node = nodes.find(v => v.id == option.idNode)
                                                  setSelectedChildNode(node)
                                                }}
                                                onChange={(e) => {
                                                  setSelectedNode((prevInputValue) => {
                                                    prevInputValue.data.options[index].label = e.target.value
                                                    prevInputValue.data.options[index].value = e.target.value
    
                                                    return {
                                                      ...prevInputValue,
                                                      data: {
                                                        ...prevInputValue.data,
                                                        options: prevInputValue.data.options,
                                                      }
                                                    }
                                                  })
                                                  changeValueBtns(e.target.value, 'basic')
                                                }}
                                              />
                                              <button 
                                                className="text-danger border-0 bg-transparent d-block pointer" 
                                                onClick={() => {
                                                  const newOptions = selectedNode.data.options.filter((v, i) => i !== index)
                                                  setSelectedNode((prevInputValue) => {
                                                    return {
                                                      ...prevInputValue,
                                                      data: {
                                                        ...prevInputValue.data,
                                                        options: newOptions,
                                                      }
                                                    }
                                                  })
                                                  const newNodes = nodes.filter(v => v.id !== option.idNode)
                                                  setNodes(newNodes)
                                                }}
                                              >
                                                <BsTrash3 style={{ fontSize: "18px" }}/>
                                              </button>
                                            </div>
                                            {!option.label || option.label.length > 20 ? (
                                              <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                                {!option.label? 'Campo para el botón es obligatorio' 
                                                : 'El campo para el botón no debe ser mayor a 20 caracteres'}
                                              </p>
                                            ): (
                                              <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                                Mensaje que envía el cliente para activar la respuesta.
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>

                                  <button
                                    type="button"
                                    style={{
                                      backgroundColor: '#00A884',
                                      border: 0,
                                      borderRadius: '4px',
                                      color: '#fff',
                                      cursor: 'pointer',
                                      fontFamily: 'Helvetica, Arial, sans-serif',
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                      height: '30px',
                                      width: '100%'
                                    }}
                                    onClick={() => {
                                      setSelectedNode((prevValue) => {
                                        const length = prevValue.data.options.length
                                        const posY = prevValue.data.options[length-1]?.position?.y + 20
                                        const item = {
                                          idNode: selectedNode.id.split('-')[0]+'-'+(length+1)+'-basicAnswer-bottomNode',
                                          label: 'Opción ' + (length +1),
                                          value: 'Opción ' + (length +1),
                                          name: 'titulo',
                                          position: { x: 15, y: !length ? 105 : posY}
                                        }
                                        prevValue.data.options.push(item)
                                        addNodeChildBasicAnswer(item)
                                        return {
                                          ...prevValue,
                                          data: {
                                            ...prevValue.data,
                                            options: prevValue.data.options,
                                          }
                                        }
                                      })
                                    }}
                                  > 
                                    <AiOutlineSelect className="mr-1"/>
                                    Añadir botón interactivo
                                  </button>
                                  <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                    Botones interactivos con respuestas que el usurio deberá escoger.
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex justify-content-center mt-4">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#00A884",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="mr-2"
                                  onClick={async() => {
                                    if(!selectedNode.data.title || 
                                      (!selectedNode.data.first && !selectedNode.data.user_response) ||
                                      !selectedNode.data.bot_response
                                    ) {
                                      return Swal.fire({
                                        title: "Atención!",
                                        text: "Se encuentran campos requeridos vacíos",
                                        icon: "warning",
                                        confirmButtonText: "Aceptar",
                                      });
                                    }

                                    if(selectedNode.data.saved) {
                                      await putBot(editFlow)
                                    } else {
                                      setSelectedNode((prevInputValue) => ({
                                        ...prevInputValue,
                                        data: {
                                          ...prevInputValue.data,
                                          saved: true
                                        },
                                      }))
                                      await postBot()
                                    }
                                  }}
                                >   
                                  Guardar
                                </button>

                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#00A884",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="ml-2"
                                  onClick={async() => {

                                    // if(!selectedNode.data.first && !selectedNode.data.saved) {
                                    //   Swal.fire({
                                    //     title: "¿Estas seguro que deseas cerrar sin guardar?",
                                    //     text: "Se eliminará el nodo.",
                                    //     icon: "warning",
                                    //     showCancelButton: true,
                                    //     confirmButtonColor: "#3085d6",
                                    //     cancelButtonColor: "#d33",
                                    //     confirmButtonText: "Seguro",
                                    //     cancelButtonText: "Cancelar",
                                    //   }).then(async (result) => {
                                    //     if (result.isConfirmed) {
                                    //       removeNode(selectedNode.id)
                                    //     }
                                    //   });
                                    // }
                                    setTimeout(() => {
                                      setSelectedNode(null);
                                    }, 200);
                                  }}
                                >   
                                  Cerrar
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                        {/* Transfer Agent */}
                        {selectedNode.type == 'transferAgent' && (
                          <>
                            <div className="d-flex align-items-center justify-content-between text-white" style={{height: "50px", padding: "10px", borderBottom: '1px solid #CED4DA', backgroundColor: '#F56F89'}}>
                              <div className="d-flex align-items-center">
                                <FiMessageSquare className="mr-1" style={{fontSize: "24px"}}/>
                                <span style={{fontSize: "22px", whiteSpace: 'nowrap'}} >
                                    {selectedNode.data.title}
                                </span>
                              </div>
                              <button className="text-white border-0 bg-transparent d-block pointer mt-2" onClick={() => {
                                  const dropNode = nodes.filter(v => v.id !== selectedNode.id)
                                  const newNodes = dropNode.filter(v => v.parentId !== selectedNode.id)
                                  setNodes(newNodes)
                                  setSelectedNode(null)
                                }}>
                                  <BsTrash3 style={{ fontSize: "18px" }}/>
                              </button>
                            </div>
                            <div id="content-list-chats" className="card-body" style={{overflowY: 'auto'}}>
                              <div>
                                <div className="col p-0">
                                  <label htmlFor="title" style={{color: '#6C757D', fontWeight: 200}}>Título: </label>
                                  <div>
                                    <input
                                      id="title"
                                      name="title"
                                      type="text"
                                      style={{width: "100%", height: "35px"}}
                                      value={selectedNode.data.title || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.title) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              title: 'Título base' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            title: e.target.value
                                          },
                                        }))
                                      }
                                    />
    
                                    {!selectedNode.data.title ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Título es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Título identificativo de la respuesta para el diagrama de flujo.
                                      </p>
                                    )}
                                    
                                  </div>
                                </div>
                                {!selectedNode.data.first && (
                                  <div className="col p-0 mt-4">
                                    <label htmlFor="user_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del cliente: </label>
                                    <div>
                                      <input
                                        id="user_response"
                                        name="user_response"
                                        type="text"
                                        style={{width: "100%", height: "35px"}}
                                        value={selectedNode.data.user_response || ""}
                                        onBlur={() => {
                                          if(!selectedNode.data.first && !selectedNode.data.user_response) {
                                            setSelectedNode((prevInputValue) => ({
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                user_response: 'Respuesta del cliente.' 
                                              },
                                            }))
                                          }
                                        }}
                                        onChange={(e) => 
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              user_response: e.target.value
                                            },
                                          }))
                                        }
                                      />
                                      {!selectedNode.data.user_response ? (
                                        <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                          Campo Respuesta del cliente es obligatorio
                                        </p>
                                      ): (
                                        <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                          Mensaje que envía el cliente para activar la respuesta.
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                )}
                                <div className="col p-0 mt-4">
                                  <label htmlFor="bot_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del Bot: </label>
                                  <div>
                                    <textarea
                                      id="bot_response"
                                      name="bot_response"
                                      placeholder="Escribe la respuesta del Bot"
                                      style={{width: "100%", height: "90px", borderRadius: "4px"}}
                                      value={selectedNode.data.bot_response || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.bot_response) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              bot_response: '¡Hola! ¿Cómo puedo ayudarte hoy?' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            bot_response: e.target.value
                                          },
                                        }))
                                      }
                                    />
                                    <div className="picker-container pt-4" style={{width: "100%"}}>
                                      <i
                                        className={
                                          showPicker
                                            ? "icon-smile emoji-icon active"
                                            : "icon-smile emoji-icon"
                                        }
                                        style={{ fontSize: "22px" }}
                                        onClick={() => setShowPicker((val) => !val)}
                                      />

                                      {showPicker && (
                                        <Picker
                                          pickerStyle={{
                                            width: "100%",
                                            top: "30px",
                                            position: "absolute",
                                            zIndex: "999",
                                          }}
                                          onEmojiClick={onEmojiClick}
                                          groupNames={{
                                            smileys_people: "Emoticones y personas",
                                            animals_nature: "Animales y naturaleza",
                                            food_drink: "Alimentos y bebidas",
                                            travel_places: "Viajes y lugares",
                                            activities: "Actividades",
                                            objects: "Objetos",
                                            symbols: "Símbolos",
                                            flags: "Banderas",
                                            recently_used: "Recientes",
                                          }}
                                        />
                                      )}
                                    </div>

                                    {!selectedNode.data.bot_response || selectedNode.data.bot_response.length > 1024 ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        {!selectedNode.data.bot_response ? 'Campo Respuesta del Bot es obligatorio' 
                                        : 'El campo Respuesta del Bot no debe ser mayor a 1024 caracteres'}
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Respuesta que se le envía al cliente.
                                      </p>
                                    )}
                                  </div>

                                  <div className="p-1" style={{backgroundColor: 'rgba(245, 111, 137, 0.3)', borderRadius: '4px'}}>
                                    <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                      <span style={{fontSize: 12, fontWeight: 600}}>Importante:</span> Ten en cuenta que las transferencias a agentes o áreas se realizarán
                                      únicamente durante el horario comercial. El cliente recibirá el mensaje previamente
                                      configurado en dicho apartado.
                                    </p>
                                  </div>
                                </div>

                                <div className="col p-0 mt-4">
                                  <div className="d-flex mb-2">
                                    <div className="d-flex pointer mr-1">
                                      <input
                                        style={{accentColor: '#5BC0DE'}}
                                        type="radio"
                                        value="areas"
                                        className="pointer"
                                        checked={typeTransfer === "areas"}
                                        onChange={(e) => {
                                          setTypeTransfer(e.target.value)
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              agent_area: null
                                            },
                                          }))
                                        }}
                                      />
                                      <label
                                        className="d-flex mb-0 ml-1 pointer d-flex align-items-center"
                                        style={{ padding: "5px 2px 5px" }}
                                      >
                                        Areas
                                      </label>
                                    </div>
                                    <div className="d-flex pointer mr-1">
                                      <input
                                        style={{accentColor: '#5BC0DE'}}
                                        type="radio"
                                        value="agents"
                                        className="pointer"
                                        checked={typeTransfer === "agents"}
                                        onChange={(e) => {
                                          setTypeTransfer(e.target.value)
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              agent_area: null
                                            },
                                          }))
                                        }}
                                      />
                                      <label
                                        className="d-flex mb-0 ml-1 pointer d-flex align-items-center"
                                        style={{ padding: "5px 2px 5px" }}
                                      >
                                        Agentes
                                      </label>
                                    </div>
                                    <div className="d-flex pointer">
                                      <input
                                        style={{accentColor: '#5BC0DE'}}
                                        type="radio"
                                        value="automatic"
                                        className="pointer"
                                        checked={typeTransfer === "automatic"}
                                        onChange={(e) => setTypeTransfer(e.target.value)}
                                        disabled
                                      />
                                      <label
                                        className="d-flex mb-0 ml-1 pointer d-flex align-items-center"
                                        style={{ padding: "5px 2px 5px" }}
                                      >
                                        Automático
                                      </label>
                                    </div>
                                  </div>

                                  {typeTransfer == 'areas' && (
                                    <div>
                                      <label>Areas: </label>
                                      <div className="su-control-group d-flex">
                                        <Select
                                          styles={isDark == 'true' ? customStylesDark : customStyles}
                                          className="form-control basic-multi-select"
                                          placeholder="Seleccione..."
                                          name="areas"
                                          value={selectedNode.data.agent_area}
                                          onChange={(area) => {
                                            setSelectedNode((prevInputValue) => ({
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                agent_area: area
                                              },
                                            }))
                                          }}
                                          options={listAreas}
                                          classNamePrefix="select"
                                          noOptionsMessage={() => "Sin opciones"}
                                          getOptionLabel={(option) => (
                                            <div className="option d-flex" style={{fontSize: "15px"}}>
                                              {option.label} 
                                            </div>
                                          )}
                                          getOptionValue={(option) => option.value}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {typeTransfer == 'agents' && (
                                    <div>
                                      <label>Agentes: </label>
                                      <div className="su-control-group d-flex">
                                        <Select
                                          styles={isDark == 'true' ? customStylesDark : customStyles}
                                          className="form-control basic-multi-select"
                                          placeholder="Seleccione..."
                                          name="agents"
                                          value={selectedNode.data.agent_area}
                                          onChange={(agent) => {
                                            setSelectedNode((prevInputValue) => ({
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                agent_area: agent
                                              },
                                            }))
                                          }}
                                          options={listAdvisers}
                                          classNamePrefix="select"
                                          noOptionsMessage={() => "Sin opciones"}
                                          getOptionLabel={(option) => (
                                            <div className="option d-flex" style={{fontSize: "15px"}}>
                                              {option.label} 
                                            </div>
                                          )}
                                          getOptionValue={(option) => option.value}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {typeTransfer == 'automatic' && (
                                    <div>
                                      <label>Automático: </label>
                                      <div>
                                        <select
                                          style={{border: 'solid 1px #000'}}
                                          name="automaticId"
                                          id="automaticId"
                                          className="form-control"
                                          value={selectedNode.data.agent_area || ""}
                                          onChange={(e) => 
                                            setSelectedNode((prevInputValue) => ({
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                agent_area: e.target.value
                                              },
                                            }))
                                          }
                                        >
                                          <option value="areas">
                                            Area
                                          </option>
                                          <option value="agents">
                                            Agente
                                          </option>
                                        </select>
                                      </div>
                                      <div className="su-control-group d-flex">
                                        <Select
                                          styles={isDark == 'true' ? customStylesDark : customStyles}
                                          className="form-control basic-multi-select"
                                          placeholder="Seleccione..."
                                          name="automatics"
                                          onChange={(auto) => {
                                            setSelectedNode((prevInputValue) => ({
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                agent_area: auto.id
                                              },
                                            }))
                                          }}
                                          options={[
                                            {
                                              label: "Area",
                                              id: "areas"
                                            },
                                            {
                                              label: "Agente",
                                              id: "agents"
                                            }
                                          ]}
                                          classNamePrefix="select"
                                          noOptionsMessage={() => "Sin opciones"}
                                          getOptionLabel={(option) => (
                                            <div className="option d-flex" style={{fontSize: "15px"}}>
                                              {option.label} 
                                            </div>
                                          )}
                                          getOptionValue={(option) => option.label}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="d-flex justify-content-center mt-4">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#F56F89",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="mr-2"
                                  onClick={async() => {
                                    if(!selectedNode.data.title || 
                                      (!selectedNode.data.first && !selectedNode.data.user_response) ||
                                      !selectedNode.data.bot_response
                                    ) {
                                      return Swal.fire({
                                        title: "Atención!",
                                        text: "Se encuentran campos requeridos vacíos",
                                        icon: "warning",
                                        confirmButtonText: "Aceptar",
                                      });
                                    }

                                    if(selectedNode.data.saved) {
                                      await putBot(editFlow)
                                    } else {
                                      setSelectedNode((prevInputValue) => ({
                                        ...prevInputValue,
                                        data: {
                                          ...prevInputValue.data,
                                          saved: true
                                        },
                                      }))
                                      await postBot()
                                    }
                                  }}
                                >   
                                  Guardar
                                </button>

                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#F56F89",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="ml-2"
                                  onClick={async() => {

                                    // if(!selectedNode.data.first && !selectedNode.data.saved) {
                                    //   Swal.fire({
                                    //     title: "¿Estas seguro que deseas cerrar sin guardar?",
                                    //     text: "Se eliminará el nodo.",
                                    //     icon: "warning",
                                    //     showCancelButton: true,
                                    //     confirmButtonColor: "#3085d6",
                                    //     cancelButtonColor: "#d33",
                                    //     confirmButtonText: "Seguro",
                                    //     cancelButtonText: "Cancelar",
                                    //   }).then(async (result) => {
                                    //     if (result.isConfirmed) {
                                    //       removeNode(selectedNode.id)
                                    //     }
                                    //   });
                                    // }
                                    setTimeout(() => {
                                      setSelectedNode(null);
                                    }, 200);
                                  }}
                                >   
                                  Cerrar
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                        {/* Tags */}
                        {selectedNode.type == 'tags' && (
                          <>
                            <div className="d-flex align-items-center justify-content-between text-white" style={{height: "50px", padding: "10px", borderBottom: '1px solid #CED4DA', backgroundColor: '#5BC0DE'}}>
                              <div className="d-flex align-items-center">
                                <LuTags className="mr-1" style={{fontSize: "24px"}}/>
                                <span style={{fontSize: "22px", whiteSpace: 'nowrap'}} >
                                    {selectedNode.data.title}
                                </span>
                              </div>
                              <button className="text-white border-0 bg-transparent d-block pointer mt-2" onClick={() => {
                                  const dropNode = nodes.filter(v => v.id !== selectedNode.id)
                                  const newNodes = dropNode.filter(v => v.parentId !== selectedNode.id)
                                  setNodes(newNodes)
                                  setSelectedNode(null)
                                }}>
                                  <BsTrash3 style={{ fontSize: "18px" }}/>
                              </button>
                            </div>
                            <div id="content-list-chats" className="card-body" style={{overflowY: 'auto'}}>
                              <div>
                                <div className="col p-0">
                                  <label htmlFor="title" style={{color: '#6C757D', fontWeight: 200}}>Título: </label>
                                  <div>
                                    <input
                                      id="title"
                                      name="title"
                                      type="text"
                                      style={{width: "100%", height: "35px"}}
                                      value={selectedNode.data.title || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.title) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              title: 'Título base' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            title: e.target.value
                                          },
                                        }))
                                      }
                                    />
    
                                    {!selectedNode.data.title ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Título es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Título identificativo de la respuesta para el diagrama de flujo.
                                      </p>
                                    )}
                                    
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label htmlFor="user_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del cliente: </label>
                                  <div>
                                    <input
                                      id="user_response"
                                      name="user_response"
                                      type="text"
                                      style={{width: "100%", height: "35px"}}
                                      value={selectedNode.data.user_response || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.first && !selectedNode.data.user_response) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              user_response: 'Respuesta del cliente.' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            user_response: e.target.value
                                          },
                                        }))
                                      }
                                    />
                                    {!selectedNode.data.user_response ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Respuesta del cliente es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Mensaje que envía el cliente para activar la respuesta.
                                      </p>
                                    )}
                                  </div>

                                  <div className="p-1" style={{backgroundColor: 'rgba(91, 192, 222, 0.3)', borderRadius: '4px'}}>
                                    <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                      <span style={{fontSize: 12, fontWeight: 600}}>Importante:</span> Con el signo * el etiquetado funcionaná 
                                      para cuaquier respuesta que de el cliente.
                                    </p>
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label>Etiquetas: </label>
                                  <div className="su-control-group d-flex">
                                    <Select
                                      styles={isDark == 'true' ? customStylesDark : customStyles}
                                      className="form-control basic-multi-select"
                                      isMulti
                                      placeholder="Seleccione..."
                                      name="tags"
                                      onChange={(tag) => {
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            tags: tag
                                          },
                                        }))
                                      }}
                                      options={listTags}
                                      classNamePrefix="select"
                                      noOptionsMessage={() => "Sin opciones"}
                                      getOptionLabel={(option) => (
                                        <div className="option d-flex" style={{fontSize: "15px"}}>
                                          {option.title} 
                                        </div>
                                      )}
                                      getOptionValue={(option) => option.title}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-center mt-4">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#5BC0DE",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="mr-2"
                                  onClick={async() => {
                                    if(!selectedNode.data.title || 
                                      (!selectedNode.data.first && !selectedNode.data.user_response) ||
                                      !selectedNode.data.bot_response
                                    ) {
                                      return Swal.fire({
                                        title: "Atención!",
                                        text: "Se encuentran campos requeridos vacíos",
                                        icon: "warning",
                                        confirmButtonText: "Aceptar",
                                      });
                                    }

                                    if(selectedNode.data.saved) {
                                      await putBot(editFlow)
                                    } else {
                                      setSelectedNode((prevInputValue) => ({
                                        ...prevInputValue,
                                        data: {
                                          ...prevInputValue.data,
                                          saved: true
                                        },
                                      }))
                                      await postBot()
                                    }
                                  }}
                                >   
                                  Guardar
                                </button>

                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#5BC0DE",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="ml-2"
                                  onClick={async() => {

                                    // if(!selectedNode.data.first && !selectedNode.data.saved) {
                                    //   Swal.fire({
                                    //     title: "¿Estas seguro que deseas cerrar sin guardar?",
                                    //     text: "Se eliminará el nodo.",
                                    //     icon: "warning",
                                    //     showCancelButton: true,
                                    //     confirmButtonColor: "#3085d6",
                                    //     cancelButtonColor: "#d33",
                                    //     confirmButtonText: "Seguro",
                                    //     cancelButtonText: "Cancelar",
                                    //   }).then(async (result) => {
                                    //     if (result.isConfirmed) {
                                    //       removeNode(selectedNode.id)
                                    //     }
                                    //   });
                                    // }
                                    setTimeout(() => {
                                      setSelectedNode(null);
                                    }, 200);
                                  }}
                                >   
                                  Cerrar
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                        {/* Assistant AI */}
                        {selectedNode.type == 'assistantAI' && (
                          <>
                            <div className="d-flex align-items-center justify-content-between text-white" style={{height: "50px", padding: "10px", borderBottom: '1px solid #CED4DA', backgroundColor: '#A7ADA7'}}>
                              <div className="d-flex align-items-center">
                                <FaBrain className="mr-1" style={{fontSize: "24px"}}/>
                                <span style={{fontSize: "22px", whiteSpace: 'nowrap'}} >
                                    {selectedNode.data.title}
                                </span>
                              </div>
                              <button className="text-white border-0 bg-transparent d-block pointer mt-2" onClick={() => {
                                  const dropNode = nodes.filter(v => v.id !== selectedNode.id)
                                  const newNodes = dropNode.filter(v => v.parentId !== selectedNode.id)
                                  setNodes(newNodes)
                                  setSelectedNode(null)
                                }}>
                                  <BsTrash3 style={{ fontSize: "18px" }}/>
                              </button>
                            </div>
                            <div id="content-list-chats" className="card-body" style={{overflowY: 'auto'}}>
                              <div>
                                <div className="col p-0">
                                  <label htmlFor="title" style={{color: '#6C757D', fontWeight: 200}}>Título: </label>
                                  <div>
                                    <input
                                      id="title"
                                      name="title"
                                      type="text"
                                      style={{width: "100%", height: "35px"}}
                                      value={selectedNode.data.title || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.title) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              title: 'Título base' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            title: e.target.value
                                          },
                                        }))
                                      }
                                    />
    
                                    {!selectedNode.data.title ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Título es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Título identificativo de la respuesta para el diagrama de flujo.
                                      </p>
                                    )}
                                    
                                  </div>
                                </div>
                                <div className="col p-0">
                                  <label htmlFor="assistant" style={{color: '#6C757D', fontWeight: 200}}>Asistente: </label>
                                  <div className="su-control-group d-flex">
                                    <Select
                                      styles={isDark == 'true' ? customStylesDark : customStyles}
                                      className="form-control basic-multi-select"
                                      placeholder="Seleccione..."
                                      name="assistants"
                                      onChange={(assis) => {
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            assistant: assis._id
                                          },
                                        }))
                                      }}
                                      options={listAssistants}
                                      classNamePrefix="select"
                                      noOptionsMessage={() => "Sin opciones"}
                                      getOptionLabel={(option) => (
                                        <div className="option d-flex" style={{fontSize: "15px"}}>
                                          {option.name} 
                                        </div>
                                      )}
                                      getOptionValue={(option) => option.name}
                                    />
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label htmlFor="user_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del cliente: </label>
                                  <div>
                                    <input
                                      id="user_response"
                                      name="user_response"
                                      type="text"
                                      style={{width: "100%", height: "35px"}}
                                      value={selectedNode.data.user_response || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.first && !selectedNode.data.user_response) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              user_response: 'Respuesta del cliente.' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            user_response: e.target.value
                                          },
                                        }))
                                      }
                                    />
                                    {!selectedNode.data.user_response ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Respuesta del cliente es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Mensaje que envía el cliente para activar la respuesta.
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label htmlFor="bot_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del Bot: </label>
                                  <div>
                                    <textarea
                                      id="bot_response"
                                      name="bot_response"
                                      placeholder="Escribe la respuesta del Bot"
                                      style={{width: "100%", height: "90px", borderRadius: "4px"}}
                                      value={selectedNode.data.bot_response || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.bot_response) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              bot_response: '¡Hola! ¿Cómo puedo ayudarte hoy?' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            bot_response: e.target.value
                                          },
                                        }))
                                      }
                                    />
                                    <div className="picker-container pt-4" style={{width: "100%"}}>
                                      <i
                                        className={
                                          showPicker
                                            ? "icon-smile emoji-icon active"
                                            : "icon-smile emoji-icon"
                                        }
                                        style={{ fontSize: "22px" }}
                                        onClick={() => setShowPicker((val) => !val)}
                                      />

                                      {showPicker && (
                                        <Picker
                                          pickerStyle={{
                                            width: "100%",
                                            top: "30px",
                                            position: "absolute",
                                            zIndex: "999",
                                          }}
                                          onEmojiClick={onEmojiClick}
                                          groupNames={{
                                            smileys_people: "Emoticones y personas",
                                            animals_nature: "Animales y naturaleza",
                                            food_drink: "Alimentos y bebidas",
                                            travel_places: "Viajes y lugares",
                                            activities: "Actividades",
                                            objects: "Objetos",
                                            symbols: "Símbolos",
                                            flags: "Banderas",
                                            recently_used: "Recientes",
                                          }}
                                        />
                                      )}
                                    </div>
                                    
                                    {!selectedNode.data.bot_response || selectedNode.data.bot_response.length > 1024 ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        {!selectedNode.data.bot_response ? 'Campo Respuesta del Bot es obligatorio' 
                                        : 'El campo Respuesta del Bot no debe ser mayor a 1024 caracteres'}
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Respuesta que se le envía al cliente.
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-center mt-4">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#A7ADA7",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="mr-2"
                                  onClick={async() => {
                                    if(!selectedNode.data.title || 
                                      (!selectedNode.data.first && !selectedNode.data.user_response) ||
                                      !selectedNode.data.bot_response
                                    ) {
                                      return Swal.fire({
                                        title: "Atención!",
                                        text: "Se encuentran campos requeridos vacíos",
                                        icon: "warning",
                                        confirmButtonText: "Aceptar",
                                      });
                                    }

                                    if(selectedNode.data.saved) {
                                      await putBot(editFlow)
                                    } else {
                                      setSelectedNode((prevInputValue) => ({
                                        ...prevInputValue,
                                        data: {
                                          ...prevInputValue.data,
                                          saved: true
                                        },
                                      }))
                                      await postBot()
                                    }
                                  }}
                                >   
                                  Guardar
                                </button>

                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#A7ADA7",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="ml-2"
                                  onClick={async() => {

                                    // if(!selectedNode.data.first && !selectedNode.data.saved) {
                                    //   Swal.fire({
                                    //     title: "¿Estas seguro que deseas cerrar sin guardar?",
                                    //     text: "Se eliminará el nodo.",
                                    //     icon: "warning",
                                    //     showCancelButton: true,
                                    //     confirmButtonColor: "#3085d6",
                                    //     cancelButtonColor: "#d33",
                                    //     confirmButtonText: "Seguro",
                                    //     cancelButtonText: "Cancelar",
                                    //   }).then(async (result) => {
                                    //     if (result.isConfirmed) {
                                    //       removeNode(selectedNode.id)
                                    //     }
                                    //   });
                                    // }
                                    setSelectedNode(null);
                                  }}
                                >   
                                  Cerrar
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                        {/* SaveAnswer */}
                        {selectedNode.type == 'saveAnswer' && (
                          <>
                            <div className="d-flex align-items-center justify-content-between text-white" style={{height: "50px", padding: "10px", borderBottom: '1px solid #CED4DA', backgroundColor: '#D89C7E'}}>
                              <div className="d-flex align-items-center">
                                <LuTags className="mr-1" style={{fontSize: "24px"}}/>
                                <span style={{fontSize: "22px", whiteSpace: 'nowrap'}} >
                                    {selectedNode.data.title}
                                </span>
                              </div>
                              <button className="text-white border-0 bg-transparent d-block pointer mt-2" onClick={() => {
                                  const dropNode = nodes.filter(v => v.id !== selectedNode.id)
                                  const newNodes = dropNode.filter(v => v.parentId !== selectedNode.id)
                                  setNodes(newNodes)
                                  setSelectedNode(null)
                                }}>
                                  <BsTrash3 style={{ fontSize: "18px" }}/>
                              </button>
                            </div>
                            <div id="content-list-chats" className="card-body" style={{overflowY: 'auto'}}>
                              <div>
                                <div className="col p-0">
                                  <label htmlFor="title" style={{color: '#6C757D', fontWeight: 200}}>Título: </label>
                                  <div>
                                    <input
                                      id="title"
                                      name="title"
                                      type="text"
                                      style={{width: "100%", height: "35px"}}
                                      value={selectedNode.data.title || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.title) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              title: 'Título base' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            title: e.target.value
                                          },
                                        }))
                                      }
                                    />
    
                                    {!selectedNode.data.title ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Título es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Título identificativo de la respuesta para el diagrama de flujo.
                                      </p>
                                    )}
                                    
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label>Variable: </label>
                                  <div className="su-control-group d-flex">
                                    <Select
                                      styles={isDark == 'true' ? customStylesDark : customStyles}
                                      className="form-control basic-multi-select"
                                      placeholder="Seleccione..."
                                      name="variables"
                                      onChange={(val) => {
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            variable: val.field
                                          },
                                        }))
                                      }}
                                      options={correspondence}
                                      classNamePrefix="select"
                                      noOptionsMessage={() => "Sin opciones"}
                                      getOptionLabel={(option) => (
                                        <div className="option d-flex" style={{fontSize: "15px"}}>
                                          {option.fieldName} 
                                        </div>
                                      )}
                                      getOptionValue={(option) => option.fieldName}
                                    />
                                  </div>

                                  <p style={{fontSize: 12, color: '#6C757D', fontWeight: 800}} className="pt-2">
                                      Ejecuta la acción y salta al siguiente nodo.
                                    </p>
                                </div>
                              </div>
                              <div className="d-flex justify-content-center mt-4">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#D89C7E",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="mr-2"
                                  onClick={async() => {
                                    if(!selectedNode.data.title || 
                                      (!selectedNode.data.first && !selectedNode.data.user_response) ||
                                      !selectedNode.data.bot_response
                                    ) {
                                      return Swal.fire({
                                        title: "Atención!",
                                        text: "Se encuentran campos requeridos vacíos",
                                        icon: "warning",
                                        confirmButtonText: "Aceptar",
                                      });
                                    }

                                    if(selectedNode.data.saved) {
                                      await putBot(editFlow)
                                    } else {
                                      setSelectedNode((prevInputValue) => ({
                                        ...prevInputValue,
                                        data: {
                                          ...prevInputValue.data,
                                          saved: true
                                        },
                                      }))
                                      await postBot()
                                    }
                                  }}
                                >   
                                  Guardar
                                </button>

                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#D89C7E",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="ml-2"
                                  onClick={async() => {

                                    // if(!selectedNode.data.first && !selectedNode.data.saved) {
                                    //   Swal.fire({
                                    //     title: "¿Estas seguro que deseas cerrar sin guardar?",
                                    //     text: "Se eliminará el nodo.",
                                    //     icon: "warning",
                                    //     showCancelButton: true,
                                    //     confirmButtonColor: "#3085d6",
                                    //     cancelButtonColor: "#d33",
                                    //     confirmButtonText: "Seguro",
                                    //     cancelButtonText: "Cancelar",
                                    //   }).then(async (result) => {
                                    //     if (result.isConfirmed) {
                                    //       removeNode(selectedNode.id)
                                    //     }
                                    //   });
                                    // }
                                    setSelectedNode(null);
                                  }}
                                >   
                                  Cerrar
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                        {/* FlowsWhatsApp */}
                        {selectedNode.type == 'flowsWhatsApp' && (
                          <>
                            <div className="d-flex align-items-center justify-content-between text-white" style={{height: "50px", padding: "10px", borderBottom: '1px solid #CED4DA', backgroundColor: '#B85C7C'}}>
                              <div className="d-flex align-items-center">
                                <TiFlowMerge className="mr-1" style={{fontSize: "24px"}}/>
                                <span style={{fontSize: "22px", whiteSpace: 'nowrap'}} >
                                    {selectedNode.data.title}
                                </span>
                              </div>
                              <button className="text-white border-0 bg-transparent d-block pointer mt-2" onClick={() => {
                                  const dropNode = nodes.filter(v => v.id !== selectedNode.id)
                                  const newNodes = dropNode.filter(v => v.parentId !== selectedNode.id)
                                  setNodes(newNodes)
                                  setSelectedNode(null)
                                }}>
                                  <BsTrash3 style={{ fontSize: "18px" }}/>
                              </button>
                            </div>
                            <div id="content-list-chats" className="card-body" style={{overflowY: 'auto'}}>
                              <div>
                                <div className="col p-0">
                                  <label htmlFor="title" style={{color: '#6C757D', fontWeight: 200}}>Título: </label>
                                  <div>
                                    <input
                                      id="title"
                                      name="title"
                                      type="text"
                                      style={{width: "100%", height: "35px"}}
                                      value={selectedNode.data.title || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.title) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              title: 'Título base' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            title: e.target.value
                                          },
                                        }))
                                      }
                                    />
    
                                    {!selectedNode.data.title ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Título es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Título identificativo de la respuesta para el diagrama de flujo.
                                      </p>
                                    )}
                                    
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label htmlFor="user_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del cliente: </label>
                                  <div>
                                    <input
                                      id="user_response"
                                      name="user_response"
                                      type="text"
                                      style={{width: "100%", height: "35px"}}
                                      value={selectedNode.data.user_response || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.first && !selectedNode.data.user_response) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              user_response: 'Respuesta del cliente.' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            user_response: e.target.value
                                          },
                                        }))
                                      }
                                    />
                                    {!selectedNode.data.user_response ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Respuesta del cliente es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Mensaje que envía el cliente para activar la respuesta.
                                      </p>
                                    )}
                                  </div>

                                  <div className="p-1" style={{backgroundColor: 'rgba(91, 16, 77, 0.3)', borderRadius: '4px'}}>
                                    <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                      <span style={{fontSize: 12, fontWeight: 600}}>Importante:</span> Con el signo * funcionaná 
                                      para cuaquier respuesta que de el cliente.
                                    </p>
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label>Flujos: </label>
                                  <div className="su-control-group d-flex">
                                    <Select
                                      styles={isDark == 'true' ? customStylesDark : customStyles}
                                      className="form-control basic-multi-select"
                                      placeholder="Seleccione..."
                                      name="flows"
                                      onChange={(flow) => {
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            tags: flow._id
                                          },
                                        }))
                                      }}
                                      options={flows}
                                      classNamePrefix="select"
                                      noOptionsMessage={() => "Sin opciones"}
                                      getOptionLabel={(option) => (
                                        <div className="option d-flex" style={{fontSize: "15px"}}>
                                          {option.name} 
                                        </div>
                                      )}
                                      getOptionValue={(option) => option.name}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-center mt-4">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#B85C7C",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="mr-2"
                                  onClick={async() => {
                                    if(!selectedNode.data.title || 
                                      (!selectedNode.data.first && !selectedNode.data.user_response) ||
                                      !selectedNode.data.bot_response
                                    ) {
                                      return Swal.fire({
                                        title: "Atención!",
                                        text: "Se encuentran campos requeridos vacíos",
                                        icon: "warning",
                                        confirmButtonText: "Aceptar",
                                      });
                                    }

                                    if(selectedNode.data.saved) {
                                      await putBot(editFlow)
                                    } else {
                                      setSelectedNode((prevInputValue) => ({
                                        ...prevInputValue,
                                        data: {
                                          ...prevInputValue.data,
                                          saved: true
                                        },
                                      }))
                                      await postBot()
                                    }
                                  }}
                                >   
                                  Guardar
                                </button>

                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#B85C7C",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="ml-2"
                                  onClick={async() => {

                                    // if(!selectedNode.data.first && !selectedNode.data.saved) {
                                    //   Swal.fire({
                                    //     title: "¿Estas seguro que deseas cerrar sin guardar?",
                                    //     text: "Se eliminará el nodo.",
                                    //     icon: "warning",
                                    //     showCancelButton: true,
                                    //     confirmButtonColor: "#3085d6",
                                    //     cancelButtonColor: "#d33",
                                    //     confirmButtonText: "Seguro",
                                    //     cancelButtonText: "Cancelar",
                                    //   }).then(async (result) => {
                                    //     if (result.isConfirmed) {
                                    //       removeNode(selectedNode.id)
                                    //     }
                                    //   });
                                    // }
                                    setSelectedNode(null);
                                  }}
                                >   
                                  Cerrar
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                        {/* Transfer QueryApi */}
                        {selectedNode.type == 'queryApi' && (
                          <>
                            <div className="d-flex align-items-center justify-content-between text-white" style={{height: "50px", padding: "10px", borderBottom: '1px solid #CED4DA', backgroundColor: '#7ECCD8'}}>
                              <div className="d-flex align-items-center">
                                <BsDatabaseFillAdd className="mr-1" style={{fontSize: "24px"}}/>
                                <span style={{fontSize: "22px", whiteSpace: 'nowrap'}} >
                                    {selectedNode.data.title}
                                </span>
                              </div>
                              <button className="text-white border-0 bg-transparent d-block pointer mt-2" onClick={() => {
                                  const dropNode = nodes.filter(v => v.id !== selectedNode.id)
                                  const newNodes = dropNode.filter(v => v.parentId !== selectedNode.id)
                                  setNodes(newNodes)
                                  setSelectedNode(null)
                                }}>
                                  <BsTrash3 style={{ fontSize: "18px" }}/>
                              </button>
                            </div>
                            <div id="content-list-chats" className="card-body" style={{overflowY: 'auto'}}>
                              <div>
                                <div className="col p-0">
                                  <label htmlFor="title" style={{color: '#6C757D', fontWeight: 200}}>Título: </label>
                                  <div>
                                    <input
                                      id="title"
                                      name="title"
                                      type="text"
                                      style={{width: "100%", height: "35px"}}
                                      value={selectedNode.data.title || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.title) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              title: 'Título base' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            title: e.target.value
                                          },
                                        }))
                                      }
                                    />
    
                                    {!selectedNode.data.title ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Título es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Título identificativo de la respuesta para el diagrama de flujo.
                                      </p>
                                    )}
                                    
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label htmlFor="user_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del cliente: </label>
                                  <div>
                                    <input
                                      id="user_response"
                                      name="user_response"
                                      type="text"
                                      style={{width: "100%", height: "35px"}}
                                      value={selectedNode.data.user_response || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.first && !selectedNode.data.user_response) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              user_response: 'Respuesta del cliente.' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            user_response: e.target.value
                                          },
                                        }))
                                      }
                                    />
                                    {!selectedNode.data.user_response ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Respuesta del cliente es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Mensaje que envía el cliente para activar la respuesta.
                                      </p>
                                    )}
                                  </div>
                                  <div className="p-1" style={{backgroundColor: 'rgba(126, 204, 216, 0.3)', borderRadius: '4px'}}>
                                    <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                      <span style={{fontSize: 12, fontWeight: 600}}>Importante:</span> Con el signo * funcionaná 
                                      para cuaquier respuesta que de el cliente.
                                    </p>
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label htmlFor="url_api" style={{color: '#6C757D', fontWeight: 200}}>URL API externa: </label>
                                  <div>
                                    <input
                                      id="url_api"
                                      name="url_api"
                                      type="text"
                                      style={{width: "100%", height: "35px"}}
                                      value={selectedNode.data.url_api || ""}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            url_api: e.target.value
                                          },
                                        }))
                                      }
                                    />
                                    {!selectedNode.data.url_api ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo URL API es obligatorio
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        URL a la cual se va a realizar la petición.
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label htmlFor="url_api" style={{color: '#6C757D', fontWeight: 200}}>Protocolo HTTP: </label>
                                  <div>
                                    <Select
                                      styles={isDark == 'true' ? customStylesDark : customStyles}
                                      className="form-control basic-multi-select"
                                      placeholder="Seleccione..."
                                      name="protocols"
                                      onChange={(area) => {
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            protocol: area.id
                                          },
                                        }))
                                      }}
                                      options={[
                                        {
                                          label: 'GET',
                                          id: 'GET'
                                        },
                                        {
                                          label: 'POST',
                                          id: 'POST'
                                        },
                                        {
                                          label: 'PUT',
                                          id: 'PUT'
                                        },
                                        {
                                          label: 'PATCH',
                                          id: 'PATCH'
                                        },
                                      ]}
                                      classNamePrefix="select"
                                      noOptionsMessage={() => "Sin opciones"}
                                      getOptionLabel={(option) => (
                                        <div className="option d-flex" style={{fontSize: "15px"}}>
                                          {option.label} 
                                        </div>
                                      )}
                                      getOptionValue={(option) => option.label}
                                    />
                                    {!selectedNode.data.protocol ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Protocolo HTTP es obligatorio.
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Protocolo HTTP para realiza la petición.
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label htmlFor="auth" style={{color: '#6C757D', fontWeight: 200}}>Tipo de autenticación: </label>
                                  <div>
                                    <Select
                                      styles={isDark == 'true' ? customStylesDark : customStyles}
                                      className="form-control basic-multi-select"
                                      placeholder="Seleccione..."
                                      name="auth"
                                      onChange={(area) => {
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            auth: area.id
                                          },
                                        }))
                                      }}
                                      options={[
                                        {
                                          label: 'Ninguna',
                                          id: 'none'
                                        },
                                        {
                                          label: 'Usuario/Contraseña',
                                          id: 'user_password'
                                        },
                                      ]}
                                      classNamePrefix="select"
                                      noOptionsMessage={() => "Sin opciones"}
                                      getOptionLabel={(option) => (
                                        <div className="option d-flex" style={{fontSize: "15px"}}>
                                          {option.label} 
                                        </div>
                                      )}
                                      getOptionValue={(option) => option.label}
                                    />
                                    {!selectedNode.data.auth ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        Campo Tipo de autenticación es obligatorio.
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Seguridad del webhook.
                                      </p>
                                    )}
                                  </div>
                                </div>
                                {selectedNode.data.auth && selectedNode.data.auth == 'user_password' && (
                                  <>
                                    <div className="col p-0">
                                      <label htmlFor="user" style={{color: '#6C757D', fontWeight: 200}}>Usuario: </label>
                                      <div>
                                        <input
                                          id="user"
                                          name="user"
                                          type="text"
                                          style={{width: "100%", height: "35px"}}
                                          value={selectedNode.data.user || ""}
                                          onChange={(e) => 
                                            setSelectedNode((prevInputValue) => ({
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                user: e.target.value
                                              },
                                            }))
                                          }
                                        />
        
                                        {selectedNode.data.auth == 'user_password' && !selectedNode.data.user && (
                                          <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                            Campo Usuario es obligatorio.
                                          </p>
                                        )}
                                        
                                      </div>
                                    </div>
                                    <div className="col p-0">
                                      <label htmlFor="password" style={{color: '#6C757D', fontWeight: 200}}>Contraseña: </label>
                                      <div>
                                        <input
                                          id="password"
                                          name="password"
                                          type="text"
                                          style={{width: "100%", height: "35px"}}
                                          value={selectedNode.data.password || ""}
                                          onChange={(e) => 
                                            setSelectedNode((prevInputValue) => ({
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                password: e.target.value
                                              },
                                            }))
                                          }
                                        />
                                        {selectedNode.data.auth == 'user_password' && !selectedNode.data.password && (
                                          <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                            Campo Contraseña es obligatorio.
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}
                                <div className="col p-0 mt-4">
                                  <div className="panel-group collapsible" id="user-collapsible">
                                    <div className="panel panel-default">
                                      <div 
                                        className="panel-heading p-2" 
                                        style={{
                                          border: "1px solid #4F4F4F",
                                          borderRadius: "4px"
                                        }}>
                                        <div id="title-aditionalDates" className="panel-title d-flex justify-content-between align-items-center pointer" onClick={onAditionalHeaders}>
                                          <span>
                                            Cabeceras (Opcional)
                                          </span>
                                          {selectHeaders ?
                                            <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                                            <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                                          }
                                        </div>
                                      </div>

                                      {selectHeaders ? (
                                        <div 
                                          className="panel-collapse pt-2" 
                                          style={{
                                            backgroundColor: "#F1F1F1",
                                            borderRadius: "4px"
                                          }}>
                                          <div className="panel-body" style={{paddingInline: "0px"}}>
                                            <div className="text-center">
                                              <button
                                                type="button"
                                                style={{
                                                  border: 0,
                                                  borderRadius: "4px",
                                                  color: "#7ECCD8",
                                                  backgroundColor: "rgba(126, 204, 216, 0.3)",
                                                  cursor: "pointer",
                                                  fontFamily: "Helvetica, Arial, sans-serif",
                                                  fontSize: "12px",
                                                  fontWeight: "bold",
                                                  width: "150px",
                                                  zIndex: 100,
                                                }}
                                                onClick={() => setModalHeaders(true)}
                                              > 
                                                <IoMdAdd/>
                                                Agregar Cabecera
                                              </button>
                                            </div>
                                            <div className="mt-2">
                                              {selectedNode.data.headers.length > 0 && selectedNode.data.headers.map((head, index) => (
                                                <div className="d-flex justify-content-around">
                                                  <div>
                                                    <div style={{fontWeight:"bolder"}}>{head.key}</div>
                                                    <div>{head.value}</div>
                                                  </div>
                                                  <div className="d-flex justify-content-end">
                                                    <button
                                                      onClick={() => {
                                                        setEditModal(true)
                                                        setModalHeaders(true)
                                                        setHeaderParams({
                                                          key: head.key,
                                                          value: head.value,
                                                          index: index
                                                        })
                                                      }}
                                                      className="text-primary border-0 bg-transparent d-block pointer mt-2"
                                                    >
                                                      <BsPencilFill style={{ fontSize: "16px" }}/>
                                                    </button>
                                                    <button 
                                                      className="text-danger border-0 bg-transparent d-block pointer mt-2" 
                                                      onClick={() => {
                                                        const filter = selectedNode.data.headers.filter(v => v.key !== head.key)
                                                        setSelectedNode((prevInputValue) => ({
                                                          ...prevInputValue,
                                                          data: {
                                                            ...prevInputValue.data,
                                                            headers: filter
                                                          },
                                                        }))
                                                      }}
                                                    >
                                                      <BsTrash3 style={{ fontSize: "16px" }}/>
                                                    </button>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <div className="panel-group collapsible" id="user-collapsible">
                                    <div className="panel panel-default">
                                      <div 
                                        className="panel-heading p-2" 
                                        style={{
                                          border: "1px solid #4F4F4F",
                                          borderRadius: "4px"
                                        }}>
                                        <div id="title-aditionalDates" className="panel-title d-flex justify-content-between align-items-center pointer" onClick={onAditionalRequest}>
                                          <span>
                                            Parámetros de petición (Opcional)
                                          </span>
                                          {selectRequest ?
                                            <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                                            <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                                          }
                                        </div>
                                      </div>

                                      {selectRequest ? (
                                        <div 
                                          className="panel-collapse pt-2" 
                                          style={{
                                            backgroundColor: "#F1F1F1",
                                            borderRadius: "4px"
                                          }}>
                                          <div className="panel-body" style={{paddingInline: "0px"}}>
                                            <div className="text-center">
                                              <button
                                                type="button"
                                                style={{
                                                  border: 0,
                                                  borderRadius: "4px",
                                                  color: "#7ECCD8",
                                                  backgroundColor: "rgba(126, 204, 216, 0.3)",
                                                  cursor: "pointer",
                                                  fontFamily: "Helvetica, Arial, sans-serif",
                                                  fontSize: "12px",
                                                  fontWeight: "bold",
                                                  width: "150px",
                                                  zIndex: 100,
                                                }}
                                                onClick={() => setModalRequestParams(true)}
                                              > 
                                                <IoMdAdd/>
                                                Agregar Parámetro
                                              </button>
                                            </div>
                                            <div className="mt-2">
                                              {selectedNode.data.requestParams.length > 0 && selectedNode.data.requestParams.map((req, index) => (
                                                <div className="d-flex justify-content-around">
                                                  <div>
                                                    <div style={{fontWeight:"bolder"}}>{req.key}</div>
                                                    <div>{req.value}</div>
                                                  </div>
                                                  <div className="d-flex justify-content-end">
                                                    <button
                                                      onClick={() => {
                                                        setEditModal(true)
                                                        setModalRequestParams(true)
                                                        setRequestParams({
                                                          key: req.key,
                                                          value: req.value,
                                                          index: index
                                                        })
                                                      }}
                                                      className="text-primary border-0 bg-transparent d-block pointer mt-2"
                                                    >
                                                      <BsPencilFill style={{ fontSize: "16px" }}/>
                                                    </button>
                                                    <button 
                                                      className="text-danger border-0 bg-transparent d-block pointer mt-2" 
                                                      onClick={() => {
                                                        const filter = selectedNode.data.requestParams.filter(v => v.key !== req.key)
                                                        setSelectedNode((prevInputValue) => ({
                                                          ...prevInputValue,
                                                          data: {
                                                            ...prevInputValue.data,
                                                            requestParams: filter
                                                          },
                                                        }))
                                                      }}
                                                    >
                                                      <BsTrash3 style={{ fontSize: "16px" }}/>
                                                    </button>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="col p-0 mt-2">
                                  <button
                                    type="button"
                                    style={{
                                      border: 0,
                                      borderRadius: "4px",
                                      color: "#fff",
                                      backgroundColor: "#7ECCD8",
                                      cursor: "pointer",
                                      fontFamily: "Helvetica, Arial, sans-serif",
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                      width: "150px",
                                      height: "30px",
                                      zIndex: 100,
                                    }}
                                    onClick={() => requestApiNode()}
                                  > 
                                    <div className="d-flex align-items-center">
                                      <BiTransfer className="mr-1" style={{fontSize: "15px"}}/>
                                      <span>
                                        Consultar API
                                      </span>
                                    </div>
                                  </button>
                                </div>
                                <div className="col p-0 mt-4">
                                  <div className="panel-group collapsible" id="user-collapsible">
                                    <div className="panel panel-default">
                                      <div 
                                        className="panel-heading p-2" 
                                        style={{
                                          border: "1px solid #4F4F4F",
                                          borderRadius: "4px"
                                        }}>
                                        <div id="title-aditionalDates" className="panel-title d-flex justify-content-between align-items-center pointer" onClick={onAditionalResponse}>
                                          <span>
                                            Parámetros de respuesta (Opcional)
                                          </span>
                                          {selectResponse ?
                                            <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                                            <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                                          }
                                        </div>
                                      </div>

                                      {selectResponse ? (
                                        <div 
                                          className="panel-collapse pt-2" 
                                          style={{
                                            backgroundColor: "#F1F1F1",
                                            borderRadius: "4px"
                                          }}>
                                          <div className="panel-body" style={{paddingInline: "0px"}}>
                                            <div className="text-center">
                                              <button
                                                type="button"
                                                style={{
                                                  border: 0,
                                                  borderRadius: "4px",
                                                  color: "#7ECCD8",
                                                  backgroundColor: "rgba(126, 204, 216, 0.3)",
                                                  cursor: "pointer",
                                                  fontFamily: "Helvetica, Arial, sans-serif",
                                                  fontSize: "12px",
                                                  fontWeight: "bold",
                                                  width: "150px",
                                                  zIndex: 100,
                                                }}
                                                onClick={() => setModalResponseParams(true)}
                                              > 
                                                <IoMdAdd/>
                                                Agregar Respuesta
                                              </button>
                                            </div>
                                            <div className="mt-2">
                                              {selectedNode.data.responseParams.length > 0 && selectedNode.data.responseParams.map((res, index) => (
                                                <div className="d-flex justify-content-around">
                                                  <div>
                                                    <div style={{fontWeight:"bolder"}}>{res.key}</div>
                                                    <div>{'{{ ' + res.value + ' }}'}</div>
                                                  </div>
                                                  <div className="d-flex justify-content-end">
                                                    <button
                                                      onClick={() => {
                                                        setEditModal(true)
                                                        setModalResponseParams(true)
                                                        setResponseParams({
                                                          key: res.key,
                                                          value: res.value,
                                                          index: index
                                                        })
                                                      }}
                                                      className="text-primary border-0 bg-transparent d-block pointer mt-2"
                                                    >
                                                      <BsPencilFill style={{ fontSize: "16px" }}/>
                                                    </button>
                                                    <button 
                                                      className="text-danger border-0 bg-transparent d-block pointer mt-2" 
                                                      onClick={() => {
                                                        const filter = selectedNode.data.responseParams.filter(v => v.key !== res.key)
                                                        setSelectedNode((prevInputValue) => ({
                                                          ...prevInputValue,
                                                          data: {
                                                            ...prevInputValue.data,
                                                            responseParams: filter
                                                          },
                                                        }))
                                                      }}
                                                    >
                                                      <BsTrash3 style={{ fontSize: "16px" }}/>
                                                    </button>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="col p-0 mt-4">
                                  <label htmlFor="bot_response" style={{color: '#6C757D', fontWeight: 200}}>Respuesta del Bot: </label>
                                  <div>
                                    <textarea
                                      id="bot_response"
                                      name="bot_response"
                                      placeholder="Escribe la respuesta del Bot"
                                      style={{width: "100%", height: "90px", borderRadius: "4px"}}
                                      value={selectedNode.data.bot_response || ""}
                                      onBlur={() => {
                                        if(!selectedNode.data.bot_response) {
                                          setSelectedNode((prevInputValue) => ({
                                            ...prevInputValue,
                                            data: {
                                              ...prevInputValue.data,
                                              bot_response: '¡Hola! ¿Cómo puedo ayudarte hoy?' 
                                            },
                                          }))
                                        }
                                      }}
                                      onChange={(e) => 
                                        setSelectedNode((prevInputValue) => ({
                                          ...prevInputValue,
                                          data: {
                                            ...prevInputValue.data,
                                            bot_response: e.target.value
                                          },
                                        }))
                                      }
                                    />
                                    <div className="picker-container pt-4" style={{width: "100%"}}>
                                      <i
                                        className={
                                          showPicker
                                            ? "icon-smile emoji-icon active"
                                            : "icon-smile emoji-icon"
                                        }
                                        style={{ fontSize: "22px" }}
                                        onClick={() => setShowPicker((val) => !val)}
                                      />

                                      {showPicker && (
                                        <Picker
                                          pickerStyle={{
                                            width: "100%",
                                            top: "30px",
                                            position: "absolute",
                                            zIndex: "999",
                                          }}
                                          onEmojiClick={onEmojiClick}
                                          groupNames={{
                                            smileys_people: "Emoticones y personas",
                                            animals_nature: "Animales y naturaleza",
                                            food_drink: "Alimentos y bebidas",
                                            travel_places: "Viajes y lugares",
                                            activities: "Actividades",
                                            objects: "Objetos",
                                            symbols: "Símbolos",
                                            flags: "Banderas",
                                            recently_used: "Recientes",
                                          }}
                                        />
                                      )}
                                    </div>

                                    {!selectedNode.data.bot_response || selectedNode.data.bot_response.length > 1024 ? (
                                      <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                        {!selectedNode.data.bot_response ? 'Campo Respuesta del Bot es obligatorio' 
                                        : 'El campo Respuesta del Bot no debe ser mayor a 1024 caracteres'}
                                      </p>
                                    ): (
                                      <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                        Respuesta que se le envía al cliente.
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div 
                                  className="col p-2 mt-4"
                                  style={{
                                    border: "1px solid #000",
                                    borderRadius: "4px",
                                    height: "auto"
                                  }}
                                > 
                                  <div className="col p-0">
                                    <span style={{fontWeight: "bolder"}}>
                                      Estados de la Solicitud
                                    </span>
                                  </div>
                                  <div className="col p-0">
                                    <label htmlFor="succes" style={{color: '#6C757D', fontWeight: 200}}>Solicitud exitosa: </label>
                                    <div className="d-flex align-items-center">
                                      <FaCheckCircle className="mr-1" style={{fontSize: "22px", color: "#94CF96"}}/>
                                      <input
                                        id="succes"
                                        name="succes"
                                        type="text"
                                        style={{width: "100%", height: "35px"}}
                                        value={selectedNode.data.succes || ""}
                                        onFocus={() => {
                                          const node = nodes.find(v => v.id == (selectedNode.id+'-'+0))
                                          setSelectedChildNode(node)
                                        }}
                                        onChange={(e) => {
                                          setSelectedNode((prevInputValue) => {
                                            return {
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                succes: e.target.value
                                              },
                                            }
                                          })
                                          changeValueBtns(e.target.value, 'api')
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col p-0 mt-2">
                                    <label htmlFor="failed" style={{color: '#6C757D', fontWeight: 200}}>Solicitud fallida: </label>
                                    <div className="d-flex align-items-center">
                                      <BiSolidError className="mr-1" style={{fontSize: "22px", color: "#F88E86"}}/>
                                      <input
                                        id="failed"
                                        name="failed"
                                        type="text"
                                        style={{width: "100%", height: "35px"}}
                                        value={selectedNode.data.failed || ""}
                                        onFocus={() => {
                                          const node = nodes.find(v => v.id == (selectedNode.id+'-'+1))
                                          setSelectedChildNode(node)
                                        }}
                                        onChange={(e) => {
                                          setSelectedNode((prevInputValue) => {
                                            return {
                                              ...prevInputValue,
                                              data: {
                                                ...prevInputValue.data,
                                                failed: e.target.value
                                              },
                                            }
                                          })
                                          changeValueBtns(e.target.value, 'api')
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-center mt-4">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#7ECCD8",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="mr-2"
                                  onClick={async() => {
                                    if(!selectedNode.data.title || 
                                      (!selectedNode.data.first && !selectedNode.data.user_response) ||
                                      !selectedNode.data.bot_response
                                    ) {
                                      return Swal.fire({
                                        title: "Atención!",
                                        text: "Se encuentran campos requeridos vacíos",
                                        icon: "warning",
                                        confirmButtonText: "Aceptar",
                                      });
                                    }

                                    if(selectedNode.data.saved) {
                                      await putBot(editFlow)
                                    } else {
                                      setSelectedNode((prevInputValue) => ({
                                        ...prevInputValue,
                                        data: {
                                          ...prevInputValue.data,
                                          saved: true
                                        },
                                      }))
                                      await postBot()
                                    }
                                  }}
                                >   
                                  Guardar
                                </button>

                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#7ECCD8",
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    height: "30px",
                                    width: "50%",
                                  }}
                                  className="ml-2"
                                  onClick={async() => {

                                    // if(!selectedNode.data.first && !selectedNode.data.saved) {
                                    //   Swal.fire({
                                    //     title: "¿Estas seguro que deseas cerrar sin guardar?",
                                    //     text: "Se eliminará el nodo.",
                                    //     icon: "warning",
                                    //     showCancelButton: true,
                                    //     confirmButtonColor: "#3085d6",
                                    //     cancelButtonColor: "#d33",
                                    //     confirmButtonText: "Seguro",
                                    //     cancelButtonText: "Cancelar",
                                    //   }).then(async (result) => {
                                    //     if (result.isConfirmed) {
                                    //       removeNode(selectedNode.id)
                                    //     }
                                    //   });
                                    // }
                                    setSelectedNode(null);
                                  }}
                                >   
                                  Cerrar
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                     )
                    : (
                      <div id="content-list-chats" className="card-body" style={{overflowY: "auto"}}>
                        <div style={{fontSize: "1.2rem", fontWeight: "bold"}}>
                          Opciones de Respuestas
                        </div>
                        <div className="mt-2">
                          <ul className="list-group list-group-flush py-2">
                            <li
                              style={{cursor: 'grab', backgroundColor: '#00A884', borderRadius: "4px"}}
                              className="grab list-group-item dndnode cursor-pointer text-white" onDragStart={(event) => onDragStart(event, 'BasicAnswer')} 
                              draggable
                            >
                              <div className="d-flex align-items-center">
                                <FiMessageSquare style={{ fontSize: "20px", fontWeight: "bold" }}/>
                                <span className="pl-2" style={{fontSize: "14px", fontWeight: "bold"}}>Respuestas Básicas</span>
                              </div>
                            </li>
                            <li
                              style={{cursor: 'grab', backgroundColor: '#F56F89', marginTop: '6px', borderRadius: "4px"}}
                              className="grab list-group-item dndnode cursor-pointer text-white" onDragStart={(event) => onDragStart(event, 'TransferAgent')} 
                              draggable
                            >
                              <div className="d-flex align-items-center">
                                <MdOutlineSupportAgent style={{ fontSize: "20px", fontWeight: "bold" }}/>
                                <span className="pl-2" style={{fontSize: "14px", fontWeight: "bold"}}>Transferir Area/Agente</span>
                              </div>
                            </li>
                            <li
                              style={{cursor: 'grab', backgroundColor: '#5BC0DE', marginTop: '6px', borderRadius: "4px"}}
                              className="grab list-group-item dndnode cursor-pointer text-white" onDragStart={(event) => onDragStart(event, 'Tags')} 
                              draggable
                            >
                              <div className="d-flex align-items-center">
                                <LuTags style={{ fontSize: "20px", fontWeight: "bold" }}/>
                                <span className="pl-2" style={{fontSize: "14px", fontWeight: "bold"}}>Etiquetas</span>
                              </div>
                            </li>
                            <li
                              style={{cursor: 'grab', backgroundColor: '#A7ADA7', marginTop: '6px', borderRadius: "4px"}}
                              className="grab list-group-item dndnode cursor-pointer text-white" onDragStart={(event) => onDragStart(event, 'AssitantAI')} 
                              draggable
                            >
                              <div className="d-flex align-items-center">
                                <FaBrain style={{ fontSize: "20px", fontWeight: "bold" }}/>
                                <span className="pl-2" style={{fontSize: "14px", fontWeight: "bold"}}>Asistente con IA</span>
                              </div>
                            </li>
                            <li
                              style={{cursor: 'grab', backgroundColor: '#D89C7E', marginTop: '6px', borderRadius: "4px"}}
                              className="grab list-group-item dndnode cursor-pointer text-white" onDragStart={(event) => onDragStart(event, 'SaveAnswer')} 
                              draggable
                            >
                              <div className="d-flex align-items-center">
                                <HiOutlineSave style={{ fontSize: "20px", fontWeight: "bold" }}/>
                                <span className="pl-2" style={{fontSize: "14px", fontWeight: "bold"}}>Guardar Respuestas</span>
                              </div>
                            </li>
                            <li
                              style={{cursor: 'grab', backgroundColor: '#B85C7C', marginTop: '6px', borderRadius: "4px"}}
                              className="grab list-group-item dndnode cursor-pointer text-white" onDragStart={(event) => onDragStart(event, 'FlowsWhatsApp')} 
                              draggable
                            >
                              <div className="d-flex align-items-center">
                                <TiFlowMerge style={{ fontSize: "20px", fontWeight: "bold" }}/>
                                <span className="pl-2" style={{fontSize: "14px", fontWeight: "bold"}}>Flujos de Whatsapp</span>
                              </div>
                            </li>
                            <li
                              style={{cursor: 'grab', backgroundColor: '#7ECCD8', marginTop: '6px', borderRadius: "4px"}}
                              className="grab list-group-item dndnode cursor-pointer text-white" onDragStart={(event) => onDragStart(event, 'QueryApi')} 
                              draggable
                            >
                              <div className="d-flex align-items-center">
                                <BsDatabaseFillAdd style={{ fontSize: "20px", fontWeight: "bold" }}/>
                                <span className="pl-2" style={{fontSize: "14px", fontWeight: "bold"}}>Consulta API</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )
                  }
                </div>
              </ReactFlow>
            </div>
          </section>
        </>
      )}

      {/* MODAL CREATE BOT */}
      <Modal
        status={modalCreate}
        changeStatus={setModalCreate}
        title="Crear Bot"
        action={() => null}
        width="550px"
      >
        <div 
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>
            <p>
              Ingresa un nombre para el bot
            </p>
            <div>
              <input
                className="form-control"
                type="text"
                name="name"
                id="name"
                value={nameBot}
                onChange={(e) => {
                  setNameBot(e.target.value)
                }}
              />
              {!nameBot.length && (
                <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                  Nombre del bot es requerido
                </p>
              )}
            </div>
            <p className="pt-4">
              Ingresa una descripción para el bot
            </p>
            <div>
              <input
                className="form-control"
                type="text"
                name="description"
                id="description"
                value={descriptionBot}
                onChange={(e) => {
                  setDescriptionBot(e.target.value)
                }}
              />
              {!descriptionBot.length && (
                <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                  La descripción del bot es requerida
                </p>
              )}
            </div>
          </div>

          <div className="row justify-content-end bd col-12 mt-4">
            <a
              href="javascript:;"
              className="btn btn-lg btn-default mr-1"
              onClick={() => {
                setModalCreate(false);
              }}
            >
              Cerrar
            </a>
            <a
              href="javascript:;"
              className="btn btn-lg btn-info mr-1"
              onClick={() => {
                if(nameBot && descriptionBot) {
                  setModalCreate(false);
                  setMode("create");
                  setNodes(initialNodes);
                }
              }}
            >
              Crear Bot
            </a> 
          </div>
        </div>
      </Modal>
      
      {/* MODAL HEADERS PARAMS */}
      <Modal
        status={modalHeaders}
        changeStatus={setModalHeaders}
        title="Cabecera"
        width={"450px"}
      >
        <div className="justify-content-center">
          <div className="justify-content-center scrollable-content">
            <div className="col">
              <label htmlFor="key">Clave de la Cabecera: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="key"
                  name="key"
                  type="text"
                  value={headerParams.key}
                  onChange={(e) => 
                    setHeaderParams((prev) => ({
                      ...prev,
                      key: e.target.value
                    }))
                  }
                />
              </div>
            </div>
            <div className="col mt-4">
              <label htmlFor="value">Valor de la Cabecera: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="value"
                  name="value"
                  type="text"
                  value={headerParams.value}
                  onChange={(e) => 
                    setHeaderParams((prev) => ({
                      ...prev,
                      value: e.target.value
                    }))
                  }
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-2">
            <a href="javascript:;" className="btn btn-lg btn-default mr-1" onClick={() => {
              setModalHeaders(false)
              setEditModal(false)
              setHeaderParams({})
            }}>
              Volver
            </a>
            <input className="btn btn-lg btn-info" style={{width: "150px"}} value="Guardar" 
              onClick={() => {
                if(!editModal) { 
                  const data = [
                    ...selectedNode.data.headers, 
                    headerParams
                  ]
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      headers: data
                    },
                  }))
                } else {
                  let data = selectedNode.data.headers
                  data[headerParams.index].key = headerParams.key
                  data[headerParams.index].value = headerParams.value
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      headers: data
                    },
                  }))
                }
                setModalHeaders(false)
                setEditModal(false)
                setHeaderParams({})
              }}
            />
          </div>
        </div>
      </Modal>
      
      {/* MODAL REQUEST PARAMS */}
      <Modal
        status={modalRequestParams}
        changeStatus={setModalRequestParams}
        title="Parámetros de petición"
        width={"450px"}
      >
        <div className="justify-content-center">
          <div className="justify-content-center scrollable-content">
            <div className="col">
              <label htmlFor="key">Nombre del Parámetro: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="key"
                  name="key"
                  type="text"
                  value={requestParams.key}
                  onChange={(e) => 
                    setRequestParams((prev) => ({
                      ...prev,
                      key: e.target.value
                    }))
                  }
                />
              </div>
            </div>
            <div className="col mt-4">
              <label htmlFor="value">Valor: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="value"
                  name="value"
                  type="text"
                  value={requestParams.value}
                  onChange={(e) => 
                    setRequestParams((prev) => ({
                      ...prev,
                      value: e.target.value
                    }))
                  }
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-2">
            <a href="javascript:;" className="btn btn-lg btn-default mr-1" onClick={() => {
              setModalRequestParams(false)
              setEditModal(false)
              setRequestParams({})
            }}>
              Volver
            </a>
            <input className="btn btn-lg btn-info" style={{width: "150px"}} value="Guardar" 
              onClick={() => {
                if(!editModal) { 
                  const data = [
                    ...selectedNode.data.requestParams, 
                    requestParams
                  ]
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      requestParams: data
                    },
                  }))
                } else {
                  let data = selectedNode.data.requestParams
                  data[requestParams.index].key = requestParams.key
                  data[requestParams.index].value = requestParams.value
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      requestParams: data
                    },
                  }))
                }
                setModalRequestParams(false)
                setEditModal(false)
                setRequestParams({})
              }}
            />
          </div>
        </div>
      </Modal>
      
      {/* MODAL RESPONSE PARAMS */}
      <Modal
        status={modalResponseParams}
        changeStatus={setModalResponseParams}
        title="Parámetros de respuesta"
        width={"450px"}
      >
        <div className="justify-content-center">
          <div className="justify-content-center scrollable-content">
            <div className="col">
              <label htmlFor="key">Nombre del Parámetro: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="key"
                  name="key"
                  type="text"
                  value={responseParams.key}
                  onChange={(e) => 
                    setResponseParams((prev) => ({
                      ...prev,
                      key: e.target.value
                    }))
                  }
                />
              </div>
            </div>
            <div className="col mt-4">
              <label htmlFor="value">Variable: </label>
              <div className="su-control-group">
                <input
                  className="form-control"
                  id="key"
                  name="value"
                  type="text"
                  value={responseParams.value}
                  readOnly 
                />
                <RichTreeView 
                  items={listRequestParams}
                  onSelectedItemsChange={handleItemValSelect}
                />
                <p className="mt-2 p-2" style={{backgroundColor: "#F1F1F1", borderRadius: "4px"}}>
                  Para que se listen opciones de variables, 
                  debe realizar una consulta previa a la Api para listar los datos.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-2">
            <a href="javascript:;" className="btn btn-lg btn-default mr-1" onClick={() => {
              setModalResponseParams(false)
              setEditModal(false)
              setResponseParams({})
            }}>
              Volver
            </a>
            <input className="btn btn-lg btn-info" style={{width: "150px"}} value="Guardar" 
              onClick={() => {
                if(!editModal) { 
                  const data = [
                    ...selectedNode.data.responseParams, 
                    responseParams
                  ]
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      responseParams: data
                    },
                  }))
                } else {
                  let data = selectedNode.data.responseParams
                  data[responseParams.index].key = responseParams.key
                  data[responseParams.index].value = responseParams.value
                  setSelectedNode((prevInputValue) => ({
                    ...prevInputValue,
                    data: {
                      ...prevInputValue.data,
                      responseParams: data
                    },
                  }))
                }
                setModalResponseParams(false)
                setEditModal(false)
                setResponseParams({})
              }}
            />
          </div>
        </div>
      </Modal>

      {/* MODAL REQUEST RESPONSE API */}
      <Modal
        status={requestResponseModal}
        changeStatus={setRequestResponseModal}
        title="Respuesta de consulata API"
        width={"450px"}
      >
        <div className="justify-content-center">
          <div className="scrollable-content">
            <AceEditor
              mode="json"
              theme={isDark == 'true' ? 'github_dark' : 'github'}
              value={JSON.stringify(requestResponse, null, 2)}
              readOnly
              editorProps={{ $blockScrolling: true }}
              style={{ width: '100%', height: '300px' }}
            />
          </div>
          <div className="mb-2 text-center">
            <input className="btn btn-lg btn-info" style={{width: "150px"}} value="Listo"  type="button"    
              onClick={() => {
                const res = convertToTree(requestResponse, selectedNode.data.title)
                setListRequestParams([res])
                setRequestResponseModal(false)
                setRequestResponse({})
              }}
            />
          </div>
        </div>
      </Modal>

      {/* MODAL CONFIG BOT*/}
      <Modal
        status={configModal}
        changeStatus={setConfigModal}
        title="Configuración"
        width={"450px"}
      >
        <div className="justify-content-center">
          <div>
            <div className="col p-0 mt-4">
              <div className="panel-group collapsible" id="user-collapsible">
                <div className="panel panel-default">
                  <div 
                    className="panel-heading p-2" 
                    style={{
                      border: "1px solid #4F4F4F",
                      borderRadius: "4px"
                    }}>
                    <div id="title-aditionalDates" className="panel-title d-flex justify-content-between align-items-center pointer" onClick={onAditionalTimeInactivity}>
                      <span>
                        Tiempo de inactividad
                      </span>
                      {selectTimeInactivity ?
                        <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                        <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                      }
                    </div>
                  </div>

                  {selectTimeInactivity ? (
                    <div 
                      className="panel-collapse pt-2" 
                      style={{
                        backgroundColor: "#F1F1F1",
                        borderRadius: "4px"
                      }}>
                        <div className="p-4">
                          <label>Tiempo: </label>
                          <div className="su-control-group d-flex">
                            <Select
                              styles={isDark == 'true' ? customStylesDark : customStyles}
                              className="form-control basic-multi-select"
                              placeholder="Seleccione..."
                              name="inactivity"
                              value={configBot.inactivity}
                              onChange={(option) => {
                                setConfigBot((prev) => ({
                                  ...prev,
                                  inactivity: option,
                                }))
                              }}
                              options={listInactivity}
                              classNamePrefix="select"
                              noOptionsMessage={() => "Sin opciones"}
                              getOptionLabel={(option) => (
                                <div className="option d-flex" style={{fontSize: "15px"}}>
                                  {option.label} 
                                </div>
                              )}
                              getOptionValue={(option) => option.value}
                            />
                          </div>
                        </div>
                        <div className="col p-0 px-4">
                          <label htmlFor="companySchedule_message" style={{color: '#6C757D', fontWeight: 200}}>Mensaje de ausencia: </label>
                          <div>
                            <textarea
                              id="companySchedule_message"
                              name="companySchedule_message"
                              placeholder="Escribe el mensaje"
                              style={{width: "100%", height: "90px", borderRadius: "4px"}}
                              value={configBot?.inactivityMessage || ""}
                              onChange={(e) => {
                                setConfigBot((prev) => ({
                                  ...prev,
                                  inactivityMessage: e.target.value
                                }))
                                setValidateMessageConfig(false)
                              }}
                            />

                              <p style={{fontSize: 11, color: '#6C757D', fontWeight: 200}}>
                                Mensaje de ausencia que se envía al cliente.
                              </p>

                              {validateMessageConfig && (
                                <p style={{fontSize: 11, color: '#f70202', fontWeight: 200}}>
                                  Por favor escribe un mensaje de ausencia.
                                </p>
                              )}
                          </div>
                        </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col p-0 mt-4">
              <div className="panel-group collapsible" id="user-collapsible">
                <div className="panel panel-default">
                  <div 
                    className="panel-heading p-2" 
                    style={{
                      border: "1px solid #4F4F4F",
                      borderRadius: "4px"
                    }}>
                    <div id="title-aditionalDates" className="panel-title d-flex justify-content-between align-items-center pointer" onClick={onAditionalCompanySchedule}>
                      <span>
                        Horario comercial
                      </span>
                      {selectCompanySchedule ?
                        <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                        <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                      }
                    </div>
                  </div>

                  {selectCompanySchedule ? (
                    <div 
                      className="panel-collapse pt-2" 
                      style={{
                        backgroundColor: "#F1F1F1",
                        borderRadius: "4px"
                      }}
                    >
                      <div className="p-4">
                          <label>Horario: </label>
                          <div className="su-control-group d-flex">
                            <Select
                              styles={isDark == 'true' ? customStylesDark : customStyles}
                              className="form-control basic-multi-select"
                              placeholder="Seleccione..."
                              name="companySchedule"
                              value={configBot.companySchedule}
                              onChange={(option) => {
                                setConfigBot((prev) => ({
                                  ...prev,
                                  companySchedule: option,
                                }))
                              }}
                              options={companyScheduleOptions}
                              classNamePrefix="select"
                              noOptionsMessage={() => "Sin opciones"}
                              getOptionLabel={(option) => (
                                <div className="option d-flex" style={{fontSize: "15px"}}>
                                  {option.label} 
                                </div>
                              )}
                              getOptionValue={(option) => option.value}
                            />
                          </div>
                        </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="mb-2 mt-3 text-center">
            <input className="btn btn-lg btn-info" style={{width: "150px"}} value="Guardar"  type="button"    
              onClick={() => {
                handleBotConfig()
              }}
            />
          </div>
        </div>
      </Modal>

      {/* MODAL VARIABLES */}

      <Dialog
        open={variablesModal}
        onClose={() => setVariablesModal(false)}
        anchorEl={anchorEl}
        PaperProps={{
          style: {
            position: 'absolute',
            top: anchorEl ? anchorEl.getBoundingClientRect().top : 0,
            left: anchorEl ? anchorEl.getBoundingClientRect().left : 0,
            minWidth: '200px',
            padding: '10px',
            height: '300px',
            overflow: 'auto',
            backgroundColor: isDark == 'true' ? '#162229' : '#fff'
          },
        }}
      > 
        <DialogTitle className="text-center text-dark">
          Variables
        </DialogTitle>

        {listRequestParams.length > 0 && (
          <div>
            <RichTreeView 
              items={listRequestParams}
              onSelectedItemsChange={handleItemValSelectTest}
              // multiSelect
              slots={{
                item: CustomTreeItem,
              }}
              slotProps={{
                item: {
                  icon: <BsDatabaseFillAdd />,
                },
              }}
            /> 
          </div>
        )}

        {!listRequestParams.length && (
          <div className="p-4 text-center text-dark">
            No hay variables disponibles
          </div>
        )}
      </Dialog>
    </>
  );
};

const FlowWithProvider = () => {
  return (
    <ReactFlowProvider>
      <Flow/>
    </ReactFlowProvider>
  );
}

export default FlowWithProvider;