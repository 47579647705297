import React, { useState, useRef, useEffect } from 'react';
import styled from "styled-components";
import Avvvatars from "avvvatars-react";
import dayjs from "dayjs";
import useAPI from "../../hooks/useAPI";
// ICONOS
import { FaSpinner, FaBell, FaBellSlash, FaFilter, FaImage, FaUser } from "react-icons/fa";
import { BsSearch, BsFillCameraVideoFill, BsFillFileEarmarkTextFill, BsCheckAll, BsCheck } from "react-icons/bs";
import { LiaEditSolid } from "react-icons/lia";
import { IoFilterCircleOutline, IoLocationOutline } from "react-icons/io5";
import { PiUserSwitchFill, PiStickerLight } from "react-icons/pi";
import { HiMicrophone } from "react-icons/hi2";
import { HiShoppingCart, HiTemplate} from "react-icons/hi";
import { LuWorkflow } from "react-icons/lu";
import { TbCubeSend, TbMessage2X, TbMessagePlus } from "react-icons/tb";
import { TiArrowBack } from "react-icons/ti";

const ListConversations = ({
  setNewChat,
  setSearchConversation,
  setActiveNotification,
  setSearchPersons,
  setPersonsList,
  setClassChat,
  setFilter,
  setLoadingChats,
  setNumberPageConversations,
  setTotalPagesConversations,
  activeNotification,
  numberPageConversations,
  totalPagesConversations,
  searchConversation,
  filterRef,
  filter,
  filterState,
  listTagsAll,
  validationChats,
  listUserChats,
  listUserChatsRef,
  loadingChats,
  textConversations,
  onChatSelected,
  getTagContact,
  getConversationsAsign,
  getConversations,
  conversationsListRef,
  fetchConversations,
  previousScrollHeightRef,
  previousScrollTopRef,
  conversationsPendingCount,
  setLoading
}) => {

  // const [loading, setLoading] = useState(false);
  const [loadingMoreConversations, setLoadingMoreConversations] = useState(false);
  const [notMessages, setNotMessages] = useState("");
  const [selectedChat, setSelectedChat] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [loadingListContacts, setLoadingListContacts] = useState(false);
  const [loadingListConv, setLoadingListConv] = useState(false);
  const [filterTags, setFilterTags] = useState("all");

  const { urlAPI, urlAPI_1, urlAPI_2, optGET, optPOST, optPUT, urlSocket } = useAPI();
  const roleId = localStorage.getItem("USER_ROLEID");

  useEffect(() => {
    /*
    const conversationList = conversationsListRef.current;
    console.log("conversationList.scrollTop:", conversationList.scrollTop);
    console.log("conversationList.clientHeight:", conversationList.clientHeight);
    if (previousScrollHeightRef.current) {
      const newScrollTop = conversationList.scrollHeight - previousScrollHeightRef.current + previousScrollTopRef.current;
      conversationList.scrollTop = newScrollTop;
      console.log("NEW conversationList.scrollTop:", conversationList.scrollTop);
    }
    */
  }, [listUserChats]);

  const getRelativeDate = (timestamp) => {
    let daysOfWeek = ["dom", "lun", "mar", "mie", "jue", "vie", "sab"];
    let today = new Date();
    today.setHours(0, 0, 0, 0); // Establezco la hora a las 00:00 hs
    today = today.getTime();
    let oneDay = 24 * 60 * 60 * 1000; // Milisegundos en un día
    let fiveDays = oneDay * 5;

    if(today - timestamp > fiveDays){
      let date_return = new Date(timestamp);
      return dayjs(date_return).locale("es").format("DD-MMM hh:mm a");
    }

    let yesterday = today - oneDay;
    today = new Date(today);
    yesterday = new Date(yesterday);

    daysOfWeek[today.getDay()] = "";
    daysOfWeek[yesterday.getDay()] = "ayer";

    const date = new Date(timestamp);
    const day = date.getDay(); // Obtengo el número del día de la semana (0-6)
    let date_return = new Date(timestamp);
    return daysOfWeek[day] + " " + dayjs(date_return).locale("es").format("hh:mm a");
  }
  
  const onScrollDownConversations = async () => {
    if (loadingMoreConversations) return; // Evitar llamadas duplicadas
    const conversationList = conversationsListRef.current;
    // console.log("conversationList.scrollTop:", conversationList.scrollTop);
    // console.log("conversationList.clientHeight:", conversationList.clientHeight);
    if ((conversationList.scrollTop + conversationList.clientHeight >= conversationList.scrollHeight - 10) && (numberPageConversations <= totalPagesConversations)) {
      console.log("onScrollDownConversations!!!");
      if(listUserChatsRef.current.length < 10){
        return;
      }
      // Lógica para cargar mas conversaciones
      setLoadingMoreConversations(true);
      let url = "";
      switch (filterRef.current) {
        case 'all':
          url = urlAPI_1 + "fetchMessages?page=" + `${numberPageConversations}` + "&origin=frontend-v2";
          break;
        case 'onHold':
          url = urlAPI_1 + "fetchMessages?page=" + `${numberPageConversations}` + "&list=noAsign" + "&origin=frontend-v2";
          break;
        case 'mine':
          url = urlAPI_1 + "fetchMessages?page=" + `${numberPageConversations}` + "&list=asign" + "&origin=frontend-v2";
          break;
        case 'bots':
            url = urlAPI_1 + "fetchMessages?page=" + `${numberPageConversations}` + "&list=bot" + "&origin=frontend-v2";
            break;
        case 'pending':
          url = urlAPI_1 + "fetchMessages?page=" + `${numberPageConversations}` + "&list=pending"  + "&origin=frontend-v2";
          break        
        default:
          url = urlAPI_1 + "fetchMessages?page=" + `${numberPageConversations}` + "&origin=frontend-v2";
          break;
      }
      previousScrollTopRef.current = conversationList.scrollTop;
      previousScrollHeightRef.current = conversationList.scrollHeight;
      await fetchConversations(url);
      setLoadingMoreConversations(false);
    }
  };

  const getConversationsPending = async (other_filter = false) => {
    setLoading(true);
    console.log("Ejecutando getConversations pending.......");
    setFilter("pending");
    filterRef.current = "pending";
    setLoadingChats(true);
    // Variables para utilizar en html, guardaran el mismo status.
    setNumberPageConversations(1);
    setTotalPagesConversations(2);
    let url = urlAPI_1 + "fetchMessages" + "?page=1&list=pending" + "&origin=frontend-v2";
    if(other_filter){
      url += "&filter=" + other_filter;
    }
    listUserChatsRef.current = [];
    await fetchConversations(url);
    setLoading(false);
    return;
  };

  const getConversationsNoAsign = async (other_filter = false) => {
    setLoading(true);
    console.log("Ejecutando getConversations No asign.......");
    setFilter("onHold");
    filterRef.current = "onHold";
    setLoadingChats(true);
    // Variables para utilizar en html, guardaran el mismo status.
    setNumberPageConversations(1);
    setTotalPagesConversations(2);
    let url = urlAPI_1 + "fetchMessages" + "?page=1&list=noAsign" + "&origin=frontend-v2";
    if(other_filter){
      url += "&filter=" + other_filter;
    }
    listUserChatsRef.current = [];
    await fetchConversations(url);
    setLoading(false);
    return;
  };

  const getConversationsBots = async (other_filter = false) => {
    console.log("Ejecutando getConversations Bots.......");
    setFilter("bots");
    filterRef.current = "bots";
    setLoadingChats(true);
    // Variables para utilizar en html, guardaran el mismo status.
    setNumberPageConversations(1);
    setTotalPagesConversations(2);
    let url = urlAPI_1 + "fetchMessages" + "?page=1&list=bot" + "&origin=frontend-v2";
    if(other_filter){
      url += "&filter=" + other_filter;
    }
    listUserChatsRef.current = [];
    await fetchConversations(url);
    return;
  };

  const onFilterTags = (e) => {
    console.log('Filtrando por etiquetas...')
    console.log('VAlor de etiqueta seleccionada...:', e)
    if(!e) return;
    switch (filterRef.current) {
      case "all":
          getConversations(e);
          break;
      case "mine":
          getConversationsAsign(e);
          break;
      case "onHold":
          getConversationsNoAsign(e);
          break;
      case "pending":
          getConversationsPending(e);
          break;          
      case "bots":
          getConversationsBots(e);
          break;
      default:
          getConversationsAsign(e);
          break;
    }
    setShowFilter(false);
  };

  const onFilterStatus = (e) => {
    if(!e) return;
    if(["1", "2", "3"].includes(e)){
      getConversations("status_" + e);
    }else{
      getConversations();
    }
    setShowFilter(false);
  };

  const handleNewChatClick = () => {
    console.log("Nuevo chat clickeado");
    setLoadingListContacts(true);

    setTimeout(() => {
      setSearchPersons("");
      setNewChat(true);
      setSelectedChat(null);
      const dataJSON = JSON.parse(localStorage.getItem('LIST_CONTACT'));
      setPersonsList(dataJSON);
      setLoadingListContacts(false);
    }, 1500);
  };

  return (
    <>
      <header className="message d-flex" style={{height: "5%"}}>
        <div id="tittleFormProduct" className="d-flex w-100">
          <div className="col-6 pl-1 d-flex">
            <div className="pointer d-flex align-items-center" style={{padding: "0 10px"}}>
            {loadingListConv ? (
              <span className="d-flex">
                <FaSpinner
                  id="spinner-list-conv"
                  className="fa-spin"
                  style={{ fontSize: "23px" }}
                />
              </span>
            ) : (
              <BsSearch
                style={{ fontSize: "20px" }}
                title="Buscar chat"
                className="icon-search"
                onClick={() => {
                  setLoadingListConv(true);
                  setTimeout(() => {
                    setNewChat(true)
                    setSearchConversation(prevState => !prevState)
                    setLoadingListConv(false);
                  }, 1500);
                }}
              />
            )}
            </div>
            <div className="ml-0 d-flex align-items-center">
              <div className="dropdown mx-2 pointer status-chat d-flex align-items-center">
                <FaFilter style={{ fontSize: "20px" }} title="Filtrar" className="icon-filter"
                  onClick={() => {
                    if (showFilter) {
                      setShowFilter(false);
                    } else {
                      setShowFilter(true);
                    }
                  }}
                />
                {showFilter ? (
                  <div className="category-list position-absolute status-list" style={{ width: "200px", zIndex: "9", top: "0", left: "20px" }}>
                    <span
                      className="position-absolute close-transfer pointer"
                      style={{ right: "0.2rem", top: "0", fontSize:"1rem", fontWeight:"bold" }}
                      onClick={() => {
                        setShowFilter(false);
                      }}
                    >x</span>
                    <div className="mt-1" style={{borderBottom: "1px solid #ccc", margin: "0 10px"}}>
                      <h5 className="ml-1">Estados</h5>
                      {filterState.length > 0 && filterState.map((state, k) => (
                        <div key={k} className="item-list">
                          <input
                            type="radio"
                            name={state.id}
                            id={state.id}
                            className="form-check-input mt-3"
                            style={{display: "none"}}
                            value={state.id}
                            onChange={(e) => {
                              onFilterStatus(e.target.value);
                              setFilterTags(e.target.value)
                            }}
                          />
                          <label
                            htmlFor={state.id}
                            className="d-flex mb-0 rounded"
                            key={state.id}
                            style={{
                              padding: "5px 2px 5px",
                              backgroundColor: filterTags == state.id ? "#aebac1" : "transparent"
                            }}
                          >
                            <div>
                              <label
                                htmlFor={state.id}
                                className="form-check-label pl-1 f-w-400"
                                style={filterTags == state.id ? {fontWeight:"700"} : {} }
                              >
                                {state.name}
                              </label>
                            </div>
                          </label>
                        </div>
                      ))}
                    </div>

                    <div className="mt-1" style={{borderBottom: "1px solid #ccc", margin: "0 10px"}}>
                      <h5 className="ml-1">Etiquetas</h5>
                      <div id='filt-tags' style={{maxHeight:"230px", overflow:"auto"}}>
                        {listTagsAll.length > 0 && listTagsAll.map((tag, k) => (
                          <div key={k} className="item-list">
                            <input
                              type="radio"
                              name={tag._id}
                              id={tag._id}
                              className="form-check-input mt-3"
                              style={{display: "none"}}
                              value={tag._id}
                              onChange={(e) => {
                                onFilterTags(e.target.value);
                                setFilterTags(e.target.value)
                              }}
                            />
                            <label
                              htmlFor={tag._id}
                              className="d-flex mb-0"
                              key={tag._id}
                              style={{
                                padding: "5px 2px 5px",
                                backgroundColor: filterTags === tag._id ? "#aebac1" : "transparent"
                              }}
                            >
                              <div>
                                <label
                                  htmlFor={tag._id}
                                  className="form-check-label pl-1 f-w-400"
                                  style={filterTags === tag._id ? {fontWeight:"700"} : {} }
                                >
                                  {tag.title}
                                </label>
                              </div>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="mt-1">
                      <div className="item-list">
                        <input
                          type="radio"
                          name="all"
                          id="all"
                          className="form-check-input mt-3"
                          style={{display: "none"}}
                          value="all"
                          onChange={(e) => {
                            onFilterTags(e.target.value);
                            setFilterTags("all")
                          }}
                        />
                        <label
                          htmlFor="all"
                          className="d-flex mb-0"
                          key="all"
                          style={{
                            padding: "5px 2px 5px",
                            backgroundColor: filterTags === "all" ? "#aebac1" : "transparent"
                          }}
                        >
                          <div>
                            <label
                              htmlFor="all"
                              className="form-check-label pl-3 f-w-400"
                              style={filterTags === "all" ? {fontWeight:"700"} : {} }
                            >
                              Todo
                            </label>
                          </div>
                        </label>
                      </div>
                    </div>

                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-6 text-right pr-1 d-flex justify-content-end align-items-center">
            {activeNotification ? (
              <FaBell style={{ fontSize: "24px", color: "#5bc0de"}} title="Desactivar tono de notificaciones" className="pointer"
              onClick={() => {
                const notificationsState = localStorage.getItem("STATE_NOT")
                notificationsState == "true" ? localStorage.setItem("STATE_NOT", "false") : localStorage.setItem("STATE_NOT", "true")
                setActiveNotification(false)
              }}
              />
            ) : (
              <FaBellSlash style={{ fontSize: "24px", color: "#455a64"}} title="Activar tono de notificaciones" className="pointer"
              onClick={() => {
                const notificationsState = localStorage.getItem("STATE_NOT")
                notificationsState == "true" ? localStorage.setItem("STATE_NOT", "false") : localStorage.setItem("STATE_NOT", "true")
                setActiveNotification(true)
              }}
              />
            )}
            {loadingListContacts ? (
              <span className="d-flex" style={{ marginLeft: "7px" }}>
                <FaSpinner
                  className="fa-spin"
                  style={{ fontSize: "25px", color:"#5bc0de" }}
                />
              </span>
            ) : (
              <TbMessagePlus
                className="icon-lia-edit"
                style={{ fontSize: "28px", color: "#5bc0de", marginLeft: "5px", cursor: "pointer" }}
                aria-hidden="true"
                title="Nuevo chat"
                onClick={handleNewChatClick}
                // onClick={() => {
                //   setLoadingListContacts(true)
                //   console.log("nuevo chat");
                //   setSearchPersons("")
                //   setNewChat(true);
                //   setSelectedChat(null);
                //   const dataJSON = JSON.parse(localStorage.getItem('LIST_CONTACT'));
                //   setPersonsList(dataJSON)
                //   setTimeout(() => {
                //     setLoadingListContacts(false)
                //   }, 2000);
                // }}
              />
            )}
          </div>
        </div>
      </header>

      <header className="header-filter d-flex py-1" style={{height: "5%"}}>
      {/* <header className="header-filter d-flex py-1" style={{height: isSwalOpen ? "1.5%" : "5%"}}> */}
        <div className="d-flex justify-content-around align-items-center w-100">
          <a className={`py-1 btn-filter btn btn-link btn-link-all ${filter === "mine" ? "active" : null}`} onClick={() => getConversationsAsign()}>
            Mios
          </a>
          <div className={`py-1 btn-filter btn btn-link d-flex align-items-center ${filter === "pending" ? "active" : null}`} onClick={() => getConversationsPending()}>
            <a className={conversationsPendingCount > 0 ? 'pr-2' : ''}>
              Pendientes
            </a>
            {conversationsPendingCount > 0 && (
              <div className="text-center d-flex">
                <div className="number-messages" style={{ fontSize: "12px" }}>
                  { conversationsPendingCount }
                </div>
              </div>
            )}
          </div>
          <a className={`py-1 btn-filter btn btn-link ${filter === "all" ? "active" : null}`} onClick={() => getConversations()}>
            Todos
          </a>
          <button
            className="bg-transparent border-0 d-flex"
            type="button"
            id="dropdownFilter"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <IoFilterCircleOutline className="icon-filter-2 f-28 pointer" style={{color:"#9fb4b9"}}/>
          </button>
          <div id="content-dropdown-filter" className="dropdown-menu p-2" aria-labelledby="dropdownFilter">
            <a className={`d-block py-1 btn-filter btn btn-link ${filter === "onHold" ? "active" : null}`} onClick={() => getConversationsNoAsign()}>
              Sin asignar
            </a>
            {/*             
            {roleId === "6284fdd697029c6d9743ccb3" || roleId === "64886b1b250f9d31bf2ed68d" ? (
              <a className={`d-block py-1 btn-filter btn btn-link ${filter === "bots" ? "active" : null}`} onClick={() => getConversationsBots()}>
                Bots
              </a>
            ) : null} 
            */}
            <a className={`d-block py-1 btn-filter btn btn-link ${filter === "bots" ? "active" : null}`} onClick={() => getConversationsBots()}>
              Bots
            </a>
          </div>
        </div>
      </header>

      <div id="content-list-chats" className="mt-0" style={{ height: "85%", overflow: "auto" }} ref={conversationsListRef} onScroll={onScrollDownConversations}>
        <ul className="chat-list">
          {validationChats && (
            <h4 className="text-center">No existen registros</h4>
          )}

          {listUserChats.length > 0 ? (listUserChats.map((chat, k) => {
            // console.log('-------------------💬💬💬💬💬------------------->>chat-user=====>', chat)
            const initialWords = chat.user.split(" ");
            const firstWord = initialWords[0];
            const secondWord = initialWords[1];
            let icon = "";
            if (secondWord === undefined) {
              icon = firstWord[0];
            } else {
              icon = firstWord[0] + secondWord;
            }

            const phoneContext = localStorage.getItem("CHAT_SELECTED");
            if(phoneContext){
              if (chat.phoneNumber == phoneContext) {
                localStorage.setItem("AGENT_ASIGN", chat.userAsign)
              }
            }

            var message = ""
            if (chat.messUlt) {
              message = chat.messUlt.length > 15 ? chat.messUlt.substr(0, 15) + "..." : chat.messUlt
            }

            return (
              <React.StrictMode key={k}>
                <li
                  key={k}
                  style={{
                    borderBottom: "1px solid rgb(0,0,0,0.05)",
                    justifyContent: "flex-start",
                  }}
                  className={selectedChat === chat.phoneNumber ? "li-chat chat-selected-active" : "li-chat" }
                  id={selectedChat === chat.phoneNumber ? "chat-selected-active" : ""}
                  onClick={() => {
                    onChatSelected(chat.phoneNumber);
                    getTagContact(chat.phoneNumber);
                    setSelectedChat(chat.phoneNumber);
                    setClassChat(true);
                  }}
                >
                  <div className="d-flex">
                    <div className="col-2 pl-1" style={{ alignItems: "center", textAlign: "end" }}>
                      <IconList className="cont-avatar" style={{
                        border: `3px solid ${chat.statusConv == "1" ? "#00a884" : chat.statusConv == "2" ? "#f56f89" : "#a7ada7"}`
                      }}>
                        <Avvvatars value={icon} />
                      </IconList>
                    </div>

                    <div className="px-2 col-6">
                      <div>
                        <strong className="name-contact">
                          {(chat.user && chat.user.length > 18)
                            ? chat.user.substr(0, 18) + "..."
                            : chat.user}
                        </strong>
                      </div>
                      {/* Ultimo mensaje para mostrar */}
                      <div className={`span-color d-flex align-items-center ${chat.amount && 'active'}`} style={chat.amount ? {fontWeight:"600"} : {}}>
                        {/* Chulitos para identificar si el ultimo mensaje es del agente o cliente y saber si fue entregado y leido  */}
                        {/* {(chat.originMessage !== "user" && chat.statusMessage === "delivered") || chat.statusMessage === "sent" ? (
                          <BsCheckAll className="" style={{ fontSize: "26px" }} />
                        ) : (chat.originMessage !== "user" && chat.statusMessage === "pending") || chat.statusMessage === "" ? (
                          <BsCheck className="" style={{ fontSize: "26px" }} />
                        ) : chat.originMessage !== "user" && chat.statusMessage === "read" ? (
                          <BsCheckAll className="" style={{ color: "#5bc0de", fontSize: "26px" }} />
                        ) : null} */}
                        {
                          chat.typeMessUlt && chat.typeMessUlt === "sticker" ? (
                            <span className="d-flex align-items-center">
                              <PiStickerLight className="mr-1" style={{fontSize: "17px"}} />
                              {message}
                            </span>
                          ):
                          chat.typeMessUlt === "location" ? (
                            <span className="d-flex align-items-center">
                              <IoLocationOutline className="mr-1" style={{fontSize: "17px"}} />
                              {message}
                            </span>
                          ):
                          chat.typeMessUlt === "image" ? (
                            <span className="d-flex align-items-center">
                              <FaImage className="mr-1" style={{fontSize: "17px"}} />
                              {message ? message : "Imagen"}
                            </span>
                          ):
                          chat.typeMessUlt === "video" ? (
                            <span className="d-flex align-items-center">
                              <BsFillCameraVideoFill className="mr-1" style={{fontSize: "17px"}} />
                              {message ? message : "Video"}
                            </span>
                          ):
                          chat.typeMessUlt === "audio" ? (
                            <span className="d-flex align-items-center">
                              <HiMicrophone className="mr-1" style={{fontSize: "17px"}} />
                              {message ? message : "Audio"}
                            </span>
                          ):
                          chat.typeMessUlt === "document" ? (
                            <span className="d-flex align-items-center">
                              <BsFillFileEarmarkTextFill className="mr-1" style={{fontSize: "17px"}} />
                              {message ? message : "Documento"}
                            </span>
                          ):
                          chat.typeMessUlt === "template" ? (
                            <span className="d-flex align-items-center">
                              <HiTemplate className="mr-1" style={{fontSize: "17px"}} />
                              {message ? message : "Plantilla"}
                            </span>
                          ):
                          chat.typeMessUlt === "contacts" ? (
                            <span className="d-flex align-items-center">
                              <FaUser className="mr-1" style={{fontSize: "17px"}} />
                              {message ? message : "Contactos"}
                            </span>
                          ):
                          chat.typeMessUlt === "interactive" ? (
                            <span className="d-flex align-items-center">
                              <LuWorkflow className="mr-1" style={{fontSize: "17px"}} />
                              {message ? message : "Contactos"}
                            </span>
                          ):
                          chat.typeMessUlt === "products" ? (
                            <span className="d-flex align-items-center">
                              <TbCubeSend className="mr-1" style={{fontSize: "17px"}} />
                              {message ? message : "Productos"}
                            </span>
                          ):
                          chat.typeMessUlt === "order" ? (
                            <span className="d-flex align-items-center">
                              <HiShoppingCart className="mr-1" style={{fontSize: "17px"}} />
                              {message ? message : "Orden"}
                            </span>
                          ):
                          chat.typeMessUlt === "contextProduct" ? (
                            <span className="d-flex align-items-center">
                              <TiArrowBack className="mr-1" style={{fontSize: "17px"}} />
                              {message}
                            </span>
                          ):
                          chat.typeMessUlt === "contextText" ? (
                            <span className="d-flex align-items-center">
                              <TiArrowBack className="mr-1" style={{fontSize: "17px"}} />
                              {message}
                            </span>
                          ):
                          chat.typeMessUlt === "transfer" ? (
                            <span className="d-flex align-items-center">
                              <PiUserSwitchFill className="mr-1" style={{fontSize: "17px"}} />
                              {message}
                            </span>
                          ):
                          chat.typeMessUlt === "close_conv" ? (
                            <span className="d-flex align-items-center">
                              <TbMessage2X className="mr-1" style={{fontSize: "17px"}} />
                              {message}
                            </span>
                          ):
                          message
                        }
                      </div>
                      <div>
                        <p className="mb-0" style={chat.userAsign ? { color: "rgb(91, 192, 222)" } : { color: "rgb(251 0 0)" }}>
                          {chat.userAsign ? chat.userAsign : "Sin asignar"}
                        </p>
                      </div>
                    </div>

                    <div style={{ position: "relative", marginLeft: "auto", marginRight: "10px" }}>
                      <div className="span-color" style={{ fontSize: "12px" }}>
                        {/*dayjs(chat.lastDate).locale("es").format("DD-MMM hh:mm a")*/ getRelativeDate(chat.lastTimestamp)}
                      </div>

                      <div className="text-center justify-content-end d-flex">
                        <div className="number-messages" style={{ fontSize: "12px" }}>
                          { chat.amount && chat.amount}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{marginLeft: "1em"}}>
                      <ul className="d-flex">
                        {chat.userData.tags && chat.userData.tags.slice(0, 3).map((tag, t) => (
                          <li
                            key={t}
                            className="px-2 py-0 mr-2 border-0"
                            style={{
                              backgroundColor: tag.color,
                              fontSize: "0.75rem",
                              color: "#fff",
                              borderRadius: "7px"
                            }}
                          >
                            {tag.title.length >= 9
                              ? tag.title.substr(0, 9) + "..."
                              : tag.title
                            }
                          </li>
                        ))}
                        {chat.userData.tags && chat.userData.tags.length > 3 && (
                          <li
                            className="px-0 py-0 mr-2 border-0"
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            +1
                          </li>
                        )}
                      </ul>
                  </div>
                </li>
              </React.StrictMode>
            )})
          ) : loadingChats ? (
            <h2 className="text-center mt-3">
              <FaSpinner
                className="fa-spin"
                style={{ fontSize: "25px" }}
              />
            </h2>
          ) : (
            <h2 className="text-center mt-3">No hay conversaciones</h2>
          )}
          {notMessages === 0 ? (
            <h2 className="text-center mt-3">No hay chats</h2>
          ) : null}
          {loadingMoreConversations ? (
            <h2 className="text-center mt-3">
              <FaSpinner
                className="fa-spin"
                style={{ fontSize: "25px" }}
              />
            </h2>
          ) : null}
        </ul>
      </div>

      {listUserChats.length > 0 ? (
          <h5 className="text-center m-0 d-flex align-items-center justify-content-center"  style={{height: "5%"}}>
            {textConversations}
          </h5>
      ) : null}
    </>
  );
};

export default ListConversations;

const IconList = styled.div`
  margin-bottom: 0px;

  div {
    // box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.7);
    margin: 4px auto;
  }

  p {
    font-size: 18px;
    font-weight: 600;
  }
`;